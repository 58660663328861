import styles from "./ListaBandeiras.module.css";
const ListaBandeiras = () => {
  return (
    <div className={styles.listaBandeiras}>
      <div className={styles.listaBandeiras1}>
        <div className={styles.defalut}>
          <div className={styles.div}>Xuliz</div>
        </div>
        <div className={styles.defalut1}>
          <div className={styles.div}>Visa</div>
        </div>
        <div className={styles.defalut2}>
          <div className={styles.div}>Mastercard</div>
        </div>
        <div className={styles.defalut3}>
          <div className={styles.div}>Elo</div>
        </div>
      </div>
    </div>
  );
};

export default ListaBandeiras;
