import styles from "./ListaFormaspagamento.module.css";
const ListaFormaspagamento = () => {
  return (
    <div className={styles.listaFormaspagamento}>
      <div className={styles.defalut}>
        <div className={styles.div}>Crédito</div>
      </div>
      <div className={styles.defalut1}>
        <div className={styles.div}>Débito</div>
      </div>
      <div className={styles.defalut2}>
        <div className={styles.div}>Pix</div>
      </div>
      <div className={styles.defalut3}>
        <div className={styles.div}>Saldo Xuliz</div>
      </div>
      <div className={styles.defalut4}>
        <div className={styles.div4}>Saldo Xuliz + Crédito</div>
      </div>
    </div>
  );
};

export default ListaFormaspagamento;
