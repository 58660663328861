import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./TelaXloja04Areaparceiros.module.css";
const TelaXloja04Areaparceiros = () => {
  const navigate = useNavigate();

  const onRealizarVendaTextClick = useCallback(() => {
    navigate("/tela-xloja01-areaparceiros");
  }, [navigate]);

  const onVendasDeHojeClick = useCallback(() => {
    navigate("/tela-xloja02-areaparceiros");
  }, [navigate]);

  const onHistricoDeVendasClick = useCallback(() => {
    navigate("/tela-xloja03-areaparceiros");
  }, [navigate]);

  const onGroupContainer5Click = useCallback(() => {
    navigate("/desktop");
  }, [navigate]);

  const onGroupContainer9Click = useCallback(() => {
    navigate("/tela-home-areaparceiros");
  }, [navigate]);

  return (
    <div className={styles.telaXloja04Areaparceiros}>
      <div className={styles.realizarVenda} onClick={onRealizarVendaTextClick}>
        Realizar venda
      </div>
      <div className={styles.div}>12/04/2023 - 17:59</div>
      <div className={styles.vendasDeHoje} onClick={onVendasDeHojeClick}>
        Vendas de hoje
      </div>
      <div
        className={styles.histricoDeVendas}
        onClick={onHistricoDeVendasClick}
      >
        Histórico de vendas
      </div>
      <div className={styles.calendrio}>{`Calendário `}</div>
      <img
        className={styles.telaXloja04AreaparceirosChild}
        alt=""
        src="/group-560.svg"
      />
      <img
        className={styles.telaXloja04AreaparceirosItem}
        alt=""
        src="/line-30.svg"
      />
      <div className={styles.calendrioDeVendasParent}>
        <div className={styles.calendrioDeVendas}>Calendário de vendas</div>
        <div className={styles.selecioneNoCalendrioContainer}>
          <span className={styles.selecioneNoCalendrioContainer1}>
            <p
              className={styles.selecioneNoCalendrio}
            >{`Selecione no calendário uma data que gostaria de iniciar uma consulta `}</p>
            <p className={styles.selecioneNoCalendrio}>
              nas suas vendas. Para consultar período, marque mais de uma data.
            </p>
          </span>
        </div>
      </div>
      <div className={styles.groupParent}>
        <div className={styles.detalhamentoDoCalendrioWrapper}>
          <div className={styles.detalhamentoDoCalendrio}>
            Detalhamento do calendário
          </div>
        </div>
        <div className={styles.data0104202308042023Wrapper}>
          <div className={styles.data01042023}>
            Data: 01/04/2023 à 08/04/2023
          </div>
        </div>
      </div>
      <div className={styles.component3}>
        <div className={styles.detailCalender}>
          <div className={styles.detailCalenderChild} />
          <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
          <div className={styles.vendasNoTotal}>Vendas no total</div>
          <div className={styles.formasDePagamento}>Formas de pagamento</div>
          <div className={styles.formaspagamento}>
            <div className={styles.crdito}>Crédito</div>
            <div className={styles.r536565}>R$ 5.365,65</div>
          </div>
          <div className={styles.formaspagamento1}>
            <div className={styles.crdito}>Débito</div>
            <div className={styles.r536565}>R$ 893,41</div>
          </div>
          <div className={styles.formaspagamento2}>
            <div className={styles.crdito}>Pix</div>
            <div className={styles.r536565}>R$ 2.597,15</div>
          </div>
          <div className={styles.formaspagamento3}>
            <div className={styles.saldoXuliz}>Saldo Xuliz</div>
            <div className={styles.r536565}>R$ 1.872,21</div>
          </div>
          <div className={styles.formaspagamento4}>
            <div className={styles.saldoXuliz}>Saldo Xuliz + Crédito</div>
            <div className={styles.r536565}>R$ 2.603,38</div>
          </div>
          <div className={styles.taxatarifaPaga}>Taxa/tarifa paga:</div>
          <div className={styles.totalLquido}>Total líquido:</div>
          <div className={styles.cashback}>Cashback:</div>
          <div className={styles.r42605}>{`-R$426,05 `}</div>
          <div className={styles.r159765}>R$1.597,65</div>
          <div className={styles.vendas}>85 vendas</div>
          <div className={styles.r1331380}>R$ 13.313,80</div>
          <div className={styles.r1129010}>R$ 11.290,10</div>
          <div className={styles.detailCalenderItem} />
        </div>
        <div className={styles.calender}>
          <div className={styles.calenderChild} />
          <div className={styles.timeday1w4i} />
          <div className={styles.timeday1w4i1} />
          <div className={styles.timeday1w4i2} />
          <div className={styles.timeday1w4i3} />
          <div className={styles.timeday1w4i4} />
          <div className={styles.timeday1w4i5} />
          <div className={styles.timeday1w4i6} />
          <div className={styles.timeday1w4i7} />
          <div className={styles.timeday1w4i8} />
          <div className={styles.timeday1w4i9}>
            <div className={styles.dayCalender}>
              <div className={styles.div1}>01</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender1}>
              <div className={styles.div1}>08</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender2}>
              <div className={styles.div1}>07</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender3}>
              <div className={styles.div1}>06</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender4}>
              <div className={styles.div1}>05</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender5}>
              <div className={styles.div1}>04</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender6}>
              <div className={styles.div1}>03</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender7}>
              <div className={styles.div1}>02</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender8}>
              <div className={styles.div1}>15</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender9}>
              <div className={styles.div1}>22</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender10}>
              <div className={styles.div1}>29</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender11}>
              <div className={styles.div1}>14</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender12}>
              <div className={styles.div1}>21</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender13}>
              <div className={styles.div1}>28</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender14}>
              <div className={styles.div1}>13</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender15}>
              <div className={styles.div1}>20</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender16}>
              <div className={styles.div1}>27</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender17}>
              <div className={styles.div1}>12</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender18}>
              <div className={styles.div1}>19</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender19}>
              <div className={styles.div1}>26</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender20}>
              <div className={styles.div1}>11</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender21}>
              <div className={styles.div1}>18</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender22}>
              <div className={styles.div1}>25</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender23}>
              <div className={styles.div1}>10</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender24}>
              <div className={styles.div1}>17</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender25}>
              <div className={styles.div1}>24</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender26}>
              <div className={styles.div1}>31</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender27}>
              <div className={styles.div1}>09</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender28}>
              <div className={styles.div1}>16</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender29}>
              <div className={styles.div1}>23</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
            <div className={styles.dayCalender30}>
              <div className={styles.div1}>30</div>
              <div className={styles.r1004442}>
                <span>R$</span>
                <span className={styles.span}>{` `}</span>
                <span className={styles.span1}>10.044,42</span>
              </div>
              <div className={styles.vendas1}>72 vendas</div>
            </div>
          </div>
          <div className={styles.dom}>Dom</div>
          <div className={styles.seg}>Seg</div>
          <div className={styles.ter}>Ter</div>
          <div className={styles.qua}>Qua</div>
          <div className={styles.qui}>Qui</div>
          <div className={styles.sex}>Sex</div>
          <div className={styles.sb}>Sáb</div>
        </div>
      </div>
      <div className={styles.telaXloja04AreaparceirosInner} />
      <div className={styles.ellipseParent}>
        <img className={styles.groupChild} alt="" src="/ellipse-1@2x.png" />
        <div className={styles.feitoComAmor}>FEITO COM AMOR</div>
        <div className={styles.feitocomamorxulizcombr}>
          feitocomamor@xuliz.com.br
        </div>
      </div>
      <img className={styles.lineIcon} alt="" src="/line-13.svg" />
      <div className={styles.vectorParent}>
        <img className={styles.vectorIcon1} alt="" src="/vector2.svg" />
        <div className={styles.configuraes}>Configurações</div>
      </div>
      <div
        className={styles.closeTracedParent}
        onClick={onGroupContainer5Click}
      >
        <img
          className={styles.closeTracedIcon}
          alt=""
          src="/close-traced.svg"
        />
        <div className={styles.configuraes}>Sair</div>
      </div>
      <div className={styles.vectorGroup}>
        <img className={styles.vectorIcon2} alt="" src="/vector3.svg" />
        <div className={styles.configuraes}>Contas</div>
      </div>
      <div className={styles.vectorContainer}>
        <img className={styles.vectorIcon1} alt="" src="/vector4.svg" />
        <div className={styles.configuraes}>Central de ajuda</div>
      </div>
      <div className={styles.lineParent}>
        <div className={styles.groupItem} />
        <div className={styles.sentTracedParent}>
          <img
            className={styles.sentTracedIcon}
            alt=""
            src="/sent-traced.svg"
          />
          <div className={styles.produtos}>Produtos</div>
        </div>
        <div className={styles.groupDiv} onClick={onGroupContainer9Click}>
          <img className={styles.vectorIcon4} alt="" src="/vector5.svg" />
          <div className={styles.home}>Home</div>
        </div>
        <div className={styles.colorPaletteTracedParent}>
          <img
            className={styles.colorPaletteTraced}
            alt=""
            src="/color-palette-traced.svg"
          />
          <div className={styles.relatriosWrapper}>
            <div className={styles.relatrios}>Relatórios</div>
          </div>
        </div>
        <div className={styles.vectorParent1}>
          <img className={styles.vectorIcon5} alt="" src="/vector6.svg" />
          <div className={styles.xloja}>XLoja</div>
        </div>
        <div className={styles.financeTracedParent}>
          <img
            className={styles.financeTracedIcon}
            alt=""
            src="/finance-traced.svg"
          />
          <div className={styles.saque}>Saque</div>
        </div>
      </div>
      <img
        className={styles.logomarcaXulizIcon}
        alt=""
        src="/logomarca-xuliz1@2x.png"
      />
      <img className={styles.parceirosIcon} alt="" src="/parceiros.svg" />
      <img
        className={styles.telaXloja04AreaparceirosChild1}
        alt=""
        src="/line-6.svg"
      />
    </div>
  );
};

export default TelaXloja04Areaparceiros;
