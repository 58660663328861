import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";


import Bancoob from "./assets/bancoob.jpg";
import bXuliz from "./assets/bXuliz.png";
import bElo from "./assets/bElo.png";
import bVisa from "./assets/bVisa.png";
import "./ResumoRecebiveis.css";


import logo1 from './assets/xuliz_logo2.png'; // Tell webpack this JS file uses this image

import visa from './assets/visa.png'; 
import master from './assets/master.png'; // Tell webpack this JS file uses this image
import elo from './assets/elo_debito.png'; // Tell webpack this JS file uses this image
import hipercard from './assets/hipercard.png'; // Tell webpack this JS file uses this image
import amex from './assets/amex.png'; // Tell webpack this JS file uses this image







import { useEffect } from "react";
import xloja_12 from './assets/xloja_12.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg'; 
import banco_img from './assets/banco.png'; 


import xloja from './assets/xloja.png'; // Tell webpack this JS file uses this image


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faShoppingBag,faMoneyCheckAlt,faFileAlt,faGavel,faChartPie,faBox,faCircleDollarToSlot,faBullhorn,faQuestionCircle,faCalendar,faUsers,faGear,faSquareXmark,faMobile,faBars,faBookReader } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import axios from "axios";
import logo_parceiros from './assets/grupo677.png'; // Tell webpack this JS file uses this image
 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";


import Calendario from "./componentes/Recebiveis/calendario";
 

var vendaPaga = false;
   
const Recebiveis = () => {
  const navigate = useNavigate();

  const Swal = require('sweetalert2')

   
  const events = [{
    id: 1,
    color: '#fd3153',
    from: '2019-05-02T18:00:00+00:00',
    to: '2019-05-05T19:00:00+00:00',
    title: 'This is an event'
}, {
    id: 2,
    color: '#1ccb9e',
    from: '2019-05-01T13:00:00+00:00',
    to: '2019-05-05T14:00:00+00:00',
    title: 'This is another event'
}, {
    id: 3,
    color: '#3694DF',
    from: '2019-05-05T13:00:00+00:00',
    to: '2019-05-05T20:00:00+00:00',
    title: 'This is also another event'
}];

  const [emailRef, setEmailRef] = useState('');
  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [cashbackCliente, setCaschbackCliente] = useState('R$ 0,00');
  const [dadosClinte, setDadosCliente] = useState([]);
  const [dadosX, setDadosX] = useState([]);
  const [primeiroNome, setPrimeiroNome] = useState('');
  const [nomeUser, setNome] = useState('');
  const [rows, setRow] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);


  const [dadosREF, setDADOSREF] = useState(null);

  const [total_receber, setTotalReceber] = useState('');
  const [geralTaxas, setTotalTaxas] = useState('R$ 0,00');
  const [recebiveisPagamento, setRecebiveisPagamento] = useState([]);

  
  

  const now = new Date();
  const [selectedValue, setSelectedValue] = useState(now.getMonth());
  const [ano, setAno] = useState(now.getFullYear());
  const [dataCalendario, setDataCalendario] = useState([]);
  const [geralDebito,setGeralDebito] = useState(0)
  const [geralCredito,setGeralCredito] = useState(0)
  const [geralCreditoParcelado,setGeralCreditoParcelado] = useState(0)
  const [geralXuliz,setGeralXuliz] = useState(0)
  const [geralPix,setGeralPix] = useState(0)
  const [geralBruto,setGeralBruto] = useState(0)
  const [geralDepositar,setGeralDepositar] = useState(0)

  
  const [totalPedidos,setTotalPedidos] = useState(0)

  const [dataRef,setDataRef] = useState('')


  const [clickEnviou, setClickEnviou] = useState(false);
  const [tokenEsta, setTokenEstabelecimento] = useState('');
  const [idvenda, setIdvenda] = useState('');
  const [isMobile, setIsMobile] = useState(true);
  const [urlLogo, setUrlLogo] = useState('');

  
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };

    
  }, []);



  useEffect(() => {

    getDadosSQL()

  },[]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const clickAplicar = ()=>{

    if(endDate == null){
      setEndDate(startDate)
      setShowCalendar(false)
      VerificaChamar(startDate,startDate)
      return false
    }
 
    setShowCalendar(false)
    VerificaChamar(endDate,startDate)
  }

  const toggleCalendar = () => {


    setShowCalendar(true)
   

    return false;

  };

  const VerificaChamar = async (endDate,startDate,showCalendar)=>{


    await sleep(300)
   
       

   var data = document.getElementById('data_filtro').value;
   data = data.split(' - ')

   if(data.length != 2){
    return false;
   }

   var inicio = data[0].split('/');
   inicio = inicio[2]+'-'+inicio[1]+'-'+inicio[0];

   var fim = data[1].split('/');
   fim = fim[2]+'-'+fim[1]+'-'+fim[0]
     
   
    getDadosSQL(true,inicio,fim)


  }


  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


  const getDadosSQL = async()=>{

    setRow([])

   

    await  getDados()

    var dados = await localStorage.getItem('login')
    dados = JSON.parse(dados);
    var idestabele = dados.idestabelecimento;




    var getTokenEstabelecimneto = await getTokenEstabelciment(idestabele);
    


    if(getTokenEstabelecimneto == null){
      getTokenEstabelecimneto = dados.tokenestabelecimento;
    }


    // var datainicial = await getDataInicial(mesSelecionado)
    // var datafinal = await getDataFinal(mesSelecionado)
   
    var datainicial = await getDataInicial()
    var datafinal = await getDataFinal()

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: new URLSearchParams({
        idempresa: '1350',
        token: getTokenEstabelecimneto,
        idvendaxuliz: '',
        metodo: 'Lista',
        idestabelecimento: idestabele,
        idvenda: '',
        datainicial: datainicial,
        datafinal: datafinal
      })
    };
    
    fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/estabelecimento/listarecebiveisagrupado.php', options)
    .then(response => response.json())
    .then(response => tradarDados(response))
    .catch(err => console.error(err));
    
    fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/estabelecimento/listarecebiveis.php', options)
      .then(response => response.json())
      .then(response => tradarDadosVendas(response))
      .catch(err => console.error(err));
    
  }

  const tradarDados = async(dadosG)=>{


    setDADOSREF(dadosG)
    
    var retorno = dadosG.retorno;
    // console.log('dadosG',dadosG)
    var usuarioFiltrou = false;

    if(dadosG.array_retorno){
      usuarioFiltrou = true;
      retorno = dadosG.array_retorno

      if(dadosG.array_retorno.length == 0){
        retorno = dadosG.retorno;
      }
    }

    // var mesREF = getCurrentMonthAndYear(newMes);
 
 
    // console.log(mesREF,'mesREF')
    //  console.log('dados_api',retorno);

     var geralLiquido = 0;
     
     var totalDebitos = 0;
     var totalCredito = 0;
     var totalXuliz  = 0;
     // var totalPix= 0;
     var totalTaxas = 0;
     
     var totalBruto = 0;
     var totalBrutoDepositar = 0;
     var totalLiquido = 0;
     var totalLiquidoDepositar = 0;

     var total_pedido = 0;



     var today =  new Date();
     var dia_hoje = today.getDate();
     var mesHoje = today.getMonth()+1;

      
     for(var x=0;x<retorno.length;x++){
       
       var data = retorno[x].datavencimento;
      var datavencimento = retorno[x].datavencimento;
     

      data = data.split('-');
      
      var diaRef = data[2];
      var mesRef =  data[1];

      



      data = data[0]+'-'+data[1];
      
      //  if (resumo == null){
      //   continue;
      //  }

      var resumo = retorno[x].resumo
      
      
      for(var b=0;b<resumo.length;b++){
        
        // console.log('dados_api2',resumo[b]);
      var bandeira = resumo[b].tipo;
        //  console.log(bandeira)
        if(bandeira.includes("Débito")){
          var debito = resumo[b].valor
          debito = parseFloat(debito)
          totalDebitos += debito
        }

        if(bandeira.includes("Crédito")){
          var credito = resumo[b].valor
          credito = parseFloat(credito)
          totalCredito += credito
        }

        if(bandeira.includes("Xuliz APP")){
          var xuliz = resumo[b].valor
          xuliz = parseFloat(xuliz)
          totalXuliz += xuliz
        }


      }
        
       
      // console.log(mesREF+'  ||| '+data+' -->'+diaRef)
      
      var valor = retorno[x].valor;
      valor = parseFloat(valor);
      
      
      geralLiquido = geralLiquido+valor;
     

      var quantidade = retorno[x].qtde
      quantidade = parseFloat(quantidade)
      total_pedido = total_pedido+quantidade;
      
      var valorBruto = retorno[x].valorbruto
      valorBruto = parseFloat(valorBruto)

      totalTaxas = totalTaxas+(valorBruto-valor)

     
      // if(mesRef > mesHoje){
      //   //futuro
      //   totalBrutoDepositar = totalBrutoDepositar+valorBruto
      //   continue
      // }

      // if(diaRef > dia_hoje){
      //   totalBrutoDepositar = totalBrutoDepositar+valorBruto
      //   //futuro
      //   continue
      // }
      if(mesRef > mesHoje){
        //futuro
        totalLiquidoDepositar = totalLiquidoDepositar+valor
        continue
      }

      if(diaRef > dia_hoje){
        totalLiquidoDepositar = totalLiquidoDepositar+valor
        //futuro
        continue
      }


       //Valor total depositado
       // totalBruto = totalBruto+valorBruto;


       totalLiquido = totalLiquido+valor
       


     

    }


    //  geralLiquido = geralLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    //  totalDebitos = totalDebitos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    //  totalCredito = totalCredito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });


    //  totalBruto = totalBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    //  totalBrutoDepositar = totalBrutoDepositar.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalTaxas = totalTaxas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalLiquidoDepositar = totalLiquidoDepositar.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalLiquido = totalLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    //  setGeralBruto(totalBruto)
    //  setGeralDepositar(totalBrutoDepositar)
    
    setTotalTaxas(totalTaxas)
     setGeralBruto(totalLiquido)
     setGeralDepositar(totalLiquidoDepositar)
     
    //   setGeralPix(totalPix)
    //  setTotalReceber(geralLiquido)
    //  setTotalPedidos(total_pedido)


  }


  const bandeiraTipo = (bandeira)=>{

 
    if(bandeira.includes("Visa")){
      return  <img style={{width:'39px', height:'23px'}} src={visa}/>
    }

    if(bandeira.includes("Master")){
      return  <img style={{width:'35px', height:'23px'}} src={master}/>
    }

    if(bandeira.includes("Elo")){
      return  <img style={{width:'35px', height:'15px'}} src={elo}/>
    }

    if(bandeira.includes("Hiper")){
      return  <img style={{width:'37px', height:'22px'}} src={hipercard}/>
    }

    if(bandeira.includes("Amex")){
      return  <img style={{width:'37px', height:'22px'}} src={amex}/>
    }

    if(bandeira.includes("Xuliz")){
      return  <img style={{width:'37px', height:'22px'}} src={logo1}/>
    }

    if(bandeira.includes("XULIZ")){
      return  <img style={{width:'37px', height:'22px',margin:10,borderWidth:3}} src={logo1}/>
    }
    

    // if(bandeira.includes("Visa")){
    //   return  <img style={{width:'36px', height:'19px'}} src={bVisa}/>
    // }

    // return  <p> - </p>

    return  <img style={{width:'39px', height:'23px'}} src={visa}/>
  }
  const bandeiraTipoMobile = (bandeira)=>{

 
    if(bandeira.includes("Visa")){
      return  <img style={{width:'12px', height:'10px'}} src={visa}/>
    }

    if(bandeira.includes("Master")){
      return  <img style={{width:'12px', height:'10px'}} src={master}/>
    }

    if(bandeira.includes("Elo")){
      return  <img style={{width:'15px', height:'10px'}} src={elo}/>
    }

    if(bandeira.includes("Hiper")){
      return  <img style={{width:'12px', height:'10px'}} src={hipercard}/>
    }

    if(bandeira.includes("Amex")){
      return  <img style={{width:'12px', height:'10px'}} src={amex}/>
    }

    if(bandeira.includes("Xuliz")){
      return  <img style={{width:'12px', height:'10px'}} src={logo1}/>
    }

    if(bandeira.includes("XULIZ")){
      return  <img style={{width:'12px', height:'10px',margin:10,borderWidth:3}} src={logo1}/>
    }
    
 

    return  <img style={{width:'12px', height:'10px'}} src={visa}/>
  }


  const tradarDadosVendas = async (dadosG) => {

     var dadosZ = dadosG.retorno;
    console.log('dadosZ',dadosZ);
    

    var totalcredito = 0;
    var totaldebito = 0
    var totalxuliz = 0


    
   
    
    for (var x = 0; x <dadosZ.length; x++) {
      var item = dadosZ[x];
      // console.log('dadosZ[x]',item);
      var bandeiraget = dadosZ[x].bandeiraxuliz

      var valor = ''+dadosZ[x].valor;
      valor = parseFloat(valor)
  
     
      if(bandeiraget.includes('Crédito')){
        totalcredito  = totalcredito+valor
      }

      if(bandeiraget.includes('Débito')){
        totaldebito  = totaldebito+valor
      }
      if(bandeiraget.includes('XULIZ') || bandeiraget.includes('Xuliz')){
        totalxuliz  = totalxuliz+valor
      }
    }



    var groupedByDate = dadosZ.reduce((acc, currentValue) => {
      var date = currentValue.datavencimento;
      if (!acc[date]) {
          acc[date] = [];
      }
      acc[date].push(currentValue);
      return acc;
    }, {});

     


    console.log('groupedByDate',groupedByDate)
    var valuesArray = Object.values(groupedByDate);

    var SeparadoData = []
 
    for(var x =0;x<valuesArray.length;x++){

      var getDadosDados = valuesArray[x]

      // console.log('ss',getDados)

        var groupedByDatebandeiraxuliz = getDadosDados.reduce((acc, currentValue) => {
          var date = currentValue.bandeiraxuliz;
          
          if (!acc[date]) {
              acc[date] = [];
              acc['dataRef'] = currentValue.datavencimento;
          }
          acc['dataRef'] = currentValue.datavencimento;
          acc[date].push(currentValue);
          return acc;
        }, {});

        SeparadoData.push(groupedByDatebandeiraxuliz)

 
    }



    console.log('valuesArra2y',valuesArray)
    console.log('SeparadoData',SeparadoData)

    
    var recebiveis_formatado = []

 
    for(var x = 0;x<SeparadoData.length;x++){
      
       let objeto = SeparadoData[x];
      for (let bandeira in objeto) {

        if(bandeira != 'dataRef'){

          let quantidade = objeto[bandeira].length;

          let somaValoresLiquido = objeto[bandeira].reduce((acumulador, item) => acumulador + parseFloat(item.valor), 0);
          let somaValoresBruto = objeto[bandeira].reduce((acumulador, item) => acumulador + parseFloat(item.valorbruto), 0);

          somaValoresLiquido = somaValoresLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          somaValoresBruto = somaValoresBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

          var dataGet = SeparadoData[x].dataRef;
          dataGet = dataGet.split('-');
          dataGet = dataGet[2]+'/'+dataGet[1]+'/'+dataGet[0]



          recebiveis_formatado.push({
            data_ref:dataGet,
            bandeira:bandeira,
            quantidade_transacoes:quantidade,
            liquido:somaValoresLiquido,
            bruto:somaValoresBruto
          })
        }
       
      }
       
     
    }

    
    recebiveis_formatado.sort((a, b) => {
      let dataA = a.data_ref.split('/').reverse().join('-'); // Convertendo "dd/mm/yyyy" para "yyyy-mm-dd"
      let dataB = b.data_ref.split('/').reverse().join('-');
      return new Date(dataA) - new Date(dataB);
    });


    console.log('recebiveis_formatado',recebiveis_formatado)

    
  
    totalcredito = totalcredito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totaldebito = totaldebito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalxuliz = totalxuliz.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    


    setGeralDebito(totaldebito)
    setGeralCredito(totalcredito);
    setGeralXuliz(totalxuliz)
    setRecebiveisPagamento(recebiveis_formatado)
    

  }


  function getCurrentMonthAndYear(newMes) {
    // Cria um objeto de data para o mês atual
    var currentDate = new Date();

    var selectedValueGet = document.getElementById('selectInput').value;
    selectedValueGet = parseInt(selectedValue)

    if(newMes != null){
      selectedValueGet = ''+newMes
      selectedValueGet = parseInt(selectedValueGet)
    }
    // Obtém o mês e o ano atual
    var month = selectedValueGet + 1; // Adiciona 1 pois os meses em JavaScript são indexados de 0 a 11
    var year = currentDate.getFullYear();

    // Retorna o mês e o ano formatados
    return `${year}-${(month < 10 ? '0' : '') + month}`;
}



function obterUltimoDiaDoMes(mes, ano) {
  var mesInt = parseInt(mes);

  // Criar uma nova data para o primeiro dia do mês seguinte
  var data = new Date(ano, mesInt, 0);

  // O último dia do mês é o último dia do mês anterior ao que definimos
  var ultimoDia = data.getDate();

  return ultimoDia;
}


  const getDataFinal = async()=>{
    var data = new Date();
  
  var ano = data.getFullYear();
  var mes = getParameterByName('m');

  var dia = obterUltimoDiaDoMes(mes,ano)
  
  return ano + '-' + mes + '-' + dia;
  }

  const getDataInicial = async()=>{
    var data = new Date();
    // data.setDate(data.getDate() - 32);
    
    var ano = data.getFullYear();
    var mes = getParameterByName('m');
    var dia = "01";
    
    return ano + '-' + mes + '-' + dia;
  }


  const getTokenEstabelciment = async(token)=>{
    const form = new FormData();
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("metodo", "ListaEstabelecimentos");
      
    
    const options = {
      method: 'POST',
    };
    
    options.body = form;
    
   var rres = await  fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options).then(response => response.json()); 
    
   var arrrayEstabelecimnto = rres.estabelecimentos;

   var tokenEstabelecimento = '';
   for(var x=0;x<arrrayEstabelecimnto.length;x++){
    if(arrrayEstabelecimnto[x].idestabelecimento == token){
      tokenEstabelecimento = arrrayEstabelecimnto[x].token;
      break;
    }
   }

  //  console.log(rres)
  //  console.log(tokenEstabelecimento)
   setTokenEstabelecimento(tokenEstabelecimento)
   return tokenEstabelecimento;
 

  }


  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
  
    verificarNiveisLogin(dadosX)
  };

 
  

 

  const getDados = async()=>{
    var dados = await localStorage.getItem('login')

    if(dados == null){
      navigate("/");

      return false;
    }
    dados = JSON.parse(dados)
     setDadosX(dados)

     setUrlLogo(dados.urllogo)
 
    //  console.log('dadosX',dados)

    setEmailRef(dados.email)
    //  console.log(dados)

      
  
     
  }


  const verificarNiveisLogin = async ()=>{
    var idperfil = await localStorage.getItem('tipoPerfil');
    
      
    if(idperfil == "7"){
        //financeiro somente relatorios
        location.href = "./minhasvendas";
        
    }

}
  
 
  


  

   

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
 

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    tradarDados(dadosREF,event.target.value)
};

const Detalhes = ({minWidth})=>{

  return(
    <>
    <div style={{display:'flex',padding:10,flexDirection:'column'}}>
            
            <div id="blockUser" style={{flexDirection:'column',display:"flex",height:"100%",padding:5,flex:1,minWidth:minWidth,padding:20}}>
                      
                      <span style={{fontFamily:'Montserrat',fontSize:20,color:'#262626',fontWeight:'bold'}}>Detalhamento do calendário</span>
                      <span style={{fontFamily:'Montserrat',fontSize:12,backgroundColor:'#F2E2C4',width:200,padding:5,borderRadius:5,marginTop:10}}>{dataRef}</span>
  
  
                      <div style={{backgroundColor:'#F1F1F1',display:'flex',height:700,marginTop:20,borderRadius:10,flexDirection:'column',flex:1}}>
                          <div style={{display:'flex',flex:1}}>
                                
                                <div style={{flex:1,justifyContent:'center',display:'flex',flexDirection:'column',padding:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:17,color:'#999999',fontWeight:'bold'}}>Vendas no total</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#999999',fontWeight:'bold'}}>{totalPedidos} vendas</span>
  
                                </div>
  
                                <div style={{flex:1,justifyContent:'center',display:'flex',flexDirection:'column',padding:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:17,color:'#999999',fontWeight:'bold'}}>{geralBruto}</span>
                                </div>
                          </div>
  
                          <div style={{display:'flex',flex:6,borderTop:"4px solid #3D3D3D",padding:20,flexDirection:'column'}}>
                              
                              <span style={{fontFamily:'Montserrat',fontSize:17,color:'#262626',fontWeight:'bold'}}>Formas de pagamento</span>
  
  
                                <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Crédito</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralCredito}</span>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
  
                                <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Débito</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralDebito}</span>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
                               
  
  
                                <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Pix</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralPix}</span>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
                               
  
  
                                <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Saldo Xuliz</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralXuliz}</span>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
                               
  
                                <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Saldo Xuliz + Crédito</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralXuliz}</span>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
                               
  
                               <div style={{width:'100%',height:150,textAlign:'-webkit-right',marginTop:20,placeContent:'center',flexDirection:'column',display:'flex'}}>
                                    
                                    <span style={{fontFamily:'Montserrat',fontSize:15,color:'#999999'}}>Taxa/tarifa paga:<span style={{color:'red'}}>  - {geralTaxas}</span></span>
                                    <span style={{fontFamily:'Montserrat',fontSize:15,color:'#999999',marginTop:10}}>Cashback: <span style={{color:'#279C33'}}> {geralXuliz} </span></span>
  
                                    <span style={{fontFamily:'Montserrat',fontSize:20,color:'#999999',marginTop:50}}>Total líquido: <span style={{color:'black'}}>  {total_receber}</span></span>
  
  
                               </div>
  
  
  
                          </div>
                      </div>
  
  
  
                
  
                          {/* CODE */}
                      
            </div>  
            </div>
    
    </>
  )
}


const TableComponent = ({ }) => {
  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{fontSize:10,textAlign:'-webkit-center'}}>Data</th>
          <th style={{fontSize:10,textAlign:'-webkit-center'}}>Bandeira</th>
          <th style={{fontSize:10,textAlign:'-webkit-center'}}>Transações</th>
          <th style={{fontSize:10,textAlign:'-webkit-center'}}>Líquido</th>
          {/* <th style={{fontSize:10,textAlign:'-webkit-center'}}>Bruto</th> */}

        </tr>
      </thead>
      <tbody>
        {recebiveisPagamento.map((transaction, index) => (
          <tr key={index} style={{ borderBottom: 'solid 1px #999' }}>
            <td style={{ padding: '8px', textAlign: 'center' }}>
              <p style={{ fontFamily: 'Montserrat', fontSize: '6px', fontWeight: '400', color: '#3D3D3D', margin: '0' }}>
                {transaction.data_ref}
              </p>
            </td>
            <td style={{ padding: '8px', textAlign: 'center' }}>
              {bandeiraTipoMobile(transaction.bandeira)}
              {/* <p style={{ fontFamily: 'Montserrat', fontSize: '9px', fontWeight: '400', color: '#3D3D3D', margin: '0', whiteSpace: 'nowrap' }}>
                {transaction.bandeira}
              </p> */}
            </td>
            <td style={{ padding: '8px', textAlign: 'center' }}>
              <p style={{ fontFamily: 'Montserrat', fontSize: '9px', fontWeight: '400', color: '#3D3D3D', margin: '0' }}>
                {transaction.quantidade_transacoes}
              </p>
            </td>
            <td style={{ padding: '8px', textAlign: 'center' }}>
              <p style={{ fontFamily: 'Montserrat', fontSize: '9px', fontWeight: '400', color: '#3D3D3D', margin: '0', whiteSpace: 'nowrap' }}>
                {transaction.liquido}
              </p>
            </td>
            {/* <td style={{ padding: '8px', textAlign: 'center' }}>
              <p style={{ fontFamily: 'Montserrat', fontSize: '9px', fontWeight: '600', color: '#3D3D3D', margin: '0', whiteSpace: 'nowrap' }}>
                {transaction.bruto}
              </p>
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};




const TabelaRecebiveisMobile = ()=>{
  return(
    <>
     <div style={{display:'flex',flex:1,flexDirection:'column',width:'100%'}}>
        <TableComponent recebiveisPagamento={recebiveisPagamento} bandeiraTipoMobile={bandeiraTipoMobile} />
      </div>
    </>
  )
}




  if(isMobile == true){
      return(
      <>
        <MenuMobile page="recebiveis"/>

        <div style={{height:'100vh',display:"flex",width:"100%",overflow:'auto',flex:1,padding:20}}>
         
      <div className="container-principal-mobile" style={{width:'100%',  padding:'13px'}}>
        <div style={{ borderBottom:'solid 1.5px #999'}}>
          <div style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:' center'}}>
            <div className="voltar-resumo" style={{ display:'inline-block'}}>
              <div style={{display: 'flex', cursor:'pointer'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"  viewBox="0 0 16 16" fill="none">
                <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p style={{color:'#999',fontFamily:'Montserrat', fontSize:'13px', fontWeight:'600', marginLeft:'10px'}}>Voltar</p>
              </div>
              <div>
                <p style={{fontFamily:'Montserrat', fontSize:'24px', fontWeight:'600'}}>Resumo</p>
                <div style={{display:'flex',alignItems:'center', background:'#F2E2C4', borderRadius: '5px', padding:'5px', justifyContent:'center'}}><p style={{margin:'0px', marginRight:'5px', fontFamily:'Montserrat', fontSize:'10px', fontWeight:'600'}}>Recebíveis</p> 
                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 4 7" fill="none">
                <path d="M1 6L3.14286 3.5L1 1" stroke="#3D3D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p style={{margin:'0px', marginLeft:'5px', fontFamily:'Montserrat', fontSize:'10px', fontWeight:'400'}}>Resumo</p></div>  
              </div>             
            </div>
            {/* <div className="inputs" style={{display:'inline-flex', height:'40px',}}>
              <input style={{margin:'3px'}} type="text" placeholder="teste"></input>
              <input style={{margin:'3px'}} type="text" placeholder="teste"></input>
              <input style={{margin:'3px'}} type="text" placeholder="teste"></input>
            </div> */}
          </div>
          <div className="container-valores-mobile" style={{display:'flex', alignItems:'center',flex:1}}>
            
            
            
            <div className="valor-total-mobile" style={{paddingTop: '20px',paddingBottom: '20px', marginRight:'25px'}}>
              <div style={{background:'#F1F1F1', display:'flex', borderRadius:'10px', padding:'10px 3px'}}>
                <div style={{ margin:'10px', display:'flex', flexDirection:'column'}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'13px', fontWeight:'400px'}}>Valor total</p>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'18px', fontWeight:'600'}}>depositado</p>
                </div>
                <div style={{display:'flex', alignItems:'center', margin:'10px',}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'30px', fontWeight:'600', color:'#3D3D3D'}}>{geralBruto}</p>
                </div>
              </div>
            </div>


 


            <div className="valor-total-mobile" style={{ paddingTop: '20px',paddingBottom: '20px', marginRight:'25px'}}>
              <div style={{ background:'#F1F1F1', display:'flex', borderRadius:'10px', padding:'10px 3px'}}>
                <div style={{ margin:'10px', display:'flex', flexDirection:'column'}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'13px', fontWeight:'400px'}}>Valor total</p>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'18px', fontWeight:'600'}}>Taxa</p>
                </div>
                <div style={{ display:'flex', alignItems:'center', margin:'10px',}}>
                   <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'30px', fontWeight:'600', color:'#3D3D3D'}}>{geralTaxas}</p>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: '20px',paddingBottom: '20px'}}>
              <div style={{background:'#F1F1F1', display:'flex', borderRadius:'10px', padding:'10px 3px'}}>
                <div style={{ margin:'10px', display:'flex', flexDirection:'column'}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'13px', fontWeight:'400px'}}>Valor total a</p>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'18px', fontWeight:'600'}}>depositar</p>
                </div>
                <div style={{display:'flex', alignItems:'center', margin:'10px',}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'30px', fontWeight:'600', color:'#3D3D3D'}}>{geralDepositar}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-lancamentos-mobile" style={{ display:'flex'}}>
          <div style={{ display:'inline-block', paddingTop:'40px', }}>
            <div style={{}}>
              <p style={{fontFamily:'Montserrat', fontSize:'20px', fontWeight:'600', whiteSpace: 'nowrap'}}>Lançamentos no período</p>
              <div style={{border:'solid 1px #F1F1F1', borderRadius:'10px', padding:'22px 25px'}}>
                <div style={{ display:'block'}}>
                  
                  
                  <div style={{borderBottom:'solid 1px #F1F1F1', display:'flex',  padding: '0px 4px 0px 6px', height:'41px',cursor:'pointer'}}>
                    <div style={{ classNam:'font-p', display:'flex',alignItems:'center', marginRight:'auto'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'11px', fontWeight:'400'}}>Débito</p></div>
                    <div style={{ display:'flex', alignItems:'center'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'11px', fontWeight:'600', marginRight:'10px'}}><span style={{color:'#279C33', marginRight:'8px'}}>+</span>{geralDebito}</p><svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path d="M1.18164 1L6.00048 6L1.18164 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></div>
                  </div>

                   <div style={{borderBottom:'solid 1px #F1F1F1', display:'flex',  padding: '0px 4px 0px 6px', height:'41px',cursor:'pointer'}}>
                    <div style={{ classNam:'font-p', display:'flex',alignItems:'center', marginRight:'auto'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'11px', fontWeight:'400'}}>Crédito</p></div>
                    <div style={{ display:'flex', alignItems:'center'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'11px', fontWeight:'600', marginRight:'10px'}}><span style={{color:'#279C33', marginRight:'8px'}}>+</span>{geralCredito}</p><svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path d="M1.18164 1L6.00048 6L1.18164 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></div>
                  </div>

                 
                 

                  <div style={{borderBottom:'solid 1px #F1F1F1', display:'flex',  padding: '0px 4px 0px 6px', height:'41px', cursor:'pointer'}}>
                    <div style={{ display:'flex',alignItems:'center', marginRight:'auto'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'11px', fontWeight:'400', whiteSpace: 'nowrap',}}>Xuliz</p></div>
                    <div style={{ display:'flex', alignItems:'center'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'11px', fontWeight:'600', marginRight:'15px'}}><span style={{color:'#279C33', marginRight:'8px'}}>+</span>{geralXuliz}</p><svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path d="M1.18164 1L6.00048 6L1.18164 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></div>
                  </div>

                   

                 
                </div>
              </div>
            </div>


            {/* <div style={{ paddingTop:'21px'}}>
              <p style={{fontFamily:'Montserrat', fontSize:'14px', fontWeight:'600',}}>Conta cadastrada para depósito de fundos:</p>
              <div style={{border:'solid 1px #F1F1F1', borderRadius:'10px', padding:'22px 25px'}}>
                <div style={{ display:'flex', alignItems:'center', }}>
                  <div style={{display:'block', marginRight:'auto'}}>
                    <div style={{display:'flex'}}>
                      <img style={{width:'18px', height:'18px'}} src={Bancoob} alt="Minha Imagem" />
                      <p style={{ fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', marginLeft:'8px', marginRight:'20px', whiteSpace: 'nowrap'}}>Bancoob S.A.</p>
                    </div>
                    <p style={{fontFamily:'Montserrat', fontSize:'8px', fontWeight:'400', color:'#999', whiteSpace: 'nowrap'}}>Ag.: 0001 | Cc.:  22700847-2</p>
                  </div>
                  <div style={{ display:'flex', flexDirection:'column'}}>
                    <p style={{fontFamily:'Montserrat', fontSize:'10px', fontWeight:'400', color:'#262626', whiteSpace: 'nowrap' }}>Valor depositado:</p>

                    <p style={{fontFamily:'Montserrat', fontSize:'10px', fontWeight:'600', color:'#262626', paddingLeft:'20px' }}>R$267.849,92</p>
                  </div>
                </div>
                <div style={{display:'flex',justifyContent:'flex-end'}}>
                  <div style={{paddingTop:'30px'}}>
                      <button style={{padding:'8px 10px', border:'none', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', background:'#F1F1F1', borderRadius:'8px'}}>Detalhar valores</button>
                  </div>
                </div>
              </div>
              <div style={{paddingTop:'20px'}}>
                <div style={{display:'inline-flex'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 17" fill="none">
                    <path d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z" fill="#262626"/>
                    <path d="M6.3175 6.25C6.49383 5.74875 6.84186 5.32608 7.29997 5.05685C7.75807 4.78762 8.29667 4.6892 8.82038 4.77903C9.34409 4.86886 9.81911 5.14114 10.1613 5.54765C10.5035 5.95415 10.6908 6.46864 10.69 7C10.69 8.5 8.44 9.25 8.44 9.25" fill="#262626"/>
                    <path d="M6.3175 6.25C6.49383 5.74875 6.84186 5.32608 7.29997 5.05685C7.75807 4.78762 8.29667 4.6892 8.82038 4.77903C9.34409 4.86886 9.81911 5.14114 10.1613 5.54765C10.5035 5.95415 10.6908 6.46864 10.69 7C10.69 8.5 8.44 9.25 8.44 9.25M8.5 12.25H8.5075M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z" stroke="#F1F1F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p style={{fontFamily:'Montserrat', fontSize:'10px', fontWeight:'600', color:'#262626', marginLeft:'5px', paddingTop:'2px'}}>Central de ajuda</p>
                </div>
                <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626',}}>Realize sua solicitação até 16:00 as para receber no mesmo dia. <br/> Após esse horário, você receberá no próximo dia útil.  Transação <br/> livre de IOF.</p>
               </div>
            </div> */}
          </div>
          <div style={{display:'inline-block', marginLeft:'15px'}}>

            <div className="consultar-recebiveis" style={{background:'#F1F1F1', borderRadius:'10px', marginTop:'40px', height:'734px', width:'auto'}}>
              <div style={{borderBottom:'solid 5px #3D3D3D', padding: '38px 30px 38px 20px'}}>
                <p style={{whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'24px', fontWeight:'600', margin:'0'}}>Consulta de recebíveis</p>
              </div>
              <div style={{margin:'10px', height:'600px'}}>
                <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                  <div style={{ marginTop:'40px', marginBottom:'10px'}}>
                    <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                      <p style={{marginRight:'30px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Data de <br/> pagamento</p>
                      <p style={{marginRight:'30px', whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Bandeira</p>
                      <p style={{marginRight:'37px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Tipo de <br/> lançamento</p>
                      <p style={{marginRight:'25px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', whiteSpace: 'nowrap'}}>Quantidade <br/> de transações</p>
                      <p style={{marginRight:'18px', whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Valor bruto</p>
                      <p style={{whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Valor líquido</p>
                    </div>
                  </div>
                </div>
                <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                      <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'44px'}}>
                        <img style={{width:'36px', height:'19px'}} src={bXuliz}/>
                      </div>
                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'38px', whiteSpace: 'nowrap'}}>Crédito à vista</p>

                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'27px'}}>250</p>

                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'87px', whiteSpace: 'nowrap'}}>R$ 6.535,98</p>

                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'20px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 6.475,83</p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="3" height="13"
                      viewBox="0 0 4 13" fill="none">
                      <path d="M3.5 1.5C3.5 2.32843 2.82843 3 2 3C1.17157 3 0.5 2.32843 0.5 1.5C0.5 0.671573 1.17157 0 2 0C2.82843 0 3.5 0.671573 3.5 1.5Z" fill="#3D3D3D"/>
                      <path d="M3.5 6.5C3.5 7.32843 2.82843 8 2 8C1.17157 8 0.5 7.32843 0.5 6.5C0.5 5.67157 1.17157 5 2 5C2.82843 5 3.5 5.67157 3.5 6.5Z" fill="#3D3D3D"/>
                      <path d="M3.5 11.5C3.5 12.3284 2.82843 13 2 13C1.17157 13 0.5 12.3284 0.5 11.5C0.5 10.6716 1.17157 10 2 10C2.82843 10 3.5 10.6716 3.5 11.5Z" fill="#3D3D3D"/>
                    </svg>
                    </div>
                  </div>
                </div>

                <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                  <div style={{ marginBottom:'10px'}}>
                    <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                      <div style={{background:'#231F20', padding:'5px', borderRadius:'4px', marginLeft:'44px'}}>
                        <img style={{width:'36px', height:'19px'}} src={bElo}/>
                      </div>
                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'38px', whiteSpace: 'nowrap'}}>Crédito à vista</p>

                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'27px'}}>50</p>

                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'94px', whiteSpace: 'nowrap'}}>R$ 6.535,98</p>

                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'20px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 6.475,83</p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="3" height="13"
                      viewBox="0 0 4 13" fill="none">
                      <path d="M3.5 1.5C3.5 2.32843 2.82843 3 2 3C1.17157 3 0.5 2.32843 0.5 1.5C0.5 0.671573 1.17157 0 2 0C2.82843 0 3.5 0.671573 3.5 1.5Z" fill="#3D3D3D"/>
                      <path d="M3.5 6.5C3.5 7.32843 2.82843 8 2 8C1.17157 8 0.5 7.32843 0.5 6.5C0.5 5.67157 1.17157 5 2 5C2.82843 5 3.5 5.67157 3.5 6.5Z" fill="#3D3D3D"/>
                      <path d="M3.5 11.5C3.5 12.3284 2.82843 13 2 13C1.17157 13 0.5 12.3284 0.5 11.5C0.5 10.6716 1.17157 10 2 10C2.82843 10 3.5 10.6716 3.5 11.5Z" fill="#3D3D3D"/>
                    </svg>
                    </div>
                  </div>
                </div>

                <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                      <div style={{background:'#051F51', padding:'5px', borderRadius:'4px', marginLeft:'44px'}}>
                        <img style={{width:'36px', height:'19px'}} src={bVisa}/>
                      </div>
                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'38px', whiteSpace: 'nowrap'}}>Crédito à vista</p>

                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'27px'}}>300</p>

                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'86px', whiteSpace: 'nowrap'}}>R$ 6.535,98</p>

                      <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'20px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 6.475,83</p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="3" height="13"
                      viewBox="0 0 4 13" fill="none">
                      <path d="M3.5 1.5C3.5 2.32843 2.82843 3 2 3C1.17157 3 0.5 2.32843 0.5 1.5C0.5 0.671573 1.17157 0 2 0C2.82843 0 3.5 0.671573 3.5 1.5Z" fill="#3D3D3D"/>
                      <path d="M3.5 6.5C3.5 7.32843 2.82843 8 2 8C1.17157 8 0.5 7.32843 0.5 6.5C0.5 5.67157 1.17157 5 2 5C2.82843 5 3.5 5.67157 3.5 6.5Z" fill="#3D3D3D"/>
                      <path d="M3.5 11.5C3.5 12.3284 2.82843 13 2 13C1.17157 13 0.5 12.3284 0.5 11.5C0.5 10.6716 1.17157 10 2 10C2.82843 10 3.5 10.6716 3.5 11.5Z" fill="#3D3D3D"/>
                    </svg>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop:'233px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                  {/* <div style={{paddingLeft:'19px', display:'inline-flex', paddingTop:'13px'}}>
                    <p style={{fontFamily:'Montserrat', fontSize:'10px', fontWeight:'400', color:'#999', margin: '0', whiteSpace: 'nowrap'}}>0-3 de 3 recebíveis</p>
                  </div> */}

                  {/* <div style={{ transform:'scale(0.77)', paddingRight:'5px', display:'inline-flex'}}>
                    <button style={{background:'#FFF', padding:'3px', border:'1px solid #0006241A', borderRadius:'5px', marginRight:'12px'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 18 18" fill="none">
                      <path d="M11.1447 6.10886C11.2912 6.2553 11.2912 6.49274 11.1447 6.63919L8.41027 9.37501L11.1447 12.11C11.2912 12.2564 11.2912 12.4938 11.1447 12.6403C10.9983 12.7867 10.7608 12.7867 10.6144 12.6403L7.34961 9.37501L10.6144 6.10886C10.7608 5.96241 10.9983 5.96241 11.1447 6.10886Z" fill="#000624" fill-opacity="0.3"/>
                      </svg>
                    </button>

                    <div style={{background:'#3D3D3D', borderRadius:'5px', width:'33px', height:'30px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                      <p style={{paddingTop:'15px', color:'#FFF'}}>1</p>
                    </div>

                    <button style={{background:'#FFF', padding:'3px', border:'1px solid #0006241A', borderRadius:'5px', marginLeft:'12px'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 18 18" fill="none">
                      <path d="M6.85528 11.8911C6.70883 11.7447 6.70883 11.5073 6.85528 11.3608L9.58973 8.62499L6.85528 5.89004C6.70883 5.7436 6.70883 5.50616 6.85528 5.35971C7.00173 5.21327 7.23916 5.21327 7.38561 5.35971L10.6504 8.62499L7.38561 11.8911C7.23916 12.0376 7.00173 12.0376 6.85528 11.8911Z" fill="#000624" fill-opacity="0.3"/>
                      </svg>
                    </button>
                  </div> */}
                </div>

              </div>
            </div>
          </div>



      





          <div className="consultar-recebiveis-mobile" style={{background:'#F1F1F1', borderRadius:'10px', marginTop:'40px', marginBottom:'20px', height:'auto', width:'auto'}}>
              
            <div style={{ height:'100%'}}>
              <div style={{borderBottom:'solid 5px #3D3D3D', padding: '38px 30px 38px 20px'}}>
                <p style={{whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'18px', fontWeight:'600', margin:'0'}}>Consulta de recebíveis</p>
              </div>

              <TabelaRecebiveisMobile/>
              </div>
            </div>
        </div>
      </div>
    </div> 

        </>
      )
  }

 










  const TabelaRecebiveis = ()=>{

    return(
      <>
      <div style={{height:500,overflow:'scroll'}}>
          {recebiveisPagamento.map((transaction, index) => (    
                <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px'}}>
                      
                      <div style={{flex:1,height:40,justifyContent:'center',alignItems:'center',display:'flex'}}>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>{transaction.data_ref}</p>
                      </div>

                      <div style={{flex:1,height:40,justifyContent:'center',alignItems:'center',display:'flex'}}>
                        {bandeiraTipo(transaction.bandeira)}
                      </div>

                      <div style={{flex:1,height:40,justifyContent:'center',alignItems:'center',display:'flex'}}>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', whiteSpace: 'nowrap'}}>{transaction.bandeira}</p>
                      </div>

                      <div style={{flex:1,height:40,justifyContent:'center',alignItems:'center',display:'flex'}}>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>{transaction.quantidade_transacoes}</p>
                      </div>

                      <div style={{flex:1,height:40,justifyContent:'center',alignItems:'center',display:'flex'}}>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', whiteSpace: 'nowrap'}}>{transaction.liquido}</p>
                      </div>

                      <div style={{flex:1,height:40,justifyContent:'center',alignItems:'center',display:'flex'}}>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', whiteSpace: 'nowrap'}}>{transaction.bruto}</p>
                      </div>


                      
                        
                        
                    </div>
                  </div>
                </div>
          ))}
          </div>
        </> 
    )
   
    
  }


// desktop
 return(
  <>

  <div style={{height:'100vh',display:"flex"}}>
    <div style={{backgroundColor:'#262626'}}>
        <Menu page="recebiveis"/>
    </div>
    

    <div style={{height:'100vh',display:"flex",width:"100%",overflow:'auto',flex:1,padding:20}}>
         
      <div className="container-principal" style={{width:'100%',  padding:'13px 0px 13px 13px'}}>
        <div style={{ borderBottom:'solid 1.5px #999'}}>
          <div style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:' center'}}>

            <div style={{ display:'inline-block'}}>
              <div  onClick={()=>{window.history.back();}} style={{display: 'flex', cursor:'pointer'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"  viewBox="0 0 16 16" fill="none">
                <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p style={{color:'#999',fontFamily:'Montserrat', fontSize:'13px', fontWeight:'600', marginLeft:'10px'}}>Voltar</p>
              </div>
              <div>
                <p style={{fontFamily:'Montserrat', fontSize:'24px', fontWeight:'600'}}>Resumo</p>
                <div style={{display:'flex',alignItems:'center', background:'#F2E2C4', borderRadius: '5px', padding:'5px', justifyContent:'center'}}><p style={{margin:'0px', marginRight:'5px', fontFamily:'Montserrat', fontSize:'10px', fontWeight:'600'}}>Recebíveis</p> 
                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 4 7" fill="none">
                <path d="M1 6L3.14286 3.5L1 1" stroke="#3D3D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p style={{margin:'0px', marginLeft:'5px', fontFamily:'Montserrat', fontSize:'10px', fontWeight:'400'}}>Resumo</p></div>  
              </div>             
            </div>

            {/* <div style={{display:'inline-flex', height:'40px',alignItems:'center'}}>

              <div style={{display:"flex",width:'38%',paddingLeft:10,paddingRight:10,border:'1px solid #262626',height:'100%',borderRadius:15}}>
                <div style={{backgroundColor:'white',width:'75%',flexDirection:'row',display:'flex'}}>
                  <div style={{zIndex:20}} > 

                   <input
                          onClick={()=>toggleCalendar()}
                          placeholder="Período"
                            type="text"
                            style={{borderColor:'white',borderWidth:0,fontSize:10,height:10,width:100 ,marginTop:15,fontFamily:'Montserrat'}}
                            readOnly
                            id="data_filtro"
                            value={
                                 startDate && endDate
                                 ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                                 : (startDate == null ? '':startDate.toLocaleDateString())
                             }
                          />

                            {showCalendar == true&&(<>
                                    <DatePicker
                                      selected={startDate}
                                      onChange={handleDateChange}
                                      startDate={startDate}
                                      endDate={endDate}
                                      selectsRange
                                      inline
                                    />
                                    <Button style={{position:'relative',width:'100%'}} onClick={()=>clickAplicar()}>Aplicar</Button>
                                    </>
                                  )}
                  </div>
                </div>
                    <div style={{flex:1,textAlign:'-webkit-right',marginTop:5}} >
                      <FontAwesomeIcon icon={faCalendar} style={{fontSize:15,color:"#8c8c8c"}}   onClick={()=>toggleCalendar()} />
                    </div>
              </div>


              <div style={{display:'flex',flex:1,width:'40%',height:'100%',borderRadius:15,margin:4,border:'1px solid #8c8c8c',alignItems:'center'}}>   
                          <select class="form-control" id="qual_bandeira" onChange={()=> VerificaChamar()} style={{borderRadius:15}}>
                                <option value="bandeira">Bandeira</option>
                                <option value="Xuliz">Xuliz</option>
                                <option value="Visa">Visa</option>
                                <option value="Master">Master</option>
                                <option value="Elo">Elo</option>
                                <option value="Hipercard">Hipercard</option>
                                <option value="Amex">Amex</option>
                               
                        </select>
                  </div>

                  <div style={{display:'flex',flex:1,width:'40%',height:'100%',borderRadius:15,margin:4,border:'1px solid #8c8c8c',alignItems:'center'}}>
                    <select class="form-control" id="qual_forma" onChange={()=> VerificaChamar()} style={{borderRadius:15}}>
                                <option value="forma_pga">Forma de Pagamento</option >
                                <option value="Crédito">Crédito</option>
                                <option value="CréditoParcelado">Parcelado</option>
                                <option value="Débito">Débito</option>
                                <option value="Xuliz">Xuliz</option>
                                <option value="Pix">Pix</option>
                                

                        </select>
                  </div>
            </div> */}
          </div>
          <div className="container-valores" style={{display:'flex', alignItems:'center'}}>
            <div className="valor-total" style={{paddingTop: '20px',paddingBottom: '20px', marginRight:'25px'}}>
              <div style={{background:'#F1F1F1', display:'flex', borderRadius:'10px', padding:'10px 3px'}}>
                <div style={{ margin:'10px', display:'flex', flexDirection:'column'}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'13px', fontWeight:'400px'}}>Valor total</p>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'18px', fontWeight:'600'}}>depositado</p>
                </div>
                <div style={{display:'flex', alignItems:'center', margin:'10px',}}>
                  {/* <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'30px', marginRight:'5px', color:'#3D3D3D'}}>R$</p> */}
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'30px', fontWeight:'600', color:'#3D3D3D'}}>{geralBruto}</p>
                </div>
              </div>
            </div>
            <div className="valor-total" style={{ paddingTop: '20px',paddingBottom: '20px', marginRight:'25px'}}>
              <div style={{ background:'#F1F1F1', display:'flex', borderRadius:'10px', padding:'10px 3px'}}>
                <div style={{ margin:'10px', display:'flex', flexDirection:'column'}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'13px', fontWeight:'400px'}}>Valor total</p>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'18px', fontWeight:'600'}}>taxa</p>
                </div>
                <div style={{ display:'flex', alignItems:'center', margin:'10px',}}>
                  {/* <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'30px', marginRight:'5px', color:'#3D3D3D'}}>R$</p> */}
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'30px', fontWeight:'600', color:'#3D3D3D'}}>{geralTaxas}</p>
                </div>
              </div>
            </div>
            <div className="valor-total"  style={{ paddingTop: '20px',paddingBottom: '20px'}}>
              <div style={{background:'#F1F1F1', display:'flex', borderRadius:'10px', padding:'10px 3px'}}>
                <div style={{ margin:'10px', display:'flex', flexDirection:'column'}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'13px', fontWeight:'400px'}}>Valor total a</p>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'18px', fontWeight:'600'}}>depositar</p>
                </div>
                <div style={{display:'flex', alignItems:'center', margin:'10px',}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'30px', marginRight:'5px', color:'#3D3D3D'}}></p>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'30px', fontWeight:'600', color:'#3D3D3D'}}>{geralDepositar}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-lancamentos" style={{ display:'flex'}}>
          <div style={{ display:'inline-block', paddingTop:'40px', }}>
            <div style={{}}>
              <p style={{fontFamily:'Montserrat', fontSize:'24px', fontWeight:'600'}}>Lançamentos no período</p>
              <div style={{border:'solid 1px #F1F1F1', borderRadius:'10px', padding:'22px 25px'}}>
                <div style={{ display:'block'}}>
                  <div style={{borderBottom:'solid 1px #F1F1F1', display:'flex',justifyContent:'space-between', padding: '0px 11px 0px 6px', height:'41px',cursor:'pointer'}}>
                    <div style={{ display:'flex',alignItems:'center', marginRight:'210px'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'16px', fontWeight:'400'}}>Débito</p></div>
                    <div style={{ display:'flex', alignItems:'center'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'16px', fontWeight:'600', marginRight:'15px'}}><span style={{color:'#279C33', marginRight:'8px'}}>+</span>{geralDebito}</p><svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path d="M1.18164 1L6.00048 6L1.18164 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></div>
                  </div>

                  
                  <div style={{borderBottom:'solid 1px #F1F1F1', display:'flex',justifyContent:'space-between', padding: '0px 11px 0px 6px', height:'41px', cursor:'pointer'}}>
                    <div style={{ display:'flex',alignItems:'center', marginRight:'152px'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'16px', fontWeight:'400', whiteSpace: 'nowrap',}}>Crédito à vista</p></div>
                    <div style={{ display:'flex', alignItems:'center'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'16px', fontWeight:'600', marginRight:'15px'}}><span style={{color:'#279C33', marginRight:'8px'}}>+</span>{geralCredito}</p><svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path d="M1.18164 1L6.00048 6L1.18164 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></div>
                  </div>

                  <div style={{borderBottom:'solid 1px #F1F1F1', display:'flex',justifyContent:'space-between', padding: '0px 11px 0px 6px', height:'41px', cursor:'pointer'}}>
                    <div style={{ display:'flex',alignItems:'center', marginRight:'190px'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'16px', fontWeight:'400', whiteSpace: 'nowrap',}}>Xuliz App</p></div>
                    <div style={{ display:'flex', alignItems:'center'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'16px', fontWeight:'600', marginRight:'15px'}}><span style={{color:'#279C33', marginRight:'8px'}}>+</span>{geralXuliz}</p><svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path d="M1.18164 1L6.00048 6L1.18164 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></div>
                  </div>

                  {/* <div style={{borderBottom:'solid 1px #F1F1F1', display:'flex',  padding: '0px 11px 0px 6px', height:'41px', cursor:'pointer'}}>
                    <div style={{ display:'flex',alignItems:'center', marginRight:'176px'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'16px', fontWeight:'400'}}>Cancelamento</p></div>
                    <div style={{ display:'flex', alignItems:'center'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'16px', fontWeight:'600', marginRight:'15px'}}><span style={{color:'#E95454', marginRight:'8px'}}>-</span>R$0,00</p><svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path d="M1.18164 1L6.00048 6L1.18164 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></div>
                  </div> */}

                  {/* <div style={{borderBottom:'solid 1px #F1F1F1', display:'flex',  padding: '0px 11px 0px 6px', height:'41px', cursor:'pointer'}}>
                    <div style={{ display:'flex',alignItems:'center', marginRight:'200px'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'16px', fontWeight:'400'}}>Contestação</p></div>
                    <div style={{ display:'flex', alignItems:'center'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'16px', fontWeight:'600', marginRight:'15px'}}><span style={{color:'#E95454', marginRight:'8px'}}>-</span>R$0,00</p><svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path d="M1.18164 1L6.00048 6L1.18164 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></div>
                  </div> */}
                </div>
              </div>
            </div>


            <div style={{ paddingTop:'21px'}}>
              {/* <p style={{fontFamily:'Montserrat', fontSize:'16px', fontWeight:'600'}}>Conta cadastrada para depósito de fundos:</p> */}
              {/* <div style={{border:'solid 1px #F1F1F1', borderRadius:'10px', padding:'22px 25px'}}>
                <div style={{ display:'flex', alignItems:'center', }}>
                  <div style={{display:'block', marginRight:'114px'}}>
                    <div style={{display:'flex'}}>
                      <img style={{width:'18px', height:'18px'}} src={Bancoob} alt="Minha Imagem" />
                      <p style={{fontFamily:'Montserrat', fontSize:'15px', fontWeight:'600', marginLeft:'8px'}}>Bancoob S.A.</p>
                    </div>
                    <p style={{fontFamily:'Montserrat', fontSize:'15px', fontWeight:'400', color:'#999'}}>Ag.: 0001 | Cc.:  22700847-2</p>
                  </div>
                  <div style={{ display:'flex', flexDirection:'column'}}>
                    <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626' }}>Valor depositado:</p>

                    <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#262626', paddingLeft:'20px' }}>R$0,00</p>
                  </div>
                </div>
                <div style={{display:'flex',justifyContent:'flex-end'}}>
                  <div style={{paddingTop:'30px'}}>
                      <button style={{padding:'8px 10px', border:'none', fontFamily:'Montserrat', fontSize:'15px', fontWeight:'400', color:'#262626', background:'#F1F1F1', borderRadius:'8px'}}>Detalhar valores</button>
                  </div>
                </div>
              </div> */}
              <div style={{paddingTop:'20px'}}>
                <div style={{display:'inline-flex'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 17" fill="none">
                    <path d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z" fill="#262626"/>
                    <path d="M6.3175 6.25C6.49383 5.74875 6.84186 5.32608 7.29997 5.05685C7.75807 4.78762 8.29667 4.6892 8.82038 4.77903C9.34409 4.86886 9.81911 5.14114 10.1613 5.54765C10.5035 5.95415 10.6908 6.46864 10.69 7C10.69 8.5 8.44 9.25 8.44 9.25" fill="#262626"/>
                    <path d="M6.3175 6.25C6.49383 5.74875 6.84186 5.32608 7.29997 5.05685C7.75807 4.78762 8.29667 4.6892 8.82038 4.77903C9.34409 4.86886 9.81911 5.14114 10.1613 5.54765C10.5035 5.95415 10.6908 6.46864 10.69 7C10.69 8.5 8.44 9.25 8.44 9.25M8.5 12.25H8.5075M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z" stroke="#F1F1F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p style={{fontFamily:'Montserrat', fontSize:'10px', fontWeight:'600', color:'#262626', marginLeft:'5px', paddingTop:'2px'}}>Central de ajuda</p>
                </div>
                <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626',}}>Realize sua solicitação até 16:00 as para receber no mesmo dia. <br/> Após esse horário, você receberá no próximo dia útil.  Transação <br/> livre de IOF.</p>
                {/* <button style={{display:'inline-flex', padding: '15px 50px', justifyContent:'center', alignItems:'center', background:'#3D3D3D 99.99%', border:'none', borderRadius:'5px'}}><p style={{fontFamily:'Montserrat', fontSize:'16px', fontWeight:'400', color:'#F2E2C4', margin:'0'}}>Simular antecipação</p></button> */}
              </div>
            </div>
          </div>
          <div style={{display:'inline-block'}}>
            <div style={{background:'#F1F1F1', borderRadius:'10px', marginTop:'40px', marginLeft:'15px', height:'734px', width:'638px'}}>
              <div style={{borderBottom:'solid 5px #3D3D3D', padding: '38px 308px 38px 20px'}}>
                <p style={{whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'24px', fontWeight:'600', margin:'0'}}>Consulta de recebíveis</p>
              </div>
              <div style={{margin:'10px', height:'600px'}}>
                <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                  <div style={{ marginTop:'40px', marginBottom:'10px'}}>
                    <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                      <p style={{marginRight:'30px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Data de <br/> pagamento</p>
                      <p style={{marginRight:'30px', whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Bandeira</p>
                      <p style={{marginRight:'37px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Tipo de <br/> lançamento</p>
                      <p style={{marginRight:'25px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', whiteSpace: 'nowrap'}}>Quantidade <br/> de transações</p>
                      <p style={{marginRight:'18px', whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Valor bruto</p>
                      <p style={{whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Valor líquido</p>
                    </div>
                  </div>
                </div>
                
                <TabelaRecebiveis/>

                

                
                <div style={{ marginTop:'233px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                  {/* <div style={{paddingLeft:'19px', display:'inline-flex', paddingTop:'13px'}}>
                    <p style={{fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400', color:'#999', margin: '0',}}>0-3 de 3 recebíveis</p>
                  </div> */}

                  {/* <div style={{ paddingRight:'19px', display:'inline-flex'}}>
                    <button style={{background:'#FFF', padding:'3px', border:'1px solid #0006241A', borderRadius:'5px', marginRight:'12px'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 18 18" fill="none">
                      <path d="M11.1447 6.10886C11.2912 6.2553 11.2912 6.49274 11.1447 6.63919L8.41027 9.37501L11.1447 12.11C11.2912 12.2564 11.2912 12.4938 11.1447 12.6403C10.9983 12.7867 10.7608 12.7867 10.6144 12.6403L7.34961 9.37501L10.6144 6.10886C10.7608 5.96241 10.9983 5.96241 11.1447 6.10886Z" fill="#000624" fill-opacity="0.3"/>
                      </svg>
                    </button>

                    <div style={{background:'#3D3D3D', borderRadius:'5px', width:'33px', height:'30px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                      <p style={{paddingTop:'15px', color:'#FFF'}}>1</p>
                    </div>

                    <button style={{background:'#FFF', padding:'3px', border:'1px solid #0006241A', borderRadius:'5px', marginLeft:'12px'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 18 18" fill="none">
                      <path d="M6.85528 11.8911C6.70883 11.7447 6.70883 11.5073 6.85528 11.3608L9.58973 8.62499L6.85528 5.89004C6.70883 5.7436 6.70883 5.50616 6.85528 5.35971C7.00173 5.21327 7.23916 5.21327 7.38561 5.35971L10.6504 8.62499L7.38561 11.8911C7.23916 12.0376 7.00173 12.0376 6.85528 11.8911Z" fill="#000624" fill-opacity="0.3"/>
                      </svg>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  </>
  
  );

};

export default Recebiveis;
