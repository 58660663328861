import styles from "./ListaMaquina.module.css";
const ListaMaquina = () => {
  return (
    <div className={styles.listaMaquina}>
      <div className={styles.component2}>
        <div className={styles.defalut}>
          <div className={styles.div}>Xuliz</div>
        </div>
        <div className={styles.defalut1}>
          <div className={styles.div}>Mastercard</div>
        </div>
        <div className={styles.defalut2}>
          <div className={styles.div}>Visa</div>
        </div>
        <div className={styles.defalut3}>
          <div className={styles.div}>Elo</div>
        </div>
      </div>
    </div>
  );
};

export default ListaMaquina;
