import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./TelaHomeAreaparceiros.module.css";
const TelaHomeAreaparceiros = () => {
  const navigate = useNavigate();

  const onGroupContainer77Click = useCallback(() => {
    navigate("/desktop");
  }, [navigate]);

  const onGroupContainer81Click = useCallback(() => {
    navigate("/tela-produtos01-areaparceiros");
  }, [navigate]);

  const onGroupContainer84Click = useCallback(() => {
    navigate("/tela-relatorios01-areaparceiros");
  }, [navigate]);

  const onGroupContainer85Click = useCallback(() => {
    navigate("/tela-xloja01-areaparceiros");
  }, [navigate]);

  const onGroupContainer86Click = useCallback(() => {
    navigate("/tela-saque01-areaparceiros");
  }, [navigate]);

  return (
    <div className={styles.telaHomeAreaparceiros}>
      <div className={styles.telaHomeAreaparceirosChild} />
      <div className={styles.minhasVendas}>Minhas vendas</div>
      <div className={styles.fundosAReceber}>Fundos a receber</div>
      <div className={styles.recebidoHoje}>Recebido hoje</div>
      <div className={styles.telaHomeAreaparceirosItem} />
      <div className={styles.telaHomeAreaparceirosInner} />
      <div className={styles.miniDashboard}>Mini dashboard</div>
      <div className={styles.groupParent}>
        <div className={styles.janParent}>
          <div className={styles.jan}>Jan</div>
          <div className={styles.feb}>Feb</div>
          <div className={styles.mar}>Mar</div>
          <div className={styles.apr}>Apr</div>
          <div className={styles.may}>May</div>
          <div className={styles.jun}>Jun</div>
          <div className={styles.jul}>Jul</div>
          <div className={styles.aug}>Aug</div>
          <div className={styles.sep}>Sep</div>
          <div className={styles.oct}>Oct</div>
          <div className={styles.nov}>Nov</div>
          <div className={styles.dec}>Dec</div>
        </div>
        <div className={styles.groupChild} />
        <div className={styles.groupItem} />
        <div className={styles.groupContainer}>
          <div className={styles.kParent}>
            <div className={styles.k}>30k</div>
            <div className={styles.k1}>40k</div>
            <div className={styles.k2}>50k</div>
            <div className={styles.k3}>15k</div>
            <div className={styles.div}>0</div>
          </div>
          <div className={styles.groupInner} />
          <div className={styles.lineDiv} />
          <div className={styles.groupChild1} />
          <div className={styles.graph}>
            <div className={styles.groupDiv}>
              <div className={styles.rectangleParent}>
                <div className={styles.rectangleDiv} />
                <div className={styles.groupChild2} />
                <div className={styles.groupChild3} />
                <div className={styles.groupChild4} />
                <div className={styles.groupChild5} />
                <div className={styles.groupChild6} />
                <div className={styles.groupChild7} />
                <div className={styles.groupChild8} />
                <div className={styles.groupChild9} />
                <div className={styles.groupChild10} />
                <div className={styles.groupChild11} />
                <div className={styles.groupChild12} />
              </div>
              <div className={styles.rectangleGroup}>
                <div className={styles.groupChild13} />
                <div className={styles.groupChild14} />
                <div className={styles.groupChild15} />
                <div className={styles.groupChild16} />
                <div className={styles.groupChild17} />
                <div className={styles.groupChild18} />
                <div className={styles.groupChild19} />
                <div className={styles.groupChild20} />
                <div className={styles.groupChild21} />
                <div className={styles.groupChild22} />
                <div className={styles.groupChild23} />
                <div className={styles.groupChild24} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.groupParent1}>
        <div className={styles.incomeParent}>
          <div className={styles.income}>
            <div className={styles.msAtual}>Mês atual</div>
            <img className={styles.incomeChild} alt="" src="/ellipse-167.svg" />
          </div>
          <div className={styles.cost}>
            <div className={styles.msAtual}>Mês passado</div>
            <img className={styles.incomeChild} alt="" src="/ellipse-168.svg" />
          </div>
        </div>
        <div className={styles.vendasMensais}>Vendas mensais</div>
      </div>
      <img className={styles.vectorIcon} alt="" src="/vector28.svg" />
      <div className={styles.variaoMiniextrato}>
        <div className={styles.variaoMiniextratoChild} />
        <div className={styles.hojeParent}>
          <div className={styles.hoje}>Hoje</div>
          <div className={styles.groupChild25} />
        </div>
        <div className={styles.abril2023}>12, Abril, 2023</div>
        <div className={styles.totalDoPerodoParent}>
          <div className={styles.totalDoPerodo}>Total do período</div>
          <div className={styles.r}>R$</div>
          <div className={styles.div1}>4.005,35</div>
        </div>
        <div className={styles.diasParent}>
          <div className={styles.dias}>7 Dias</div>
          <div className={styles.frameChild} />
        </div>
        <div className={styles.variaoMiniextratoInner}>
          <div className={styles.diasGroup}>
            <div className={styles.dias1}>30 Dias</div>
            <div className={styles.frameItem} />
          </div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.cashbackDistribudoParent}>
            <div className={styles.cashbackDistribudo}>
              Cashback distribuído
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.groupParent2}>
                <div className={styles.rParent}>
                  <div className={styles.cashbackDistribudo}>R$</div>
                  <div className={styles.div2}>120,16</div>
                </div>
                <img
                  className={styles.vectorIcon1}
                  alt=""
                  src="/vector29.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.horrioParent}>
          <div className={styles.horrio}>Horário</div>
          <div className={styles.formaDePagamento}>Forma de pagamento</div>
          <div className={styles.valortaxa}>Valor/Taxa</div>
          <div className={styles.cashback}>Cashback</div>
          <div className={styles.statusunidade}>Status/Unidade</div>
        </div>
        <div className={styles.variaoMiniextratoInner1}>
          <div className={styles.groupParent3}>
            <div className={styles.groupWrapper}>
              <div className={styles.groupWrapper}>
                <img className={styles.groupIcon} alt="" src="/group-428.svg" />
                <div className={styles.parent}>
                  <div className={styles.div3}>10:27</div>
                  <div className={styles.xuliz}>Xuliz</div>
                  <div className={styles.r756}>R$7,56</div>
                  <div className={styles.r21600}>R$216,00</div>
                  <div className={styles.div4}>+</div>
                  <div className={styles.div5}>-</div>
                  <div className={styles.r2592Parent}>
                    <div className={styles.r2592}>R$25,92</div>
                    <img
                      className={styles.vectorIcon2}
                      alt=""
                      src="/vector30.svg"
                    />
                  </div>
                  <div className={styles.fcamor}>FCAMOR</div>
                  <div className={styles.variaoStatus}>
                    <div className={styles.completado}>Completado</div>
                    <img
                      className={styles.vectorIcon3}
                      alt=""
                      src="/vector11.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.groupFrame}>
              <div className={styles.groupWrapper}>
                <img className={styles.groupIcon} alt="" src="/group-428.svg" />
                <div className={styles.group}>
                  <div className={styles.div3}>10:22</div>
                  <div className={styles.xuliz}>Xuliz</div>
                  <div className={styles.r756}>R$1,50</div>
                  <div className={styles.r21600}>R$50,00</div>
                  <div className={styles.div4}>+</div>
                  <div className={styles.div5}>-</div>
                  <div className={styles.r2592Parent}>
                    <div className={styles.r2592}>R$6,00</div>
                    <img
                      className={styles.vectorIcon4}
                      alt=""
                      src="/vector30.svg"
                    />
                  </div>
                  <div className={styles.fcamor}>FCAMOR</div>
                  <div className={styles.variaoStatus}>
                    <div className={styles.completado}>Completado</div>
                    <img
                      className={styles.vectorIcon5}
                      alt=""
                      src="/vector11.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.groupWrapper1}>
              <div className={styles.groupWrapper}>
                <img className={styles.groupIcon} alt="" src="/group-428.svg" />
                <div className={styles.container}>
                  <div className={styles.div3}>10:15</div>
                  <div className={styles.xuliz}>Crédito</div>
                  <div className={styles.r756}>R$15,96</div>
                  <div className={styles.r21600}>R$532,00</div>
                  <div className={styles.div4}>+</div>
                  <div className={styles.div5}>-</div>
                  <div className={styles.r2592Parent}>
                    <div className={styles.r2592}>R$63,84</div>
                    <img
                      className={styles.vectorIcon4}
                      alt=""
                      src="/vector30.svg"
                    />
                  </div>
                  <div className={styles.fcamor}>FCAMOR</div>
                  <div className={styles.variaoStatus}>
                    <div className={styles.completado}>Completado</div>
                    <img
                      className={styles.vectorIcon5}
                      alt=""
                      src="/vector11.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.groupWrapper2}>
              <div className={styles.groupWrapper}>
                <img className={styles.groupIcon} alt="" src="/group-428.svg" />
                <div className={styles.parent1}>
                  <div className={styles.div3}>9:59</div>
                  <div className={styles.xuliz}>Crédito</div>
                  <div className={styles.r108}>R$1,08</div>
                  <div className={styles.r21600}>R$36,00</div>
                  <div className={styles.div13}>-</div>
                  <div className={styles.div5}>-</div>
                  <div className={styles.r2592Parent}>
                    <div className={styles.r432}>R$4,32</div>
                    <img
                      className={styles.vectorIcon4}
                      alt=""
                      src="/vector30.svg"
                    />
                  </div>
                  <div className={styles.fcamor}>FCAMOR</div>
                  <div className={styles.variaoStatus3}>
                    <div className={styles.completado}>Estornado</div>
                    <img
                      className={styles.vectorIcon9}
                      alt=""
                      src="/vector31.svg"
                    />
                    <img
                      className={styles.vectorIcon9}
                      alt=""
                      src="/vector31.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.groupWrapper3}>
              <div className={styles.groupWrapper}>
                <img className={styles.groupIcon} alt="" src="/group-428.svg" />
                <div className={styles.parent1}>
                  <div className={styles.div3}>9:50</div>
                  <div className={styles.xuliz}>Crédito</div>
                  <div className={styles.r756}>R$1,08</div>
                  <div className={styles.r21600}>R$36,00</div>
                  <div className={styles.div4}>+</div>
                  <div className={styles.div5}>-</div>
                  <div className={styles.r2592Parent}>
                    <div className={styles.r2592}>R$4,32</div>
                    <img
                      className={styles.vectorIcon4}
                      alt=""
                      src="/vector30.svg"
                    />
                  </div>
                  <div className={styles.fcamor}>FCAMOR</div>
                  <div className={styles.variaoStatus}>
                    <div className={styles.completado}>Completado</div>
                    <img
                      className={styles.vectorIcon5}
                      alt=""
                      src="/vector11.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.groupWrapper4}>
              <div className={styles.groupWrapper}>
                <img className={styles.groupIcon} alt="" src="/group-428.svg" />
                <div className={styles.parent3}>
                  <div className={styles.div3}>9:48</div>
                  <div className={styles.xuliz}>Xuliz</div>
                  <div className={styles.r756}>R$0,81</div>
                  <div className={styles.r21600}>R$27,00</div>
                  <div className={styles.div4}>+</div>
                  <div className={styles.div5}>-</div>
                  <div className={styles.r2592Parent}>
                    <div className={styles.r2592}>R$3,24</div>
                    <img
                      className={styles.vectorIcon4}
                      alt=""
                      src="/vector30.svg"
                    />
                  </div>
                  <div className={styles.fcamor}>FCAMOR</div>
                  <div className={styles.variaoStatus}>
                    <div className={styles.completado}>Completado</div>
                    <img
                      className={styles.vectorIcon5}
                      alt=""
                      src="/vector11.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.groupWrapper5}>
              <div className={styles.groupWrapper}>
                <img className={styles.groupIcon} alt="" src="/group-428.svg" />
                <div className={styles.parent4}>
                  <div className={styles.div3}>9:47</div>
                  <div className={styles.xuliz}>Xuliz</div>
                  <div className={styles.r108}>R$15,69</div>
                  <div className={styles.r52300}>R$523,00</div>
                  <div className={styles.div22}>?</div>
                  <div className={styles.div23}>?</div>
                  <div className={styles.r2592Parent}>
                    <div className={styles.r432}>R$62,76</div>
                    <img
                      className={styles.vectorIcon4}
                      alt=""
                      src="/vector30.svg"
                    />
                  </div>
                  <div className={styles.fcamor}>FCAMOR</div>
                  <div className={styles.variaoStatus3}>
                    <div className={styles.completado}>Rejeitado</div>
                    <img
                      className={styles.vectorIcon16}
                      alt=""
                      src="/vector32.svg"
                    />
                    <img
                      className={styles.vectorIcon16}
                      alt=""
                      src="/vector32.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.lineParent}>
              <div className={styles.groupChild32} />
              <div className={styles.groupChild33} />
              <div className={styles.groupChild34} />
              <div className={styles.groupChild35} />
              <div className={styles.groupChild36} />
              <div className={styles.groupChild37} />
              <div className={styles.groupChild38} />
              <div className={styles.groupParent11}>
                <div className={styles.groupWrapper}>
                  <div className={styles.groupWrapper}>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-428.svg"
                    />
                    <div className={styles.parent}>
                      <div className={styles.div3}>10:27</div>
                      <div className={styles.xuliz}>Xuliz</div>
                      <div className={styles.r756}>R$7,56</div>
                      <div className={styles.r21600}>R$216,00</div>
                      <div className={styles.div4}>+</div>
                      <div className={styles.div5}>-</div>
                      <div className={styles.r2592Parent}>
                        <div className={styles.r2592}>R$25,92</div>
                        <img
                          className={styles.vectorIcon4}
                          alt=""
                          src="/vector30.svg"
                        />
                      </div>
                      <div className={styles.fcamor}>FCAMOR</div>
                      <div className={styles.variaoStatus}>
                        <div className={styles.completado}>Completado</div>
                        <img
                          className={styles.vectorIcon5}
                          alt=""
                          src="/vector11.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.groupFrame}>
                  <div className={styles.groupWrapper}>
                    <img
                      className={styles.groupChild40}
                      alt=""
                      src="/group-428.svg"
                    />
                    <div className={styles.group}>
                      <div className={styles.div3}>10:22</div>
                      <div className={styles.xuliz}>Xuliz</div>
                      <div className={styles.r756}>R$1,50</div>
                      <div className={styles.r21600}>R$50,00</div>
                      <div className={styles.div4}>+</div>
                      <div className={styles.div5}>-</div>
                      <div className={styles.r2592Parent}>
                        <div className={styles.r2592}>R$6,00</div>
                        <img
                          className={styles.vectorIcon20}
                          alt=""
                          src="/vector30.svg"
                        />
                      </div>
                      <div className={styles.fcamor}>FCAMOR</div>
                      <div className={styles.variaoStatus}>
                        <div className={styles.completado}>Completado</div>
                        <img
                          className={styles.vectorIcon21}
                          alt=""
                          src="/vector11.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.groupWrapper1}>
                  <div className={styles.groupWrapper}>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-428.svg"
                    />
                    <div className={styles.container}>
                      <div className={styles.div3}>10:15</div>
                      <div className={styles.xuliz}>Crédito</div>
                      <div className={styles.r756}>R$15,96</div>
                      <div className={styles.r21600}>R$532,00</div>
                      <div className={styles.div4}>+</div>
                      <div className={styles.div5}>-</div>
                      <div className={styles.r2592Parent}>
                        <div className={styles.r2592}>R$63,84</div>
                        <img
                          className={styles.vectorIcon22}
                          alt=""
                          src="/vector30.svg"
                        />
                      </div>
                      <div className={styles.fcamor}>FCAMOR</div>
                      <div className={styles.variaoStatus}>
                        <div className={styles.completado}>Completado</div>
                        <img
                          className={styles.vectorIcon23}
                          alt=""
                          src="/vector11.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.groupWrapper2}>
                  <div className={styles.groupWrapper}>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-428.svg"
                    />
                    <div className={styles.parent1}>
                      <div className={styles.div3}>9:59</div>
                      <div className={styles.xuliz}>Crédito</div>
                      <div className={styles.r108}>R$1,08</div>
                      <div className={styles.r21600}>R$36,00</div>
                      <div className={styles.div13}>-</div>
                      <div className={styles.div5}>-</div>
                      <div className={styles.r2592Parent}>
                        <div className={styles.r432}>R$4,32</div>
                        <img
                          className={styles.vectorIcon22}
                          alt=""
                          src="/vector30.svg"
                        />
                      </div>
                      <div className={styles.fcamor}>FCAMOR</div>
                      <div className={styles.variaoStatus3}>
                        <div className={styles.completado}>Estornado</div>
                        <img
                          className={styles.vectorIcon25}
                          alt=""
                          src="/vector31.svg"
                        />
                        <img
                          className={styles.vectorIcon25}
                          alt=""
                          src="/vector31.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.groupWrapper3}>
                  <div className={styles.groupWrapper}>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-428.svg"
                    />
                    <div className={styles.parent1}>
                      <div className={styles.div3}>9:50</div>
                      <div className={styles.xuliz}>Crédito</div>
                      <div className={styles.r756}>R$1,08</div>
                      <div className={styles.r21600}>R$36,00</div>
                      <div className={styles.div4}>+</div>
                      <div className={styles.div5}>-</div>
                      <div className={styles.r2592Parent}>
                        <div className={styles.r2592}>R$4,32</div>
                        <img
                          className={styles.vectorIcon22}
                          alt=""
                          src="/vector30.svg"
                        />
                      </div>
                      <div className={styles.fcamor}>FCAMOR</div>
                      <div className={styles.variaoStatus}>
                        <div className={styles.completado}>Completado</div>
                        <img
                          className={styles.vectorIcon23}
                          alt=""
                          src="/vector11.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.groupWrapper4}>
                  <div className={styles.groupWrapper}>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-428.svg"
                    />
                    <div className={styles.parent3}>
                      <div className={styles.div3}>9:48</div>
                      <div className={styles.xuliz}>Saldo Xuliz + Crédito</div>
                      <div className={styles.r756}>R$0,81</div>
                      <div className={styles.r21600}>R$27,00</div>
                      <div className={styles.div4}>+</div>
                      <div className={styles.div5}>-</div>
                      <div className={styles.r2592Parent}>
                        <div className={styles.r2592}>R$3,24</div>
                        <img
                          className={styles.vectorIcon22}
                          alt=""
                          src="/vector30.svg"
                        />
                      </div>
                      <div className={styles.fcamor}>FCAMOR</div>
                      <div className={styles.variaoStatus}>
                        <div className={styles.completado}>Completado</div>
                        <img
                          className={styles.vectorIcon23}
                          alt=""
                          src="/vector11.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.groupWrapper5}>
                  <div className={styles.groupWrapper}>
                    <img
                      className={styles.groupIcon}
                      alt=""
                      src="/group-428.svg"
                    />
                    <div className={styles.parent4}>
                      <div className={styles.div3}>9:47</div>
                      <div className={styles.xuliz}>Saldo Xuliz</div>
                      <div className={styles.r108}>R$15,69</div>
                      <div className={styles.r52300}>R$523,00</div>
                      <div className={styles.div22}>?</div>
                      <div className={styles.div23}>?</div>
                      <div className={styles.r2592Parent}>
                        <div className={styles.r432}>R$62,76</div>
                        <img
                          className={styles.vectorIcon22}
                          alt=""
                          src="/vector30.svg"
                        />
                      </div>
                      <div className={styles.fcamor}>FCAMOR</div>
                      <div className={styles.variaoStatus3}>
                        <div className={styles.completado}>Rejeitado</div>
                        <img
                          className={styles.vectorIcon32}
                          alt=""
                          src="/vector32.svg"
                        />
                        <img
                          className={styles.vectorIcon32}
                          alt=""
                          src="/vector32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.groupChild46} />
                <div className={styles.groupChild47} />
                <div className={styles.groupChild48} />
                <div className={styles.groupChild49} />
                <div className={styles.groupChild50} />
                <div className={styles.groupChild51} />
                <div className={styles.groupChild52} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filtrarTransaes}>Filtrar transações</div>
        <div className={styles.crditoWrapper}>
          <div className={styles.dbito}>Crédito</div>
        </div>
        <div className={styles.dbitoWrapper}>
          <div className={styles.dbito}>Débito</div>
        </div>
        <div className={styles.pixWrapper}>
          <div className={styles.dbito}>Pix</div>
        </div>
        <div className={styles.saldoXulizWrapper}>
          <div className={styles.dbito}>Saldo Xuliz</div>
        </div>
        <div className={styles.saldoXulizCrditoWrapper}>
          <div className={styles.dbito}>Saldo Xuliz + Crédito</div>
        </div>
      </div>
      <div className={styles.privacidadeNmeros}>
        <div className={styles.r2}>R$</div>
        <div className={styles.div45}>6.057,84</div>
        <div className={styles.privarNmeros}>
          <div className={styles.privarNmerosInner}>
            <div className={styles.rGroup}>
              <img className={styles.rIcon} alt="" src="/r4.svg" />
              <div className={styles.div46}>267.849,92</div>
            </div>
          </div>
          <img className={styles.vectorIcon34} alt="" src="/vector33.svg" />
        </div>
      </div>
      <div className={styles.telaHomeAreaparceirosChild1} />
      <div className={styles.telaHomeAreaparceirosChild2} />
      <div className={styles.telaHomeAreaparceirosChild3} />
      <div className={styles.telaHomeAreaparceirosChild4} />
      <div className={styles.ticketMdioParent}>
        <div className={styles.ticketMdio}>Ticket médio</div>
        <div className={styles.rContainer}>
          <div className={styles.ticketMdio}>R$</div>
          <div className={styles.div47}>201,54</div>
        </div>
        <div className={styles.groupWrapper13}>
          <div className={styles.parent12}>
            <div className={styles.cashbackDistribudo}>3,5%</div>
            <img className={styles.vectorIcon35} alt="" src="/vector34.svg" />
          </div>
        </div>
      </div>
      <div className={styles.transaoAppParent}>
        <div className={styles.ticketMdio}>Transação App</div>
        <div className={styles.wrapper}>
          <div className={styles.cashbackDistribudo}>24</div>
        </div>
        <div className={styles.groupWrapper14}>
          <div className={styles.parent13}>
            <div className={styles.cashbackDistribudo}>14%</div>
            <img className={styles.vectorIcon36} alt="" src="/vector34.svg" />
          </div>
        </div>
      </div>
      <div className={styles.groupParent19}>
        <div className={styles.maisVendidoParent}>
          <div className={styles.maisVendido}>Mais vendido</div>
          <div className={styles.caneca250mlWrapper}>
            <div className={styles.caneca250ml}>Caneca 250ml</div>
          </div>
        </div>
        <div className={styles.top1ColaboradorParent}>
          <div className={styles.maisVendido}>Top 1 Colaborador</div>
          <div className={styles.caneca250mlWrapper}>
            <div className={styles.caneca250ml}>Valdisnei O.</div>
          </div>
        </div>
      </div>
      <div className={styles.telaHomeAreaparceirosChild5} />
      <div className={styles.vectorParent}>
        <img className={styles.vectorIcon37} alt="" src="/vector2.svg" />
        <div className={styles.configuraes}>Configurações</div>
      </div>
      <div
        className={styles.closeTracedParent}
        onClick={onGroupContainer77Click}
      >
        <img
          className={styles.closeTracedIcon}
          alt=""
          src="/close-traced.svg"
        />
        <div className={styles.configuraes}>Sair</div>
      </div>
      <div className={styles.vectorGroup}>
        <img className={styles.vectorIcon38} alt="" src="/vector3.svg" />
        <div className={styles.configuraes}>Contas</div>
      </div>
      <div className={styles.vectorContainer}>
        <img className={styles.vectorIcon37} alt="" src="/vector4.svg" />
        <div className={styles.configuraes}>Central de ajuda</div>
      </div>
      <div className={styles.ellipseParent}>
        <img className={styles.ellipseIcon} alt="" src="/ellipse-1@2x.png" />
        <div className={styles.feitoComAmor}>FEITO COM AMOR</div>
        <div className={styles.feitocomamorxulizcombr}>
          feitocomamor@xuliz.com.br
        </div>
      </div>
      <img className={styles.lineIcon} alt="" src="/line-13.svg" />
      <div className={styles.lineGroup}>
        <div className={styles.groupChild53} />
        <div
          className={styles.sentTracedParent}
          onClick={onGroupContainer81Click}
        >
          <img
            className={styles.sentTracedIcon}
            alt=""
            src="/sent-traced1.svg"
          />
          <div className={styles.produtos}>Produtos</div>
        </div>
        <div className={styles.vectorParent1}>
          <img className={styles.vectorIcon40} alt="" src="/vector35.svg" />
          <div className={styles.home}>Home</div>
        </div>
        <div
          className={styles.colorPaletteTracedParent}
          onClick={onGroupContainer84Click}
        >
          <img
            className={styles.colorPaletteTraced}
            alt=""
            src="/color-palette-traced1.svg"
          />
          <div className={styles.relatriosWrapper}>
            <div className={styles.relatrios}>Relatórios</div>
          </div>
        </div>
        <div className={styles.vectorParent2} onClick={onGroupContainer85Click}>
          <img className={styles.vectorIcon41} alt="" src="/vector26.svg" />
          <div className={styles.xloja}>XLoja</div>
        </div>
        <div
          className={styles.financeTracedParent}
          onClick={onGroupContainer86Click}
        >
          <img
            className={styles.financeTracedIcon}
            alt=""
            src="/finance-traced1.svg"
          />
          <div className={styles.saque}>Saque</div>
        </div>
      </div>
      <img
        className={styles.logomarcaXulizIcon}
        alt=""
        src="/logomarca-xuliz1@2x.png"
      />
      <img className={styles.parceirosIcon} alt="" src="/parceiros.svg" />
      <img
        className={styles.telaHomeAreaparceirosChild6}
        alt=""
        src="/line-6.svg"
      />
    </div>
  );
};

export default TelaHomeAreaparceiros;
