import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Desktop.module.css";
const Desktop = () => {
  const navigate = useNavigate();

  const onAreaparceirosContainerClick = useCallback(() => {
    navigate("/login-areaparceiros");
  }, [navigate]);

  return (
    <div className={styles.desktop}>
      <div className={styles.section7}>
        <div className={styles.lineParent}>
          <div className={styles.groupChild} />
          <div className={styles.faleComA}>Fale com a gente</div>
          <div className={styles.noLigamosNem}>
            Não ligamos nem mandamos email pra você por esses meios de contato.
            Também não solicitamos dados pessoais ou senha da conta. Estes
            números são apenas para você contatar o Xuliz.
          </div>
          <div className={styles.eMailParaParceriaParceriaWrapper}>
            <div className={styles.eMailParaParceriaContainer}>
              <p className={styles.eMailParaParceria}>E-mail para parceria</p>
              <p className={styles.parceriaxulizcombr}>parceria@xuliz.com.br</p>
            </div>
          </div>
          <div className={styles.entrarEmContatoParent}>
            <div className={styles.eMailParaParceriaContainer}>
              Entrar em contato
            </div>
            <div className={styles.ouMandeUmContainer}>
              <p className={styles.eMailParaParceria}>
                Ou mande um e-mail para
              </p>
              <p className={styles.parceriaxulizcombr}>suporte@xuliz.com.br</p>
            </div>
          </div>
          <div className={styles.precisaDeAjuda}>Precisa de ajuda?</div>
          <div className={styles.polticaDePrivacidadeParent}>
            <div className={styles.eMailParaParceriaContainer}>
              Política de privacidade
            </div>
            <div className={styles.ouMandeUmContainer}>Termos de condição</div>
            <div className={styles.polticaAssinaturaRecorrenteContainer}>
              <p className={styles.eMailParaParceria}>Política assinatura</p>
              <p className={styles.eMailParaParceria}>recorrente</p>
            </div>
          </div>
          <div className={styles.polticas}>Políticas</div>
          <div className={styles.baixeOXuliz}>Baixe o Xuliz</div>
          <div className={styles.sigaOXuliz}>Siga o Xuliz</div>
          <div className={styles.appstoreBrancoPiylz2yjgagdbmParent}>
            <img
              className={styles.appstoreBrancoPiylz2yjgagdbmIcon}
              alt=""
              src="/appstore-brancopiylz2yjgagdbmrbhql2mj9rzkc99lr2wc8imtixxspng@2x.png"
            />
            <img
              className={styles.googleplayBrancoPiylzccxcmt8Icon}
              alt=""
              src="/googleplay-brancopiylzccxcmt8jqdnyuncbgwdxf1xekse9mrdfl507kpng@2x.png"
            />
          </div>
          <div className={styles.aconselhamosQueTodosOsUsuParent}>
            <div className={styles.aconselhamosQueTodos}>
              Aconselhamos que todos os usuários leiam ATENTAMENTE todas as
              Políticas e observem as tarifas cobradas em cada situação.
            </div>
            <img
              className={styles.logoBrancaPiykomro5dg3q4opx6Icon}
              alt=""
              src="/logo-brancapiykomro5dg3q4opx6vjxg7om8iz429ryl37c01lacpng@2x.png"
            />
            <div className={styles.xulizAllRights}>
              @2022 Xuliz. All rights reserved
            </div>
          </div>
          <div className={styles.instagramParent}>
            <div className={styles.instagram}>Instagram</div>
            <img className={styles.vectorIcon} alt="" src="/vector36.svg" />
          </div>
        </div>
      </div>
      <div className={styles.section6}>
        <div className={styles.groupParent}>
          <div className={styles.frameParent}>
            <div className={styles.groupWrapper}>
              <div className={styles.groupContainer}>
                <div className={styles.subtitulo01Parent}>
                  <div className={styles.subtitulo01}>
                    <p
                      className={styles.eMailParaParceria}
                    >{`Acompanhe as novidades que postamos `}</p>
                    <p className={styles.eMailParaParceria}>
                      pelo nosso perfil do Instagram
                    </p>
                  </div>
                  <div className={styles.saibamais}>
                    <div className={styles.sigaONosso}>
                      Siga o nosso Instagram
                    </div>
                  </div>
                  <div className={styles.groupDiv}>
                    <div className={styles.subtitulo01Group}>
                      <div className={styles.subtitulo011}>Instagram</div>
                      <img
                        className={styles.vectorIcon1}
                        alt=""
                        src="/vector37.svg"
                      />
                    </div>
                    <img
                      className={styles.vectorIcon2}
                      alt=""
                      src="/vector38.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.titulo01Parent}>
              <div className={styles.titulo01}>
                <p className={styles.eMailParaParceria}>Fique por dentro das</p>
                <p className={styles.eMailParaParceria}>nossas novidades</p>
              </div>
              <div className={styles.acompanheNossasRedesContainer}>
                <p className={styles.eMailParaParceria}>
                  Acompanhe nossas redes sociais para ficar
                </p>
                <p className={styles.eMailParaParceria}>
                  por dentro do que acontece aqui no Xuliz
                </p>
              </div>
            </div>
          </div>
          <div className={styles.parent}>
            <img className={styles.icon} alt="" src="/11-1@2x.png" />
            <div className={styles.groupFrame}>
              <div className={styles.xulizAppParent}>
                <b className={styles.xulizApp}>Xuliz App</b>
                <b className={styles.pagamentosRpidosEContainer}>
                  <p
                    className={styles.eMailParaParceria}
                  >{`Pagamentos rápidos `}</p>
                  <p className={styles.eMailParaParceria}>e simplificados.</p>
                </b>
                <div className={styles.criaUmaContaContainer}>
                  <span className={styles.criaUmaContaContainer1}>
                    <p
                      className={styles.eMailParaParceria}
                    >{`Cria uma conta sem burocracia agora `}</p>
                    <p
                      className={styles.eMailParaParceria}
                    >{`mesmo e comece a fazer parte da `}</p>
                    <p className={styles.eMailParaParceria}>revolução Xuliz.</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.groupWrapper1}>
            <div className={styles.groupParent1}>
              <div className={styles.groupWrapper2}>
                <div className={styles.xulizAppGroup}>
                  <b className={styles.xulizApp}>Xuliz App</b>
                  <b className={styles.pagamentosRpidosEContainer}>
                    <p className={styles.eMailParaParceria}>
                      Ganhe cashback em
                    </p>
                    <p className={styles.eMailParaParceria}>
                      todos os pagamentos.
                    </p>
                  </b>
                  <div className={styles.emTodosOsContainer}>
                    <span className={styles.criaUmaContaContainer1}>
                      <span>{`Em todos os pagamentos feitos dentro do Xuliz, você ganha `}</span>
                      <span className={styles.cashbackNaHora}>
                        cashback na hora!
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <img
                className={styles.vectorIcon3}
                alt=""
                src="/vector39@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section4}>
        <div className={styles.section4Inner}>
          <div className={styles.imagem02SitePiynep9kd4k8s4mParent}>
            <div className={styles.imagem02SitePiynep9kd4k8s4mParent}>
              <div className={styles.imagem02SitePiynep9kd4k8s4mParent}>
                <img
                  className={styles.imagem02SitePiynep9kd4k8s4mIcon}
                  alt=""
                  src="/imagem-02-sitepiynep9kd4k8s4mn8kll93xjlvq8vunrihx4zzn1uspng@2x.png"
                />
                <div className={styles.issoSerXulizParent}>
                  <b className={styles.issoSer}>Isso é ser Xuliz</b>
                  <div className={styles.subtitulo012}>
                    <p
                      className={styles.eMailParaParceria}
                    >{`Simplificando e agilizando o pagamento `}</p>
                    <p className={styles.eMailParaParceria}>
                      do seu dia a dia.
                    </p>
                  </div>
                  <div className={styles.groupParent2}>
                    <div className={styles.subtitulo01Container}>
                      <div className={styles.subtitulo013}>
                        <p className={styles.eMailParaParceria}>
                          Ganhar cashback em todos os
                        </p>
                        <p className={styles.eMailParaParceria}>
                          seu pagamentos
                        </p>
                      </div>
                      <img
                        className={styles.groupItem}
                        alt=""
                        src="/group-2.svg"
                      />
                    </div>
                    <div className={styles.subtitulo01Parent1}>
                      <div className={styles.subtitulo013}>Conta gratuita</div>
                      <img
                        className={styles.vectorIcon4}
                        alt=""
                        src="/vector40.svg"
                      />
                    </div>
                    <div className={styles.subtitulo01Parent2}>
                      <div className={styles.subtitulo013}>
                        Pagamento rápido e fácil
                      </div>
                      <img
                        className={styles.vectorIcon5}
                        alt=""
                        src="/vector41.svg"
                      />
                    </div>
                    <div className={styles.subtitulo01Parent3}>
                      <div className={styles.subtitulo013}>
                        Pagar o abastecimento sem cartão físico
                      </div>
                      <img
                        className={styles.groupInner}
                        alt=""
                        src="/group-1.svg"
                      />
                    </div>
                    <div className={styles.subtitulo01Parent4}>
                      <div className={styles.subtitulo017}>
                        Pagar clicando somente em um botão
                      </div>
                      <img
                        className={styles.groupIcon}
                        alt=""
                        src="/group.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section3}>
        <div className={styles.groupParent3}>
          <div className={styles.feito3Parent}>
            <div className={styles.feito3}>
              <div className={styles.vocSPrecisaContainer}>
                <p className={styles.eMailParaParceria}>
                  Você só precisa clicar em “Confirmar pagamento” quando o
                </p>
                <p className={styles.eMailParaParceria}>
                  atendimento finalizar e pronto, seu cashback ja caiu na conta.
                </p>
              </div>
              <img className={styles.vectorIcon6} alt="" src="/vector42.svg" />
            </div>
            <div className={styles.feito2}>
              <div className={styles.vocSPrecisaContainer}>
                <p
                  className={styles.eMailParaParceria}
                >{`Vender seus produtos e dar cashback para seus `}</p>
                <p className={styles.eMailParaParceria}>
                  clientes, você terá isso também na palma da sua mão.
                </p>
              </div>
              <img className={styles.vectorIcon7} alt="" src="/vector42.svg" />
            </div>
            <div className={styles.feito1}>
              <div className={styles.simplificarOProcessoEAindaParent}>
                <div className={styles.vocSPrecisaContainer}>
                  <p className={styles.eMailParaParceria}>
                    Simplificar o processo e ainda dar cashback em
                  </p>
                  <p className={styles.eMailParaParceria}>
                    todos os pagamentos é algo que vamos te proporcionar.
                  </p>
                </div>
                <img
                  className={styles.vectorIcon8}
                  alt=""
                  src="/vector42.svg"
                />
              </div>
            </div>
            <b className={styles.titulo011}>
              <p className={styles.eMailParaParceria}>
                Agilizar sua vida, deixando ela
              </p>
              <p className={styles.eMailParaParceria}>
                mais rápida, simples e prática
              </p>
              <p className={styles.eMailParaParceria}>
                é a nossa principal missão.
              </p>
            </b>
          </div>
          <img className={styles.groupChild1} alt="" src="/group-54.svg" />
        </div>
      </div>
      <div className={styles.section2}>
        <div className={styles.nascemosParaMostrarONovoNParent}>
          <b className={styles.nascemosParaMostrarContainer}>
            <p
              className={styles.eMailParaParceria}
            >{`Nascemos para mostrar `}</p>
            <p className={styles.eMailParaParceria}>o novo normal.</p>
          </b>
          <div className={styles.oXulizEstContainer}>
            <p
              className={styles.eMailParaParceria}
            >{`O Xuliz está aqui para te mostrar uma nova forma `}</p>
            <p className={styles.eMailParaParceria}>
              <span>{`de pagamento, `}</span>
              <i
                className={styles.maisRpidaFcil}
              >{`mais rápida, fácil e com cashback. `}</i>
            </p>
            <p className={styles.eMailParaParceria}>
              Para reunir em um app aquilo que faz a diferença.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.section1}>
        <div className={styles.groupParent4}>
          <div className={styles.groupWrapper5}>
            <div className={styles.infoParent}>
              <div className={styles.info}>
                *Mais de 3000 pessoas do ES já baixaram o App e estão ganhando
                Cashback
              </div>
              <div className={styles.saibamais1}>
                <div className={styles.sigaONosso}>
                  Saiba mais sobre o nosso app
                </div>
              </div>
              <div className={styles.subtitulo018}>
                <p
                  className={styles.eMailParaParceria}
                >{`Uma forma totalmente nova de pagar `}</p>
                <p
                  className={styles.eMailParaParceria}
                >{`seu abastecimento e fazer compras em `}</p>
                <p className={styles.eMailParaParceria}>lojas parceiras.</p>
              </div>
              <b className={styles.titulo012}>
                <p className={styles.eMailParaParceria}>Revolução Xuliz.</p>
                <p className={styles.eMailParaParceria}>Venha fazer</p>
                <p className={styles.eMailParaParceria}>parte também</p>
              </b>
            </div>
          </div>
          <img className={styles.site1Icon} alt="" src="/site-1@2x.png" />
        </div>
      </div>
      <div className={styles.cabealho}>
        <div className={styles.parceirosParent}>
          <div className={styles.parceiros}>Parceiros</div>
          <div className={styles.ajuda}>Ajuda</div>
          <div className={styles.oXuliz}>O Xuliz</div>
        </div>
        <img
          className={styles.logomarcaXulizIcon}
          alt=""
          src="/logomarca-xuliz2@2x.png"
        />
        <div className={styles.areaparceirosParent}>
          <div
            className={styles.areaparceiros}
            onClick={onAreaparceirosContainerClick}
          >
            <div className={styles.sigaONosso}>Área parceiros</div>
          </div>
          <div className={styles.baixeapp}>
            <div className={styles.sigaONosso}>Baixe o app</div>
            <img className={styles.vectorIcon9} alt="" src="/vector43.svg" />
          </div>
        </div>
        <div className={styles.cabealhoChild} />
      </div>
    </div>
  );
};

export default Desktop;
