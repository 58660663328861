import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";

import { useEffect } from "react";
import xloja_12 from './assets/xloja_12.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg'; 

import xloja from './assets/xloja.png'; // Tell webpack this JS file uses this image


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faShoppingBag,faChartPie,faBox,faCircleDollarToSlot,faBullhorn,faQuestionCircle,faUsers,faGear,faSquareXmark,faMobile,faBars,faTrash,faAngleLeft,faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import axios from "axios";
import logo_parceiros from './assets/grupo677.png'; // Tell webpack this JS file uses this image
import grupo12 from './assets/grupo12.png'; 
import retangulo473 from './assets/retangulo473.png'; 

import more_falt1 from './assets/more_falt1.png'; 
import more_falt2 from './assets/more_falt2.png'; 


var vendaPaga = false;
   
const CadastrarSubmembrosJS = () => {
  const navigate = useNavigate();

  const [emailRef, setEmailRef] = useState('');
  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [cashbackCliente, setCaschbackCliente] = useState('R$ 0,00');
  const [dadosClinte, setDadosCliente] = useState([]);
  const [dadosClinteSubmembros, setSubmembros2] = useState([]);

  const [options,setOptions] = useState([])
  const [options2,setOptions2] = useState([])

 
  const [dadosX, setDadosX] = useState([]);
  const [primeiroNome, setPrimeiroNome] = useState('');
  const [nomeUser, setNome] = useState('');

  const [clickEnviou, setClickEnviou] = useState(false);
  const [tokenEsta, setTokenEstabelecimento] = useState('');
  const [idvenda, setIdvenda] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [urlLogo, setUrlLogo] = useState('');
  const [cadastroExiste, setCadastroExiste] = useState(false);
  const [submembroTipo, setSubmembroTipo] = useState(false);
  const [idSubmembro, setIdsubmembro] = useState('');


  const [submembros, setSubmembros] = useState([]);
//{item:'item',id:1,nome:'Lucas',celular:'62 98604-236'},{item:'item',id:1,nome:'Lucas',celular:'62 98604-236'}
 

  
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);


  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
  };

 
  

  useEffect(() => {
     
     
    getDados()

    pegarTipos()
    pegarTiposSubmembros()

  }, []);



  const pegarTipos = async()=>{
     

    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/listartipo.php',
      headers: {'Content-Type': 'application/json'},
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'listartipo',
        tipo: 'MEMBRO',
      },
    };
    
    await axios.request(options).then(function (response) {
      console.log('listarTipo',response.data);
      var dados = response.data;

      var retorno = dados.retorno;


      var arra = [{
        id:0,
        iddependentetipo:'',
        dependentetipo:'',
      }]
      

      for(var x=0;x<retorno.length;x++){
        arra.push({
          id:retorno[x].iddependentetipo,
          iddependentetipo:retorno[x].iddependentetipo,
          dependentetipo:retorno[x].dependentetipo,
        })
      }

      
      setOptions(arra)

      //   var qual_relação = document.getElementById("qual_relação");
      //   var novoComponente = document.createElement("options");
      //   novoComponente.value = retorno[x].iddependentetipo;
      //   novoComponente.textContent = retorno[x].dependentetipo;

      //   qual_relação.appendChild(novoComponente);
      // }
            
    
  
    
    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
  }
  
  const pegarTiposSubmembros = async()=>{
     

    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/listartipo.php',
      headers: {'Content-Type': 'application/json'},
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'listartipo',
        tipo: 'SUBMEMBRO',
      },
    };
    
    await axios.request(options).then(function (response) {
       var dados = response.data;

      var retorno = dados.retorno;


      var arra = [{
        id:0,
        iddependentetipo:'',
        dependentetipo:'',
      }]
      

      for(var x=0;x<retorno.length;x++){
        arra.push({
          id:retorno[x].iddependentetipo,
          iddependentetipo:retorno[x].iddependentetipo,
          dependentetipo:retorno[x].dependentetipo,
        })
      }

      
      setOptions2(arra)

      //   var qual_relação = document.getElementById("qual_relação");
      //   var novoComponente = document.createElement("options");
      //   novoComponente.value = retorno[x].iddependentetipo;
      //   novoComponente.textContent = retorno[x].dependentetipo;

      //   qual_relação.appendChild(novoComponente);
      // }
            
    
  
    
    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
  }
  
  

  const Swal = require('sweetalert2')

  const verificarSubmembros = async(dados)=>{
      function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }
        
    
    var sub = getParameterByName('sub'); 
    var ids = getParameterByName('id'); 

    ///COLOQUE
    // if(sub == null || sub != 's'){
    //   return false;
    // }

     if(sub == null || sub != 's'){
      return false;
    }
    //COLOQUE

    setSubmembroTipo(true);
    setIdsubmembro(ids)
    // document.getElementById('id_filho').style.display = 'block'
    // document.getElementById('id_conjuge').style.display = 'block'
    // document.getElementById('id_titular').style.display = 'none';
    document.getElementById('id_cadastro').innerText = 'Cadastro de submembro+'
    document.getElementById('submembros_id').style.display = 'flex';

    buscarMembro(dados,ids)


  }

  const buscarMembro = async(dados,id)=>{


    var token_estabelecimento = dados.tokenestabelecimento;
    var idestabelecimento = dados.idestabelecimento;
    var idparceiro = dados.parceiros;

    if(idparceiro == null){
      return false;
    }

    if(idparceiro.length == 0){
      return false;
    }

    if(idparceiro[0] == null){
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

 
    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/membrosListar.php',
      headers: {'Content-Type': 'application/json'},
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'membrosListar',
        token_estabelecimento: token_estabelecimento,
        idestabelecimento: idestabelecimento,
        idparceiro: idparceiro
      },
    };
    
    await axios.request(options).then(function (response) {
      console.log('membrosListar',response.data);
      var dados = response.data;
           if(dados.erro != "N"){
              return false;
            }

            var membros = dados.retorno;

            if(membros == null || membros.length == 0){
              
              location.href = './membros'
              return false;
            }

           //Vamos listar dados

           listarMembro(dados,id,membros)
          



    
           
    
    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
    
  }

  const listarMembro = async(dados,id,membros)=>{

     

    for(var x=0;x<membros.length;x++){


          
      if(membros[x].idusuario == id){
        document.getElementById('chave_celular').value = membros[x].celular
        document.getElementById('chave_celular').setAttribute('readonly',true)

        document.getElementById('qual_relação').value = membros[x].iddependentetipo

        document.getElementById('qual_relação').setAttribute('readonly',true)

        const selectElement = document.getElementById('qual_relação');

        // Impede a mudança do valor selecionado do select
        selectElement.addEventListener('change', (event) => {
          event.preventDefault();
          selectElement.value = membros[x].iddependentetipo; // Mantém o valor atual selecionado
        });
        document.getElementById('nome_cliente').value = membros[x].nome

        chaveCelular()
        setSubmembros(membros[x].dependentes)
        
        
        break
      }
   }
  }
 
  const pegarMembros = async(dados)=>{

    var token_estabelecimento = dados.tokenestabelecimento;
    var idestabelecimento = dados.idestabelecimento;
    var idparceiro = dados.parceiros;

    if(idparceiro == null){
      return false;
    }

    if(idparceiro.length == 0){
      return false;
    }

    if(idparceiro[0] == null){
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

    const options = {
      method: 'POST',
      url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/membrosListar.php',
      headers: {'Content-Type': 'application/json'},
      params: {
        token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
        funcao: 'membrosListar',
        token_estabelecimento: token_estabelecimento,
        idestabelecimento: idestabelecimento,
        idparceiro: idparceiro
      },
    };
    
    await axios.request(options).then(function (response) {
      console.log('membrosListar',response.data);
      var dados = response.data;
           if(dados.erro != "N"){
              return false;
            }

            var membros = dados.retorno;

            if(membros != null && membros.length > 0){
            //   document.getElementById('membros_id').innerText = ""+membros.length;
            }

            calcularSubmembros(membros)
    
           
    
    }).catch(function (error) {
      console.error(error);
      alert("Encontramos um problema tente mais tarde")
    });
    
  }

  const calcularSubmembros = async(membros)=>{
    var dependentesTOTAL = 0;

     

    for(var x=0;x<membros.length;x++){

        if(membros[x].dependentes && membros[x].dependentes.length  && membros[x].dependentes.length > 0){
            var total = membros[x].dependentes.length;
            dependentesTOTAL = dependentesTOTAL+total;
        }

       
    }

 
   
 
 
  }

  const getDados = async()=>{
    var dados = await localStorage.getItem('login')

    if(dados == null){
      navigate("/");

      return false;
    }
    dados = JSON.parse(dados)
     setDadosX(dados)

     setUrlLogo(dados.urllogo)

     console.log('dadosX',dados)

    setEmailRef(dados.email)
     console.log(dados)
 
 pegarMembros(dados)
 verificarSubmembros(dados)

     
  
     
  }

  const adicionarSubmembro = async()=>{

    // salvarMembro

    var chave = document.getElementById('numero_celular2').value;
    if(chave.length != 11){
        Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title:  'Número de celular inválido!',
            showConfirmButton: false,
            timer: 1000
          })
    
          return false;
    }


    var iddependentetipo = document.getElementById('qual_relação2').value;

    if(iddependentetipo  ==  '-'){
        Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title:  'Selecione uma relação com o membro',
            showConfirmButton: false,
            timer: 1000
          })
    
          return false;
    }


    ///ANTES
     
    var idestabelecimento = dadosX.idestabelecimento
    var token_estabelecimento = dadosX.tokenestabelecimento
 
    var idparceiro = dadosX.parceiros;

    if(idparceiro == null){
      return false;
    }

    if(idparceiro.length == 0){
      return false;
    }

    if(idparceiro[0] == null){
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

     var nome = document.getElementById('nome_cliente2').value;
    var celular = document.getElementById('numero_celular2').value;

    console.log('asdsad',dadosClinteSubmembros)
    var idusuario = dadosClinteSubmembros.idusuario;
    var idusuariotitular = dadosClinte.idusuario;
    var idusuarioregistro = dadosX.idusuario;

    var urlPost = 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembro.php'
    
    var cadastrarMembro = 'cadastrarMembro';
    
    if(submembroTipo == true){
      idusuariotitular = idSubmembro
    }

  
    if(cadastroExiste == false){

      if(nome == ''){
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title:  'Escreva o nome',
          showConfirmButton: false,
          timer: 1000
        })
  
        return false;
      }

      cadastrarMembro = 'cadastrarMembroCelular'
      urlPost = 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembroCelular.php'
    }

 
    const options = {
        method: 'POST',
           url: urlPost,
        headers: {'Content-Type': 'application/json'},
        params: {
          token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
          funcao: cadastrarMembro,
          token_estabelecimento: token_estabelecimento,
          idestabelecimento: idestabelecimento,
          idparceiro: idparceiro,
          idusuario:idusuario,
          celular:celular,
          nome:nome,
          idusuariotitular:idusuariotitular,
          iddependentetipo:iddependentetipo,
          idusuarioregistro:idusuarioregistro
        }
      };

       
      
      await axios.request(options).then(function (response) {
        console.log('novoCadastro',response.data);
        var dados = response.data;
        
        if(dados.erro != "N"){
            alert(dados.mensagem)
            return false;
        }

        Swal.fire(
            'Sucesso!',
            ''+dados.mensagem,
            'success'
          ).then(()=>{
            location.reload()
          })

       
             
      
      }).catch(function (error) {
        console.error(error);
        alert("Encontramos um problema tente mais tarde")
      });

    //ANTES
  

    // var idestabelecimento = dadosX.idestabelecimento
    // var token_estabelecimento = dadosX.tokenestabelecimento
 
    // var idparceiro = dadosX.parceiros;

    // if(idparceiro == null){
    //   return false;
    // }

    // if(idparceiro.length == 0){
    //   return false;
    // }

    // if(idparceiro[0] == null){
    //   return false;
    // }

    // idparceiro = idparceiro[0].idparceiro

    // var idusuario = dadosClinteSubmembros.idusuario;
    // var idusuariotitular = dadosClinte.idusuario;
    // var idusuarioregistro = dadosX.idusuario;
    

    
    // const options = {
    //     method: 'POST',
    //     url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembro.php',
    //     headers: {'Content-Type': 'application/json'},
    //     params: {
    //       token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
    //       funcao: 'cadastrarMembro',
    //       token_estabelecimento: token_estabelecimento,
    //       idestabelecimento: idestabelecimento,
    //       idparceiro: idparceiro,
    //       idusuario:idusuario,
    //       idusuariotitular:idusuariotitular,
    //       iddependentetipo:iddependentetipo,
    //       idusuarioregistro:idusuarioregistro,

    //     },
    //   };
      
    //   await axios.request(options).then(function (response) {
    //     console.log('novoCadastro',response.data);
    //     var dados = response.data;
        
    //     if(dados.erro != "N"){
    //         alert(dados.mensagem)
    //         return false;
    //     }

    //     Swal.fire(
    //         'Sucesso!',
    //         ''+dados.mensagem,
    //         'success'
    //       ).then(()=>{
    //         location.reload()
    //       })

      
             
      
    //   }).catch(function (error) {
    //     console.error(error);
    //     alert("Encontramos um problema tente mais tarde")
    //   });



  }
     

  const salvarMembro = async()=>{

    var chave = document.getElementById('chave_celular').value;
    if(chave.length != 11){
        Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title:  'Número de celular inválido!',
            showConfirmButton: false,
            timer: 1000
          })
    
          return false;
    }


    var iddependentetipo = document.getElementById('qual_relação').value;

    if(iddependentetipo  ==  '-'){
        Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title:  'Selecione uma relação com a empresa',
            showConfirmButton: false,
            timer: 1000
          })
    
          return false;
    }


    //vamos conversar com a API


    var idestabelecimento = dadosX.idestabelecimento
    var token_estabelecimento = dadosX.tokenestabelecimento
 
    var idparceiro = dadosX.parceiros;

    if(idparceiro == null){
      return false;
    }

    if(idparceiro.length == 0){
      return false;
    }

    if(idparceiro[0] == null){
      return false;
    }

    idparceiro = idparceiro[0].idparceiro

    var idusuario = dadosClinte.idusuario;
    var idusuariotitular = '';
    var idusuarioregistro = dadosX.idusuario;
    var nome = document.getElementById('nome_cliente').value;
    var celular = document.getElementById('chave_celular').value;

    var urlPost = 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembro.php'
    
    var cadastrarMembro = 'cadastrarMembro';
    
    if(submembroTipo == true){
      idusuariotitular = idSubmembro
    }

 
    if(cadastroExiste == false){

      if(nome == ''){
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title:  'Escreva o nome',
          showConfirmButton: false,
          timer: 1000
        })
  
        return false;
      }

      cadastrarMembro = 'cadastrarMembroCelular'
      urlPost = 'https://us-central1-restapi-4777e.cloudfunctions.net/app/cadastrarMembroCelular.php'
    }

    
    const options = {
        method: 'POST',
        url: urlPost,
        headers: {'Content-Type': 'application/json'},
        params: {
          token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
          funcao: cadastrarMembro,
          token_estabelecimento: token_estabelecimento,
          idestabelecimento: idestabelecimento,
          idparceiro: idparceiro,
          idusuario:idusuario,
          idusuariotitular:idusuariotitular,
          iddependentetipo:iddependentetipo,
          idusuarioregistro:idusuarioregistro,
          nome:nome,
          celular:celular

        },
      };

       
      
      await axios.request(options).then(function (response) {
        console.log('novoCadastro',response.data);
        var dados = response.data;
        
        if(dados.erro != "N"){
            alert(dados.mensagem)
            return false;
        }

        Swal.fire(
            'Sucesso!',
            ''+dados.mensagem,
            'success'
          ).then(()=>{
            location.reload()
          })

       
             
      
      }).catch(function (error) {
        console.error(error);
        alert("Encontramos um problema tente mais tarde")
      });


  }


  const chaveCelular2 = async ()=>{

    
    var chave = document.getElementById('numero_celular2').value;
    if(chave.length != 11){
        document.getElementById("nome_cliente2").value = '';
   

         document.getElementById('imagem_user2').src = more_falt1;
        document.getElementById('titulo_msg3').innerText = 'Xuliz & CDL';
        document.getElementById('titulo_msg4').innerText = 'Digite o número de celular para cadastrar um novo Membro+';
        document.getElementById('card_info4').style.display = 'none';
        document.getElementById('card_info5').style.display = 'flex';
        document.getElementById('nome_3').innerText = '';

        // document.getElementById('imagem_user2').src = urlFoto;
        // document.getElementById('card_info4').style.display = 'flex';
        // document.getElementById('card_info5').style.display = 'none';
        
        // document.getElementById('nome_3').innerText = dados.nome
        
        return false;
      }


      var qual_chave = "celular";
     
      const form = new FormData();
      form.append("metodo", "ConsultaUsuarioChave");
      form.append("idempresa", "1350");
      form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
      form.append("chavecliente", ""+chave);
      form.append("chavetipo", qual_chave);
      
      const options = {
        method: 'POST',
      };
      
      options.body = form;
      
      fetch('https://xuliz.com.br/painel/integracao/usuario.php', options)
        .then(response => response.json())
        .then(response => setarDados2(response))
        .catch(err => console.error(err));
  
  }


  const chaveCelular = async()=>{

    var chave = document.getElementById('chave_celular').value;
    chave = chave.replace(/[^0-9]/g, "");
 
     if(chave.length != 11){
      document.getElementById("nome_cliente").value = '';
      document.getElementById('imagem_user').src = more_falt1;
      document.getElementById('titulo_msg').innerText = 'Xuliz & CDL';
      document.getElementById('titulo_msg2').innerText = 'Digite o número de celular para cadastrar um novo Membro+';
      document.getElementById('card_info').style.display = 'none';
      document.getElementById('card_info2').style.display = 'flex';
      document.getElementById('submembros_id').style.display = 'none';
      document.getElementById('nome_2').innerText = '';
       
      return false;
    }

    var qual_chave = "celular";
     
    const form = new FormData();
    form.append("metodo", "ConsultaUsuarioChave");
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("chavecliente", ""+chave);
    form.append("chavetipo", qual_chave);
    
    const options = {
      method: 'POST',
    };
    
    options.body = form;
    
    fetch('https://xuliz.com.br/painel/integracao/usuario.php', options)
      .then(response => response.json())
      .then(response => setarDados(response))
      .catch(err => console.error(err));
  }

  const setarDados2 = async(dados)=>{
    console.log(dados)
     setSubmembros2(dados)
     setCadastroExiste(false)
 
    if(dados.erro != "N"){

      document.getElementById('imagem_user2').src = more_falt2;
      document.getElementById('titulo_msg3').innerText = 'Usuário não existente';
      document.getElementById('titulo_msg4').innerText = 'Mas não se preocupe, enviaremos um SMS para ele criar uma conta Xuliz';

      return false;
  
  }

   
  

  setCadastroExiste(true)

    setDadosCliente(dados);

    console.log('dadosClinte',dadosClinte)


    var urlFoto = dados.urlfotoperfil;

    if(urlFoto == '' || urlFoto == null){
      urlFoto = 'https://cdn-icons-png.flaticon.com/512/149/149071.png';
    }


    document.getElementById('imagem_user2').src = urlFoto;
    document.getElementById('card_info4').style.display = 'flex';
    document.getElementById('card_info5').style.display = 'none';
    
    document.getElementById('nome_3').innerText = dados.nome
    
     
    



    document.getElementById('nome_cliente2').value = dados.nome;

     
  }

  

  const setarDados = async(dados)=>{
    console.log(dados)

    setCadastroExiste(false)

    if(dados.erro != "N"){

        document.getElementById('imagem_user').src = more_falt2;
        document.getElementById('titulo_msg').innerText = 'Usuário não existente';
        document.getElementById('titulo_msg2').innerText = 'Mas não se preocupe, enviaremos um SMS para ele criar uma conta Xuliz';

        return false;
    
    }

    setCadastroExiste(true)

    setDadosCliente(dados);

    console.log('dadosClinte',dadosClinte)


    var urlFoto = dados.urlfotoperfil;

    if(urlFoto == '' || urlFoto == null){
      urlFoto = 'https://cdn-icons-png.flaticon.com/512/149/149071.png';
    }


    document.getElementById('imagem_user').src = urlFoto;
    document.getElementById('card_info').style.display = 'flex';
    document.getElementById('card_info2').style.display = 'none';
    
    document.getElementById('nome_cliente').value = dados.nome
    document.getElementById('nome_2').innerText = dados.nome
    
    


  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }





  if(isMobile == true){

    return (
      <>
        <MenuMobile page="marketing"/>
        <div style={{height:'100vh',display:"flex",width:"100%",overflow:'auto'}}>
          <div style={{display:"flex",width:"100%",margin:40,flexDirection:'column',height:420}}>

            <p style={{fontSize:15,fontFamily: 'Montserrat',marginLeft:20,marginTop:30,cursor:"pointer"}} onClick={()=>{

          if(submembroTipo == true){
            location.href = './membros'
            return false
          }
          location.href = './membros'
            }}><FontAwesomeIcon icon={faArrowLeft} style={{fontSize:15,color:"#262626",marginRight:3}} />Voltar</p>

            <p style={{fontSize:16,fontWeight:'bold',fontFamily: 'Montserrat',marginLeft:20,marginTop:2}} id="id_cadastro">Cadastro de Membro+</p>
            
            <div style={{display:'flex',flexDirection:'column'}}>
  

                    <div style={{display:'flex',flexDirection:'column',flex:1}}>



                    <div style={{display:'flex',flexDirection:'column',flex:0.5,padding:10}}>
                        <div style={{display:'flex',flex:1,borderRadius:10}}>
                            

                            <div style={{flex:1,borderTopLeftRadius:10,borderBottomLeftRadius:10,textAlign:'center',alignSelf:'center'}}>
                                <img src={more_falt1} style={{width:150,height:150,borderRadius:40}} id="imagem_user"/>
                            </div>

                            <div id="card_info" style={{flex:1.2,backgroundColor:'white',borderTopRightRadius:10,borderBottomRightRadius:10,flexDirection:'column',justifyContent:'center',display:'none',paddingLeft:10}}>
                                <span style={{fontSize:14}}>Nome</span>
                                <p style={{fontWeight:'bold',fontSize:14}} id='nome_2'>-</p>

                                <p style={{fontSize:14, marginBottom:1}}>Situação</p>
                                <p style={{fontWeight:'bold',fontSize:14,color:'green'}} id='situacao_2'>OK</p>
                            </div>

                            <div  id="card_info2" style={{flex:1.2,backgroundColor:'white',borderTopRightRadius:10,borderBottomRightRadius:10,flexDirection:'column',justifyContent:'center',display:'flex',paddingLeft:10}}>
                                <span style={{fontSize:14,fontWeight:'bold'}} id="titulo_msg">Xuliz & CDL</span>
                                <p style={{fontSize:12,marginTop:5}} id="titulo_msg2">Digite o número de celular para cadastrar um novo Membro+</p>

                             
                            </div>


                        </div>
                    </div>

                
                            <div style={{flexDirection:'row',display:'flex'}}>
                                                <div class="mb-3" style={{marginLeft:10,marginTop:20,flex:1}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Número de celular:</label>
                                                    <input type="email" class="form-control" id="chave_celular" aria-describedby="emailHelp"   onChange={()=>chaveCelular()} maxLength={11}/>
                                                </div>

                                                <div class="mb-3" style={{marginTop:20,marginLeft:10,flex:1}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Relação com empresa</label>
                                                    
                                                    <select class="form-control" id="qual_relação">


                                                        {options.map((option, index) => (
                                                        <option key={index.iddependentetipo} value={option.iddependentetipo}>
                                                          {option.dependentetipo}
                                                        </option>
                                                      ))}
                                                            {/* <option value="-">-</option>
                                                            <option value="1" id="id_titular">Titular</option>
                                                            <option value="4" >Funcionário</option>

                                                            <option value="2"  id="id_conjuge" style={{display:'none'}}>Cônjuge</option>
                                                            <option value="3"  id="id_filho"  style={{display:'none'}}>Filho</option> */}


                                                    </select>
                                                </div>

                                                
                            </div>

                            <div style={{flexDirection:'row',display:'flex'}}>
                                                

                                                <div class="mb-3" style={{marginLeft:10,flex:3}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Nome:</label>
                                                    <input type="email" class="form-control" id="nome_cliente" aria-describedby="emailHelp"  />
                                                </div>

                                               
                                </div>

                    </div>


                  
                    
             </div>

            {submembroTipo == false&&( <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{width:120,height:40,backgroundColor:'#e95453',marginLeft:10,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={()=>{
                  
                  if(submembroTipo == true){
                    location.href = './membros'
                    return false
                  }
                  location.href = './marketing'
                 }}
                  
                  >
                    <span style={{color:'#f2e1c4',fontSize:14}}>Cancelar</span>
                </div>

                <div style={{width:150,height:40,backgroundColor:'#262626',marginLeft:10,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={()=>{salvarMembro()}}>
                    <span style={{color:'#f2e1c4',fontSize:14}}>Adicionar Membro+</span>
                </div>
            </div>
            )}
           
            
           

            <div style={{display:'none',flex:1,marginTop:50,flexDirection:'column'}}  id="submembros_id">
                
                <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                        <p style={{fontSize:16,fontWeight:'bold',fontFamily: 'Montserrat',marginLeft:20,marginTop:3}}>Adicionar Submembros+</p>
                        <p style={{fontSize:12,fontFamily: 'Montserrat',marginLeft:20,color:'#585858'}}>Área destinada ao cadastro de submembros+ e do  membro+ acima.</p>

                          <div style={{display:'flex',flexDirection:'column',flex:0.5,padding:10}}>
                                  <div style={{display:'flex',flex:1,borderRadius:10}}>
                                      

                                      <div style={{flex:1,borderTopLeftRadius:10,borderBottomLeftRadius:10,textAlign:'center',alignSelf:'center'}}>
                                          <img src={more_falt1} style={{width:70,height:70,borderRadius:10}} id="imagem_user2"/>
                                      </div>

                                      <div id="card_info4" style={{flex:1.2,backgroundColor:'white',borderTopRightRadius:10,borderBottomRightRadius:10,flexDirection:'column',justifyContent:'center',display:'none',paddingLeft:10}}>
                                          <span style={{fontSize:14}}>Nome</span>
                                          <p style={{fontWeight:'bold',fontSize:14}} id='nome_3'>-</p>

                                          <p style={{fontSize:14, marginBottom:1}}>Situação</p>
                                          <p style={{fontWeight:'bold',fontSize:14,color:'green'}} id='situacao_3'>OK</p>
                                      </div>

                                      <div  id="card_info5" style={{flex:3,backgroundColor:'white',borderTopRightRadius:10,borderBottomRightRadius:10,flexDirection:'column',justifyContent:'center',display:'flex',paddingLeft:10}}>
                                          <span style={{fontSize:14,fontWeight:'bold'}} id="titulo_msg3">Xuliz & CDL</span>
                                          <p style={{fontSize:12,marginTop:5}} id="titulo_msg4">Digite o número de celular para cadastrar um novo Membro+</p>

                                      
                                      </div>


                                  </div>
                      </div>



                        <div class="mb-3" style={{marginLeft:10,marginTop:1,flex:1,marginRight:20,marginLeft:20}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Número de celular:</label>
                                                    <input type="email" class="form-control" id="numero_celular2" aria-describedby="emailHelp"   onChange={()=>chaveCelular2()}/>
                        </div>

                        <div class="mb-3" style={{marginLeft:10,marginTop:1,flex:1,marginRight:20,marginLeft:20}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Nome:</label>
                                                    <input type="email" class="form-control" id="nome_cliente2" aria-describedby="emailHelp"  />
                        </div>

                        <div class="mb-3" style={{marginTop:10,marginLeft:10,flex:1,marginRight:20,marginLeft:20}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Relação com membro</label>
                                                    
                                                    <select class="form-control" id="qual_relação2">
                                                    {options2.map((option, index) => (
                                                    <option key={index.iddependentetipo} value={option.iddependentetipo}>
                                                      {option.dependentetipo}
                                                    </option>
                                                  ))}

                                                    </select>
                            </div>

                            <div style={{display:'flex',flexDirection:'column'}}>
               



                   



                <div style={{width:200,height:40,backgroundColor:'#262626',marginLeft:10,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer',marginTop:5}} onClick={()=>{adicionarSubmembro()}}>
                    <span style={{color:'#f2e1c4',fontSize:14}}>Adicionar Submembro+</span>
                </div>

                <div style={{width:30,height:30,backgroundColor:'white',marginLeft:10,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer',marginTop:50}}>
                 </div>
            </div>

                        

                       
                </div>


               {submembros.length!=0&&( <div style={{display:'flex',flex:1.4,flexDirection:'column'}}>
                    <p style={{fontSize:16,fontWeight:'bold',fontFamily: 'Montserrat',marginLeft:20,marginTop:20}}>Submembros+ cadastrados</p>
                    <p style={{fontSize:12,fontFamily: 'Montserrat',marginLeft:20,color:'#585858'}}>Abaixo estão todos os submembros+ que foram cadastrados</p>
                    

                    <div style={{backgroundColor:'#464646',borderRadius:10,padding:10,height:250,overflow: 'auto' }}>
                    
                        {submembros.map((item, index) => {
                          var idRef = item.idempresaparceirousuario;
                          idRef = idRef.match(/\d+/g);

                          var idRef2 = item.idempresaparceirousuario;


                           var imagemRef = item.urlfotoperfil;

                           if(imagemRef == '' || imagemRef == ''){
                              imagemRef = 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
                            }



                            

                            const excluirMembro = async (idempresaparceirousuario,situacao)=>{

                              var url = "https://us-central1-restapi-4777e.cloudfunctions.net/app/excluirMembro.php";
                              var fun = 'excluirMembro';
                              if(situacao && situacao == "PENDENTE"){
                                url = "https://us-central1-restapi-4777e.cloudfunctions.net/app/excluirMembroCelular.php";
                                fun = 'excluirMembroCelular';
                              }
    
                              var get_idempresaparceirousuario = idempresaparceirousuario;
                              get_idempresaparceirousuario = ''+get_idempresaparceirousuario.match(/\d+/g);
                              
                              var idestabelecimento = dadosX.idestabelecimento
                              var token_estabelecimento = dadosX.tokenestabelecimento
    
                              
                              const options = {
                                method: 'POST',
                                url: url,
                                headers: {'Content-Type': 'application/json'},
                                params: {
                                  token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
                                  funcao: fun,
                                  token_estabelecimento: token_estabelecimento,
                                  idestabelecimento: idestabelecimento,
                                  idlinha:get_idempresaparceirousuario
                                  
                                },
                              };
                        
                               
                              
                              await axios.request(options).then(function (response) {
                                console.log('novoCadastro',response.data);
                                var dados = response.data;
                                
                                if(dados.erro != "N"){
                                    alert(dados.mensagem)
                                    return false;
                                }
                        
                                Swal.fire(
                                    'Sucesso!',
                                    ''+dados.mensagem,
                                    'success'
                                  ).then(()=>{
                                    location.reload()
                                  })
                       
                                     
                              
                              }).catch(function (error) {
                                console.error(error);
                                alert("Encontramos um problema tente mais tarde")
                              });
                        
                            }

                          return(
                            <div style={{width:'100%',height:30,marginBottom:10,flex:1,display:'flex'}}>
                                
                                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                                    <div style={{backgroundColor:'#f2e1c4',width:20,height:20,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center'}}>
                                        <span style={{textAlignLast:'center',fontSize:12}}>{index+1}</span>
                                    </div>
                                </div>

                                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                                    {/* <div style={{width:30,height:30,borderRadius:15,display:'flex',alignItems:'center',justifyContent:'center',borderWidth:1,borderColor:'#f2e1c4',borderStyle:'solid'}}>
                                    </div> */}

                                    <img style={{height:30,width:30,borderRadius:15,display:'flex',alignItems:'center',justifyContent:'center',borderWidth:1,borderColor:'#f2e1c4',borderStyle:'solid'}} src={imagemRef}>
                                                    
                                        </img>
                                                    
                                                
                                </div>

                                <div style={{display:'flex',flex:0.8,flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:9,color:'#8f8f8f'}}>ID</span>
                                    <p style={{fontSize:10,marginBottom:0,color:'white'}}>{idRef}</p>
                                </div>

                                <div style={{display:'flex',flex:3,flexDirection:'column',justifyContent:'center'}}>
                                    <span style={{fontSize:9,color:'#8f8f8f'}}>Nome</span>
                                    <p style={{fontSize:10,marginBottom:0,color:'white'}}>{item.nome}</p>
                                </div>

                                <div style={{display:'flex',flex:2,flexDirection:'column'}}>
                                    <span style={{fontSize:9,color:'#8f8f8f'}}>Nº Celular</span>
                                    <p style={{fontSize:10,marginBottom:0,color:'white'}}>{item.celular}</p>
                                </div>

                                <div style={{display:'flex',flex:2,flexDirection:'column'}}>
                                    <span style={{fontSize:9,color:'#8f8f8f'}}>Situação</span>
                                    <p style={{fontSize:10,marginBottom:0,color:'white'}}>{item.situacao}</p>
                                </div>

                                <div style={{display:'flex',flex:1,alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={()=>{excluirMembro(item.idempresaparceirousuario,item.situacao) }}>
                                        <FontAwesomeIcon icon={faTrash} style={{fontSize:12,color:'#f1e2c4'}} />     
                                </div>
                            </div>
                        )})}

                    </div>
                     <p style={{fontSize:12,fontFamily: 'Montserrat',marginLeft:20,color:'#585858',fontWeight:'bold',marginBottom:3,paddingTop:50}}>*Usuários não existentes</p>
                        <p style={{fontSize:11,fontFamily: 'Montserrat',marginLeft:20,color:'#585858'}}>Todos os usuários que estiverem com a situação como "Não existente" enviaremos um SMS informando sobre um benéficio exclusivo e incluindo um link para download do aplicativo Xuliz</p>



                </div>
               )}


            </div>

            <div style={{display:'flex',height:1,marginTop:10,borderWidth:0.6,borderColor:'#262626',borderStyle:'solid'}}>
            </div>
            

          </div> 
      </div> 

        </>
    )
  }
 
     
  return (
    <>

    <div style={{height:'100vh',display:"flex"}}>
      <div style={{backgroundColor:'#262626'}}>
          <Menu page="marketing"/>
      </div>

      <div style={{height:'100vh',display:"flex",width:"100%",overflow:'auto'}}>
          <div style={{display:"flex",width:"100%",margin:40,flexDirection:'column',height:420}}>

            <p style={{fontSize:15,fontFamily: 'Montserrat',marginLeft:20,marginTop:30,cursor:"pointer"}} onClick={()=>{

          if(submembroTipo == true){
            location.href = './membros'
            return false
          }
          location.href = './membros'
            }}><FontAwesomeIcon icon={faArrowLeft} style={{fontSize:15,color:"#262626",marginRight:3}} />Voltar</p>

            <p style={{fontSize:16,fontWeight:'bold',fontFamily: 'Montserrat',marginLeft:20,marginTop:2}} id="id_cadastro">Cadastro de Membro+</p>
            
            <div style={{display:'flex'}}>
  

                    <div style={{display:'flex',flexDirection:'column',flex:1}}>

                
                            <div style={{flexDirection:'row',display:'flex'}}>
                                                <div class="mb-3" style={{marginLeft:10,marginTop:20,flex:1}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Número de celular:</label>
                                                    <input type="email" class="form-control" id="chave_celular" aria-describedby="emailHelp"   onChange={()=>chaveCelular()} maxLength={11}/>
                                                </div>

                                                <div class="mb-3" style={{marginTop:20,marginLeft:10,flex:1}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Relação com empresa</label>
                                                    
                                                    <select class="form-control" id="qual_relação">

                                                        {options.map((option, index) => (
                                                        <option key={index.iddependentetipo} value={option.iddependentetipo}>
                                                          {option.dependentetipo}
                                                        </option>
                                                      ))}
                                                            {/* <option value="-">-</option>
                                                            <option value="1" id="id_titular">Titular</option>
                                                            <option value="4" >Funcionário</option>

                                                            <option value="2"  id="id_conjuge" style={{display:'none'}}>Cônjuge</option>
                                                            <option value="3"  id="id_filho"  style={{display:'none'}}>Filho</option> */}


                                                    </select>
                                                </div>

                                                
                            </div>

                            <div style={{flexDirection:'row',display:'flex'}}>
                                                

                                                <div class="mb-3" style={{marginLeft:10,flex:3}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Nome:</label>
                                                    <input type="email" class="form-control" id="nome_cliente" aria-describedby="emailHelp"  />
                                                </div>

                                               
                                </div>

                    </div>

                    <div style={{display:'flex',flexDirection:'column',flex:0.5,padding:10}}>
                        <div style={{display:'flex',flex:1,borderRadius:10}}>
                            

                            <div style={{flex:1,borderTopLeftRadius:10,borderBottomLeftRadius:10,textAlign:'center',alignSelf:'center'}}>
                                <img src={more_falt1} style={{width:150,height:150,borderRadius:40}} id="imagem_user"/>
                            </div>

                            <div id="card_info" style={{flex:1.2,backgroundColor:'white',borderTopRightRadius:10,borderBottomRightRadius:10,flexDirection:'column',justifyContent:'center',display:'none',paddingLeft:10}}>
                                <span style={{fontSize:14}}>Nome</span>
                                <p style={{fontWeight:'bold',fontSize:14}} id='nome_2'>-</p>

                                <p style={{fontSize:14, marginBottom:1}}>Situação</p>
                                <p style={{fontWeight:'bold',fontSize:14,color:'green'}} id='situacao_2'>OK</p>
                            </div>

                            <div  id="card_info2" style={{flex:1.2,backgroundColor:'white',borderTopRightRadius:10,borderBottomRightRadius:10,flexDirection:'column',justifyContent:'center',display:'flex',paddingLeft:10}}>
                                <span style={{fontSize:14,fontWeight:'bold'}} id="titulo_msg">Xuliz & CDL</span>
                                <p style={{fontSize:12,marginTop:5}} id="titulo_msg2">Digite o número de celular para cadastrar um novo Membro+</p>

                             
                            </div>


                        </div>
                    </div>

                  
                    
             </div>

            {submembroTipo == false&&( <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{width:120,height:40,backgroundColor:'#e95453',marginLeft:10,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={()=>{
                  
                  if(submembroTipo == true){
                    location.href = './membros'
                    return false
                  }
                  location.href = './marketing'
                 }}
                  
                  >
                    <span style={{color:'#f2e1c4',fontSize:14}}>Cancelar</span>
                </div>

                <div style={{width:150,height:40,backgroundColor:'#262626',marginLeft:10,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={()=>{salvarMembro()}}>
                    <span style={{color:'#f2e1c4',fontSize:14}}>Adicionar Membro+</span>
                </div>
            </div>
            )}
           
            
           

            <div style={{display:'none',flex:1,marginTop:50}}  id="submembros_id">
                
                <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                        <p style={{fontSize:16,fontWeight:'bold',fontFamily: 'Montserrat',marginLeft:20,marginTop:3}}>Adicionar Submembros+</p>
                        <p style={{fontSize:12,fontFamily: 'Montserrat',marginLeft:20,color:'#585858'}}>Área destinada ao cadastro de submembros+ e do  membro+ acima.</p>

                          <div style={{display:'flex',flexDirection:'column',flex:0.5,padding:10}}>
                                  <div style={{display:'flex',flex:1,borderRadius:10}}>
                                      

                                      <div style={{flex:1,borderTopLeftRadius:10,borderBottomLeftRadius:10,textAlign:'center',alignSelf:'center'}}>
                                          <img src={more_falt1} style={{width:70,height:70,borderRadius:10}} id="imagem_user2"/>
                                      </div>

                                      <div id="card_info4" style={{flex:1.2,backgroundColor:'white',borderTopRightRadius:10,borderBottomRightRadius:10,flexDirection:'column',justifyContent:'center',display:'none',paddingLeft:10}}>
                                          <span style={{fontSize:14}}>Nome</span>
                                          <p style={{fontWeight:'bold',fontSize:14}} id='nome_3'>-</p>

                                          <p style={{fontSize:14, marginBottom:1}}>Situação</p>
                                          <p style={{fontWeight:'bold',fontSize:14,color:'green'}} id='situacao_3'>OK</p>
                                      </div>

                                      <div  id="card_info5" style={{flex:3,backgroundColor:'white',borderTopRightRadius:10,borderBottomRightRadius:10,flexDirection:'column',justifyContent:'center',display:'flex',paddingLeft:10}}>
                                          <span style={{fontSize:14,fontWeight:'bold'}} id="titulo_msg3">Xuliz & CDL</span>
                                          <p style={{fontSize:12,marginTop:5}} id="titulo_msg4">Digite o número de celular para cadastrar um novo Membro+</p>

                                      
                                      </div>


                                  </div>
                      </div>



                        <div class="mb-3" style={{marginLeft:10,marginTop:1,flex:1,marginRight:20,marginLeft:20}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Número de celular:</label>
                                                    <input type="email" class="form-control" id="numero_celular2" aria-describedby="emailHelp"   onChange={()=>chaveCelular2()}/>
                        </div>

                        <div class="mb-3" style={{marginLeft:10,marginTop:1,flex:1,marginRight:20,marginLeft:20}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Nome:</label>
                                                    <input type="email" class="form-control" id="nome_cliente2" aria-describedby="emailHelp"  />
                        </div>

                        <div class="mb-3" style={{marginTop:10,marginLeft:10,flex:1,marginRight:20,marginLeft:20}}>
                                                    <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Relação com membro</label>
                                                    
                                                    <select class="form-control" id="qual_relação2">
                                                    {options2.map((option, index) => (
                                                        <option key={index.iddependentetipo} value={option.iddependentetipo}>
                                                          {option.dependentetipo}
                                                        </option>
                                                      ))}

                                                    </select>
                            </div>

                            <div style={{display:'flex',flexDirection:'column'}}>
               



                   



                <div style={{width:200,height:40,backgroundColor:'#262626',marginLeft:10,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer',marginTop:5}} onClick={()=>{adicionarSubmembro()}}>
                    <span style={{color:'#f2e1c4',fontSize:14}}>Adicionar Submembro+</span>
                </div>

                <div style={{width:200,height:200,backgroundColor:'white',marginLeft:10,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer',marginTop:50}}>
                 </div>
            </div>

                        

                       
                </div>


               {submembros.length!=0&&( <div style={{display:'flex',flex:1.4,flexDirection:'column'}}>
                    <p style={{fontSize:16,fontWeight:'bold',fontFamily: 'Montserrat',marginLeft:20,marginTop:20}}>Submembros+ cadastrados</p>
                    <p style={{fontSize:12,fontFamily: 'Montserrat',marginLeft:20,color:'#585858'}}>Abaixo estão todos os submembros+ que foram cadastrados</p>
                    

                    <div style={{backgroundColor:'#464646',borderRadius:10,padding:10,height:250,overflow: 'auto' }}>
                    
                        {submembros.map((item, index) => {
                          var idRef = item.idempresaparceirousuario;
                          idRef = idRef.match(/\d+/g);

                          var idRef2 = item.idempresaparceirousuario;


                           var imagemRef = item.urlfotoperfil;

                           if(imagemRef == '' || imagemRef == ''){
                              imagemRef = 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
                            }



                            

                            const excluirMembro = async (idempresaparceirousuario,situacao)=>{

                              var url = "https://us-central1-restapi-4777e.cloudfunctions.net/app/excluirMembro.php";
                              var fun = 'excluirMembro';
                              if(situacao && situacao == "PENDENTE"){
                                url = "https://us-central1-restapi-4777e.cloudfunctions.net/app/excluirMembroCelular.php";
                                fun = 'excluirMembroCelular';
                              }
    
                              var get_idempresaparceirousuario = idempresaparceirousuario;
                              get_idempresaparceirousuario = ''+get_idempresaparceirousuario.match(/\d+/g);
                              
                              var idestabelecimento = dadosX.idestabelecimento
                              var token_estabelecimento = dadosX.tokenestabelecimento
    
                              
                              const options = {
                                method: 'POST',
                                url: url,
                                headers: {'Content-Type': 'application/json'},
                                params: {
                                  token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
                                  funcao: fun,
                                  token_estabelecimento: token_estabelecimento,
                                  idestabelecimento: idestabelecimento,
                                  idlinha:get_idempresaparceirousuario
                                  
                                },
                              };
                        
                              // console.log('POST',options)
                               
                              // return false;
                              
                              await axios.request(options).then(function (response) {
                                console.log('novoCadastro',response.data);
                                var dados = response.data;
                                
                                if(dados.erro != "N"){
                                    alert(dados.mensagem)
                                    return false;
                                }
                        
                                Swal.fire(
                                    'Sucesso!',
                                    ''+dados.mensagem,
                                    'success'
                                  ).then(()=>{
                                    location.reload()
                                  })
                       
                                     
                              
                              }).catch(function (error) {
                                console.error(error);
                                alert("Encontramos um problema tente mais tarde")
                              });
                        
                            }

                          return(
                            <div style={{width:'100%',height:30,marginBottom:10,flex:1,display:'flex'}}>
                                
                                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                                    <div style={{backgroundColor:'#f2e1c4',width:20,height:20,borderRadius:10,display:'flex',alignItems:'center',justifyContent:'center'}}>
                                        <span style={{textAlignLast:'center',fontSize:12}}>{index+1}</span>
                                    </div>
                                </div>

                                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                                    {/* <div style={{width:30,height:30,borderRadius:15,display:'flex',alignItems:'center',justifyContent:'center',borderWidth:1,borderColor:'#f2e1c4',borderStyle:'solid'}}>
                                    </div> */}

                                    <img style={{height:30,width:30,borderRadius:15,display:'flex',alignItems:'center',justifyContent:'center',borderWidth:1,borderColor:'#f2e1c4',borderStyle:'solid'}} src={imagemRef}>
                                                    
                                        </img>
                                                    
                                                
                                </div>

                                <div style={{display:'flex',flex:0.8,flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:9,color:'#8f8f8f'}}>ID</span>
                                    <p style={{fontSize:10,marginBottom:0,color:'white'}}>{idRef}</p>
                                </div>

                                <div style={{display:'flex',flex:3,flexDirection:'column',justifyContent:'center'}}>
                                    <span style={{fontSize:9,color:'#8f8f8f'}}>Nome</span>
                                    <p style={{fontSize:10,marginBottom:0,color:'white'}}>{item.nome}</p>
                                </div>

                                <div style={{display:'flex',flex:2,flexDirection:'column'}}>
                                    <span style={{fontSize:9,color:'#8f8f8f'}}>Nº Celular</span>
                                    <p style={{fontSize:10,marginBottom:0,color:'white'}}>{item.celular}</p>
                                </div>

                                <div style={{display:'flex',flex:2,flexDirection:'column'}}>
                                    <span style={{fontSize:9,color:'#8f8f8f'}}>Situação</span>
                                    <p style={{fontSize:10,marginBottom:0,color:'white'}}>{item.situacao}</p>
                                </div>

                                <div style={{display:'flex',flex:1,alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={()=>{excluirMembro(item.idempresaparceirousuario,item.situacao) }}>
                                        <FontAwesomeIcon icon={faTrash} style={{fontSize:12,color:'#f1e2c4'}} />     
                                </div>
                            </div>
                        )})}

                    </div>
                     <p style={{fontSize:12,fontFamily: 'Montserrat',marginLeft:20,color:'#585858',fontWeight:'bold',marginBottom:3,paddingTop:50}}>*Usuários não existentes</p>
                        <p style={{fontSize:11,fontFamily: 'Montserrat',marginLeft:20,color:'#585858'}}>Todos os usuários que estiverem com a situação como "Não existente" enviaremos um SMS informando sobre um benéficio exclusivo e incluindo um link para download do aplicativo Xuliz</p>



                </div>
               )}


            </div>

            <div style={{display:'flex',height:1,marginTop:10,borderWidth:0.6,borderColor:'#262626',borderStyle:'solid'}}>
            </div>
            

          </div> 
      </div> 

      </div>

      </>
    )
};

export default CadastrarSubmembrosJS;
