import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";

import { useEffect } from "react";
import xloja_12 from './assets/xloja_12.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg'; 

import xloja from './assets/xloja.png'; // Tell webpack this JS file uses this image


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faShoppingBag,faChartPie,faBox,faCircleDollarToSlot,faBullhorn,faQuestionCircle,faUsers,faGear,faSquareXmark,faMobile,faBars,faBookReader } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import axios from "axios";
import logo_parceiros from './assets/grupo677.png'; // Tell webpack this JS file uses this image


var vendaPaga = false;
   
const TelaXloja01Areaparceiros = () => {
  const navigate = useNavigate();

  const [emailRef, setEmailRef] = useState('');
  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [cashbackCliente, setCaschbackCliente] = useState('R$ 0,00');
  const [dadosClinte, setDadosCliente] = useState([]);
  const [dadosX, setDadosX] = useState([]);
  const [primeiroNome, setPrimeiroNome] = useState('');
  const [nomeUser, setNome] = useState('');

  const [clickEnviou, setClickEnviou] = useState(false);
  const [tokenEsta, setTokenEstabelecimento] = useState('');
  const [idvenda, setIdvenda] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [urlLogo, setUrlLogo] = useState('');

  
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);


  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
  
    verificarNiveisLogin(dadosX)
  };

 
  

  useEffect(() => {
    setarHora()
    getDados()
  }, []);

  const Swal = require('sweetalert2')


  const setarHora = async()=>{
    var getDa = await getData();
    document.getElementById("horas").innerHTML = getDa;
  }

  const getDados = async()=>{
    var dados = await localStorage.getItem('login')

    if(dados == null){
      navigate("/");

      return false;
    }
    dados = JSON.parse(dados)
     setDadosX(dados)

     setUrlLogo(dados.urllogo)
     verificarNiveisLogin(dados);

     console.log('dadosX',dados)

    setEmailRef(dados.email)
     console.log(dados)

     document.getElementById('nome_empresa').value = dados.empresa;

     if(dados.cashbackfixo == null){
      dados.cashbackfixo = 0;
     }

    // setCashback(10)
    var porcentagemxuliz = parseFloat(dados.cashbackfixo);
    if(porcentagemxuliz == ''){
      porcentagemxuliz = '0';
    }
    setCashback(porcentagemxuliz)
    document.getElementById('get_cashback').value = porcentagemxuliz+'%';

    ///PEGAR CASHBACK
  
     
  }


  const verificarNiveisLogin = async ()=>{
    var idperfil = await localStorage.getItem('tipoPerfil');
    
      
    if(idperfil == "7"){
        //financeiro somente relatorios
        location.href = "./minhasvendas";
        
    }


    if(idperfil == "8"){
      await sleep(50)

        //financeiro somente relatorios
        // document.getElementById('realizar_vendaID').style.display = "none";
       
        //  document.getElementById('vendas_hoje').style.display = "none";
         document.getElementById('historico_vendas').style.display = "none";
         document.getElementById('menu_marketing').style.display = "none";
         document.getElementById('menu_configuracoes').style.display = "none";

         

        
    }

}
  

  const chaveG = async()=>{

    var chave = document.getElementById('chave_id').value;
    console.log('asd')
    if(chave.length != 11){
      document.getElementById("nome_cliente").value = '';

      return false;
    }

    var qual_chave = document.getElementById('qual_chave').value;
     
    const form = new FormData();
    form.append("metodo", "ConsultaUsuarioChave");
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("chavecliente", ""+chave);
    form.append("chavetipo", qual_chave);
    
    const options = {
      method: 'POST',
    };
    
    options.body = form;
    
    fetch('https://xuliz.com.br/painel/integracao/usuario.php', options)
      .then(response => response.json())
      .then(response => setarDados(response))
      .catch(err => console.error(err));
  }

  const setarDados = async(dados)=>{
    console.log(dados)

    if(dados.erro != "N"){
      
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title:  dados.mensagem,
        showConfirmButton: false,
        timer: 1000
      })

      return false;
    }

    setDadosCliente(dados);

    console.log('dadosClinte',dadosClinte)

    document.getElementById("blockUser").style.display = "flex";

    dadosClinte.telefone = dados.email;

    var nome = dados.nome;

    setNome(nome)
    nome = nome.split(' ')
    setPrimeiroNome(nome[0])
     

    
    document.getElementById("nome_cliente").value = dados.nome
  
    document.getElementById("nome_cliente2").innerHTML = dados.nome
    document.getElementById("chave_cliente").innerHTML = dados.cpf

    var urlFoto = dados.urlfotoperfil;

    if(urlFoto == ''){
      urlFoto = 'https://cdn-icons-png.flaticon.com/512/149/149071.png';
    }

    setFotoUrl(urlFoto)



  } 


 


  const clickEnviar = async()=>{

    document.getElementById('msg_venda').style.display = "none";
    document.getElementById('msg_venda_enviada').style.display = "flex";
    document.getElementById('button_enviarar').style.display = 'none';


    if(clickEnviou == true){

      console.log('Trava click')
      return false;
    }
     

    setClickEnviou(true)

    //obter o token do establecimeeneto
    var getTokenEstabelecimneto = dadosX.tokenestabelecimento
    setTokenEstabelecimento(dadosX.tokenestabelecimento)
 

    var valorXX = document.getElementById('valor_cobrado').value;

    valorXX = valorXX.replace(/\D/g, '');
    valorXX = valorXX/100;
    valorXX = ''+valorXX;
    valorXX = valorXX.replace('.',',')

    console.log(valorXX)
     
    // return false;

      console.log(dadosClinte)
      var chave = document.getElementById('chave_id').value;

    var codigo_venda = document.getElementById('codigo_venda').value;
    var qual_chave = document.getElementById('qual_chave').value;
    const form = new FormData();
form.append("idempresa", "1350");
form.append("idestabelecimento", dadosX.idestabelecimento);
form.append("metodo", "VendaXLoja");
form.append("token", getTokenEstabelecimneto);
form.append("idusuariovenda", dadosX.idusuario);
form.append("mensagem", "");
form.append("idusuario",dadosClinte.idusuario );
form.append("chave", chave);
form.append("porcentagemcashback", cashbackConta);
form.append("tipo", qual_chave);
form.append("valor", valorXX);
form.append("codigovenda", codigo_venda);

    

const options = {
  method: 'POST',
};

options.body = form;

fetch('https://xuliz.com.br/painel/integracao/vendaxuliz.php?metodo=', options)
  .then(response => response.json())
  .then(response => {

    setClickEnviou(false)

    console.log(response)

    if(response.erro == "S"){

      Swal.fire(
        '',
        response.mensagem,
        'warning'
      )

      document.getElementById('msg_venda').style.display = "block";
      document.getElementById('msg_venda_enviada').style.display = "none";
      document.getElementById('button_enviarar').style.display = 'block';
      return false;
    }

    setIdvenda(response.idvendaxuliz)

    var idvendaxuliz = response.idvendaxuliz;
    
    aguardarPagamento(getTokenEstabelecimneto,idvendaxuliz)

    // Swal.fire(
    //   'Venda enviada',
    //   response.mensagem,
    //   'success'
    // )
  })
  .catch(err => {
    console.error(err);
    document.getElementById('msg_venda').style.display = "block";
    document.getElementById('msg_venda_enviada').style.display = "none";
    document.getElementById('button_enviarar').style.display = 'block';
    setClickEnviou(false)

  });
  }

  const aguardarPagamento = async(getTokenEstabelecimneto,idvendaxuliz)=>{
    
      verificarPagamento(getTokenEstabelecimneto,idvendaxuliz)

  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const verificarPagamento = async(getTokenEstabelecimneto,idvendaxuliz)=>{

    try {

      
      if(vendaPaga == false){
      await sleep(1000);
      loadderPagamento(getTokenEstabelecimneto,idvendaxuliz)
      verificarPagamento(getTokenEstabelecimneto,idvendaxuliz);
      }
    } catch (error) {
      verificarPagamento(getTokenEstabelecimneto,idvendaxuliz);
    }

  }

 
  const getDataInicial = async()=>{
    var data = new Date();
    data.setDate(data.getDate() - 7);
    
    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var dia = String(data.getDate()).padStart(2, '0');
    
    return ano + '-' + mes + '-' + dia;
  }

  const getDataFinal = async()=>{
    var data = new Date();
  
  var ano = data.getFullYear();
  var mes = String(data.getMonth() + 1).padStart(2, '0');
  var dia = String(data.getDate()).padStart(2, '0');
  
  return ano + '-' + mes + '-' + dia;
  }


  const loadderPagamento = async(getTokenEstabelecimneto,idvenda)=>{


    const options = {
  method: 'POST',
  url: 'https://us-central1-restapi-4777e.cloudfunctions.net/app/retaguarda.php',
  headers: {'Content-Type': 'application/json'},
  params: {
    token: 'f20sajd2-asdk2-2b32-b2cc03-820e292kd',
    funcao: 'vendaconsulta',
    token_estabelecimento:getTokenEstabelecimneto,
    idvendaxuliz: idvenda,
    idempresa: dadosX.idempresa
  },
};

await axios.request(options).then(function (response) {
  console.log(response.data);
  var dados = response.data;
       if(dados.erro != "N"){
          return false;
        }

        if(dados.idvendastatus == "2"){
          return pagamentoSalvo()

        }

}).catch(function (error) {
  console.error(error);
});



        
     
  }

  const pagamentoSalvo = async(lista)=>{

    if(vendaPaga == true){
      return false;
    }
   
    vendaPaga = true;

    Swal.fire(
      'Venda concluída com sucesso!',
      '',
      'success'
    ).then((res)=>{
      location.href = './minhasvendas'
    })

    // location.href = './ordem?id='+lista.idvenda


  }

  const getData= async()=>{
    var today = new Date();
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";
 
    day = checkZero(day);
    month = checkZero(month);
    year = checkZero(year);
    hour = checkZero(hour);
    minutes = checkZero(minutes);
 
    var data =  day + "/" + month + "/" + year + " " + hour + ":" + minutes;

    return data;
    function checkZero(data){
      if(data.length == 1){
        data = "0" + data;
      }
      return data;
    }
  }
 

  const formatCurrency = (inputValue) => {
    const rawValue = inputValue.replace(/\D/g, '');

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(rawValue / 100);
  };

  const handleChange = (event) => {
    setValue(formatCurrency(event.target.value));

    var rawValue2 = event.target.value.replace(/\D/g, '');
    
    var valor2 = parseInt(rawValue2);
    valor2 = rawValue2/100;

    const formatter2 = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    var total_venda = formatter2.format(rawValue2 / 100);

    document.getElementById('total_venda').innerHTML = total_venda;
    console.log('total_venda',total_venda)
    console.log('valor2',valor2)


    //calucalo cashback

    var getCas =parseInt(cashbackConta);
    getCas = getCas/100;
    getCas = getCas*valor2;

    // document.getElementById()
    // console.log('getCas',getCas)
    var formatadoCash =   formatter2.format(getCas); 
    // console.log('formatadoCash',formatadoCash)
 
    setCaschbackCliente(formatadoCash)


    //quando receber
    var quandoReceber = valor2-getCas;
    var formatadoCashForm =   formatter2.format(quandoReceber); 

    document.getElementById('quandoReceber').innerHTML = formatadoCashForm;


  };

  if(isMobile == true){

    return (
      <>
        <MenuMobile page="xloja"/>
        
        <div style={{height:150,width:'100%',flexDirection:'row',display:'flex',padding:10}}>
          <div style={{width:'100%',backgroundColor:'#f2e2c4',display:'flex',flex:1,margin:5,borderRadius:10,justifyContent:'center',alignItems:'center',flexDirection:'column'}} onClick={()=>{location.href = './xloja'}}>
            <img src={xloja} style={{width:"auto",height:30}} />

            <span style={{textAlign:'center',fontSize:14,marginTop:10}} id="realizar_vendaID">Realizar venda</span>

          </div>

          <div style={{width:'100%',backgroundColor:'#F1F1F1',display:'flex',flex:1,margin:5,borderRadius:10,justifyContent:'center',alignItems:'center',flexDirection:'column'}} id="vendas_hoje" onClick={()=>{location.href = './vendashoje'}}>
            <FontAwesomeIcon icon={faShoppingBag} style={{fontSize:30,color:"#262626"}} />
            <span style={{textAlign:'center',fontSize:14,marginTop:10}} >Vendas de hoje</span>

          </div>

          <div style={{width:'100%',backgroundColor:'#F1F1F1',display:'flex',flex:1,margin:5,borderRadius:10,justifyContent:'center',alignItems:'center',flexDirection:'column'}} id="historico_vendas" onClick={()=>{location.href = './minhasvendas'}}>
             <FontAwesomeIcon icon={faBookReader} style={{fontSize:30,color:"#262626"}} />

            <span style={{textAlign:'center',fontSize:14,marginTop:10}}>Histórico de vendas </span>

          </div>
        
        </div>









        <div style={{display:"flex",width:"100%"}}>
          <div style={{display:"flex",width:"100%",flexDirection:'column'}}>
          <span style={{display:'none'}} id="horas"></span>


            <div style={{width:'100%',flexDirection:'column',display:"flex",padding:15}}>
                <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flexDirection:'row'}}>
                    <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",padding:1,maxWidth:500}}>
                      <div style={{width:'100%',flexDirection:'column',display:"flex",backgroundColor:'#f1f1f1',height:"100%",borderRadius:10}}>
                         
                         <div style={{width:'100%',flexDirection:'row',display:"flex",backgroundColor:'#f1f1f1',borderRadius:10,flex:0.5,justifyContent:'center',padding:10,alignItems:"center",cursor:"pointer",paddingTop:20}} >
                          <span style={{fontSize:19}} id="realizar_vendaID">Realizar venda</span><span style={{paddingLeft:10,color:'black',fontSize:13}}> <FontAwesomeIcon icon={faQuestionCircle} style={{fontSize:10,color:"#262626",paddingRight:10}} />Central de ajuda</span>     
                         </div>    


                         <div style={{width:'100%',flexDirection:'column',display:"flex",backgroundColor:'#f1f1f1',flex:3,paddingLeft:30,paddingRight:30,paddingTop:2,borderRadius:10}}>
                     
                           

                            <div style={{flexDirection:'row',display:'flex'}}>
                              
                                <div class="mb-3" style={{}}>
                                  <label for="exampleInputEmail1" style={{fontSize:13}} class="form-label">Nome empresa/Estabelecimento*</label>
                                  <input type="email" class="form-control" id="nome_empresa" aria-describedby="emailHelp" readOnly={true} style={{backgroundColor:'#eaecef',color:'#495057'}}/>
    
                                </div>


                                <div class="mb-3" style={{marginLeft:10,flex:1}}>
                                  <label for="exampleInputEmail1" style={{fontSize:13}} class="form-label">Cashback*</label>
                                  <input type="email" class="form-control" id="get_cashback" aria-describedby="emailHelp" readOnly={true} style={{backgroundColor:'#eaecef',color:'#495057'}}/>
                                </div>
                            </div>


                            <div style={{flexDirection:'row',display:'flex',borderTop:"1px solid #afafaf"}}>
                                <div class="mb-3" style={{flex:1,marginTop:20}}>
                                  <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Tipo de chave*</label>
                                  
                                  <select class="form-control" id="qual_chave" onChange={()=> chaveG()}>
                                         <option value="cpf">CPF</option>
                                         <option value="celular">Celular</option>

                                  </select>
                                </div>

                                <div class="mb-3" style={{marginLeft:10,flex:3,marginTop:20}}>
                                  <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Chave*</label>
                                  <input type="email" class="form-control" id="chave_id" aria-describedby="emailHelp"   onChange={()=>chaveG()}/>
                                </div>
                            </div>

                            <div class="mb-3" style={{flex:2,borderTop:"1px solid #afafaf",paddingBottom:10}}>
                                  <label for="exampleInputEmail1" style={{fontSize:13,paddingTop:15}} class="form-label">Valor a ser cobrado*</label>
                                  <input type="email" class="form-control" id="valor_cobrado" onChange={handleChange}  value={value}/>
                                
                                  
                              </div>

                            <div class="mb-3" style={{flex:2}}>
                                  <label for="exampleInputEmail1" style={{fontSize:13}} class="form-label">Nome do cliente*</label>
                                  <input type="email" class="form-control" id="nome_cliente" aria-describedby="emailHelp" readOnly={false} style={{backgroundColor:'#eaecef',color:'#495057'}}/>
                              </div>

                              

                              <div class="mb-3" style={{flex:2,paddingBottom:20}}>
                                  <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Código da Venda</label>
                                  <input type="email" class="form-control" id="codigo_venda" aria-describedby="emailHelp"/>
                                </div>

                         </div> 

                         
                      </div>    
                    </div>    

                  
                </div>

            </div>

          </div>
 
      </div>

      













      <div id="blockUser" style={{width:'100%',flexDirection:'column',display:"none",height:"100%",padding:5,padding:20}}>
                      
                      <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flex:0.4,flexDirection:'row',maxWidth:400}}>
                            <div style={{display:"flex",flex:1,width:'100%',borderRadius:50,width:60,height:60}}>
                                  <img src={urlfotoperfil} style={{width:"auto",width:60,height:"auto",objectFit:"cover",borderRadius:30}} />
                              </div>

                              <div style={{display:"flex",flex:5,width:'100%',flexDirection:'column',justifyContent:'center',marginLeft:10,height:60}}>
                                  <p style={{marginBottom:1,fontSize:12,fontSize:20,fontWeight:'bold',color:'black'}} id="nome_cliente2"></p>
                                  <p style={{marginBottom:1,fontSize:8,color:'black'}} id="chave_cliente"></p>
                              </div>
                      </div>

                      <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flex:1,borderTop:"1px solid #afafaf",marginTop:10}}>

                        <p style={{fontSize:16,fontWeight:200,paddingTop:20,marginBottom:5}}>Total da venda</p>
                        <p style={{fontSize:20,fontWeight:'bold'}} id="total_venda">R$ 0,00</p>

                        <p style={{fontSize:16,fontWeight:200,paddingTop:20,marginBottom:5}}>Cashback para o cliente</p>
                        <p style={{fontSize:20,fontWeight:'bold',color:'green',marginBottom:20}}>{cashbackCliente} <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.40625 18L18.4063 2M18.4063 2H2.40625M18.4063 2V18" stroke="#279C33" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
</p>
                       

                      </div>

                      <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flex:1,borderTop:"1px solid #afafaf",paddingTop:1}}>
                          <p style={{fontSize:20,fontWeight:'bold',paddingTop:5,marginBottom:2}}>Total a receber</p>
                          <p style={{fontSize:13}}>*antes das taxas</p>
                          <p style={{fontSize:25,fontWeight:'bold',marginBottom:2,fontSize:35}} id="quandoReceber">R$ 0,00</p>

                      </div>

                      <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flex:5,backgroundColor:'#3d3d3d',height:80,marginTop:20,borderRadius:5,justifyContent:'center',cursor:"pointer",padding:20}} onClick={()=>clickEnviar()} id="button_enviarar">
                        <span style={{marginLeft:20,color:'#f1e2c4'}}>Enviar venda</span>
                        
                      </div>


                      

                      <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flex:10,backgroundColor:'#f1f1f1',borderRadius:5,marginTop:10,justifyContent:'center',alignItems:'center',padding:10}} id="msg_venda">
  <span style={{color:'#999999',fontSize:12,textAlign:'center'}} >Esperando envio da venda...</span>



</div>

<div style={{width:'100%',flexDirection:'column',display:"none",height:"100%",flex:10,backgroundColor:'#f1f1f1',borderRadius:5,marginTop:10,justifyContent:'center',alignItems:'center',padding:20}} id="msg_venda_enviada">
  <span style={{color:'#999999',fontSize:12,textAlign:'center'}} > <FontAwesomeIcon icon={faMobile} style={{fontSize:10,paddingRight:10}} />A notificação chegou no aparelho celular do cliente, {primeiroNome}</span>
  <img src={waiting} style={{width:"auto",height:30,paddingTop:10}} />


</div>

<div style={{width:20,height:200}}></div>
                            
                  </div>   



      </>
    )
   
 
  }


  if(isMobile == false){

  return (
    <>

    <div style={{height:'100vh',display:"flex"}}>
      <div style={{backgroundColor:'#262626',}}>
          <Menu page="xloja" />
      </div>

      <div style={{height:'100vh',display:"flex",width:"100%"}}>
          <div style={{display:"flex",width:"100%",margin:40,flexDirection:'column'}}>
            
            <div style={{width:'100%',flexDirection:'column',display:"flex",height:50,flexDirection:'row'}}>
                    <div style={{height:50,display:"flex",width:"100%",flex:2,alignItems:'center'}}>
                        <img src={xloja_12} style={{width:"auto",height:50,}} />
                        <span style={{marginLeft:30,fontSize:15,fontWeight:600,cursor:"pointer"}}  onClick={()=>{location.href = './xloja'}}>Realizar venda</span>

                        <span style={{marginLeft:10,fontSize:15,fontWeight:600,marginLeft:30,cursor:"pointer",color:'#6c6c6c'}} onClick={()=>{location.href = './vendashoje'}} id="vendas_hoje" >Vendas de hoje</span>


                        <span style={{marginLeft:10,fontSize:15,fontWeight:600,marginLeft:30,cursor:"pointer",color:'#6c6c6c'}} onClick={()=>{location.href = './minhasvendas'}} id="historico_vendas">Histórico de vendas</span>

                    </div>  

                    <div style={{height:50,display:"flex",width:"100%",flex:0.5,alignItems:'center'}}>
                      <span style={{marginLeft:10,fontSize:20,marginLeft:30,fontWeight:600}} id="horas"></span>
                    </div>  
            </div>


            <div style={{width:'100%',flexDirection:'column',display:"flex",height:'100vh',padding:'0px 30px'}}>
                <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flexDirection:'row'}}>
                    <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",padding:'0px 5px',maxWidth:500}}>
                      <div style={{width:'100%',flexDirection:'column',display:"flex",backgroundColor:'#f1f1f1',height:"100%",borderRadius:10}}>
                         
                         
                         <div style={{width:'100%',flexDirection:'row',display:"flex",backgroundColor:'#f1f1f1',borderRadius:10,flex:0.5,justifyContent:'center',padding:10,alignItems:"center",cursor:"pointer"}} >
                          <span style={{fontSize:19}}>Realizar venda</span><span style={{paddingLeft:10,color:'black',fontSize:13}}> <FontAwesomeIcon icon={faQuestionCircle} style={{fontSize:10,color:"#262626",paddingRight:10}} />Central de ajuda</span>     
                         </div>    


                         <div style={{width:'100%',flexDirection:'column',display:"flex",backgroundColor:'#f1f1f1',flex:3,paddingLeft:30,paddingRight:30,paddingTop:10,borderRadius:10}}>
                     
                           

                            <div style={{flexDirection:'row',display:'flex'}}>
                              
                                <div class="mb-3" style={{}}>
                                  <label for="exampleInputEmail1" style={{fontSize:13}} class="form-label">Nome empresa/Estabelecimento*</label>
                                  <input type="email" class="form-control" id="nome_empresa" aria-describedby="emailHelp" readOnly={true} style={{backgroundColor:'#eaecef',color:'#495057'}}/>
    
                                </div>


                                <div class="mb-3" style={{marginLeft:10,flex:1}}>
                                  <label for="exampleInputEmail1" style={{fontSize:13}} class="form-label">Cashback*</label>
                                  <input type="email" class="form-control" id="get_cashback" aria-describedby="emailHelp" readOnly={true} style={{backgroundColor:'#eaecef',color:'#495057'}}/>
                                </div>
                            </div>


                            <div style={{flexDirection:'row',display:'flex',borderTop:"1px solid #afafaf"}}>
                                <div class="mb-3" style={{flex:1,marginTop:20}}>
                                  <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Tipo de chave*</label>
                                  
                                  <select class="form-control" id="qual_chave" onChange={()=> chaveG()}>
                                         <option value="cpf">CPF</option>
                                         <option value="celular">Celular</option>

                                  </select>
                                </div>

                                <div class="mb-3" style={{marginLeft:10,flex:3,marginTop:20}}>
                                  <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Chave*</label>
                                  <input type="email" class="form-control" id="chave_id" aria-describedby="emailHelp"   onChange={()=>chaveG()}/>
                                </div>
                            </div>

                            <div class="mb-3" style={{flex:2,borderTop:"1px solid #afafaf",paddingBottom:10}}>
                                  <label for="exampleInputEmail1" style={{fontSize:13,paddingTop:15}} class="form-label">Valor a ser cobrado*</label>
                                  <input type="email" class="form-control" id="valor_cobrado" onChange={handleChange}  value={value}/>
                                
                                  
                              </div>

                            <div class="mb-3" style={{flex:2}}>
                                  <label for="exampleInputEmail1" style={{fontSize:13}} class="form-label">Nome do cliente*</label>
                                  <input type="email" class="form-control" id="nome_cliente" aria-describedby="emailHelp" readOnly={false} style={{backgroundColor:'#eaecef',color:'#495057'}}/>
                              </div>

                              

                              <div class="mb-3" style={{flex:2}}>
                                  <label for="exampleInputEmail1" style={{fontSize:10}} class="form-label">Código da Venda</label>
                                  <input type="email" class="form-control" id="codigo_venda" aria-describedby="emailHelp"/>
                                </div>

                         </div> 

                         
                      </div>    
                    </div>    

                    <div id="blockUser" style={{width:'100%',flexDirection:'column',display:"none",height:"100%",padding:5,paddingLeft:50,}}>
                      
                        <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flex:0.4,flexDirection:'row',maxWidth:400}}>
                              <div style={{display:"flex",flex:1,width:'100%',borderRadius:50,width:60,height:60}}>
                                    <img src={urlfotoperfil} style={{width:"auto",width:60,height:"auto",objectFit:"cover",borderRadius:30}} />
                                </div>

                                <div style={{display:"flex",flex:5,width:'100%',flexDirection:'column',justifyContent:'center',marginLeft:10,height:60}}>
                                    <p style={{marginBottom:1,fontSize:12,fontSize:20,fontWeight:'bold',color:'black'}} id="nome_cliente2"></p>
                                    <p style={{marginBottom:1,fontSize:8,color:'black'}} id="chave_cliente"></p>
                                </div>
                        </div>

                        <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flex:1,borderTop:"1px solid #afafaf",marginTop:10}}>

                          <p style={{fontSize:16,fontWeight:200,paddingTop:20,marginBottom:5}}>Total da venda</p>
                          <p style={{fontSize:20,fontWeight:'bold'}} id="total_venda">R$ 0,00</p>

                          <p style={{fontSize:16,fontWeight:200,paddingTop:20,marginBottom:5}}>Cashback para o cliente</p>
                          <p style={{fontSize:20,fontWeight:'bold',color:'green',marginBottom:20}}>{cashbackCliente} <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M2.40625 18L18.4063 2M18.4063 2H2.40625M18.4063 2V18" stroke="#279C33" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
</p>
                         

                        </div>

                        <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flex:1,borderTop:"1px solid #afafaf",paddingTop:1}}>
                            <p style={{fontSize:20,fontWeight:'bold',paddingTop:5,marginBottom:2}}>Total a receber</p>
                            <p style={{fontSize:13}}>*antes das taxas</p>
                            <p style={{fontSize:25,fontWeight:'bold',marginBottom:2,fontSize:35}} id="quandoReceber">R$ 0,00</p>

                        </div>

                        <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flex:5,backgroundColor:'#3d3d3d',height:80,marginTop:20,borderRadius:5,justifyContent:'center',cursor:"pointer"}} onClick={()=>clickEnviar()} id="button_enviarar">
                          <span style={{marginLeft:20,color:'#f1e2c4'}}>Enviar venda</span>
                          
                        </div>


                        

                        <div style={{width:'100%',flexDirection:'column',display:"flex",height:"100%",flex:10,backgroundColor:'#f1f1f1',borderRadius:5,marginTop:10,justifyContent:'center',alignItems:'center'}} id="msg_venda">
    <span style={{color:'#999999',fontSize:12,textAlign:'center'}} >Esperando envio da venda...</span>



</div>

<div style={{width:'100%',flexDirection:'column',display:"none",height:"100%",flex:10,backgroundColor:'#f1f1f1',borderRadius:5,marginTop:10,justifyContent:'center',alignItems:'center'}} id="msg_venda_enviada">
    <span style={{color:'#999999',fontSize:12,textAlign:'center'}} > <FontAwesomeIcon icon={faMobile} style={{fontSize:10,paddingRight:10}} />A notificação chegou no aparelho celular do cliente, {primeiroNome}</span>
    <img src={waiting} style={{width:"auto",height:30,paddingTop:10}} />


</div>
                              
                    </div>    
                </div>

            </div>

          </div>
 
      </div>

      

    
    </div>

 
    
     






     
    </>

  )
 
  }
};

export default TelaXloja01Areaparceiros;
