import styles from "./LoginAreaparceiros02.module.css";
const LoginAreaparceiros02 = () => {
  return (
    <div className={styles.loginAreaparceiros02}>
      <div className={styles.loginAreaparceiros02Child} />
      <div className={styles.loginAreaparceiros02Item} />
      <div className={styles.loginAreaparceiros02Inner}>
        <div className={styles.logomarcaXulizParent}>
          <img
            className={styles.logomarcaXulizIcon}
            alt=""
            src="/logomarca-xuliz@2x.png"
          />
          <div className={styles.lineParent}>
            <div className={styles.groupChild} />
            <div className={styles.groupItem} />
            <div className={styles.eMailCnpjOu}>E-mail, CNPJ ou telefone</div>
            <div className={styles.senha}>Senha</div>
          </div>
          <div className={styles.botoEntrar}>
            <div className={styles.entrar}>ENTRAR</div>
            <div className={styles.botoEntrarChild} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginAreaparceiros02;
