import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./TelaXloja01AreaparceirosVAZ.module.css";
const TelaXloja01AreaparceirosVAZ = () => {
  const navigate = useNavigate();

  const onVendasDeHojeClick = useCallback(() => {
    navigate("/tela-xloja02-areaparceiros");
  }, [navigate]);

  const onHistricoDeVendasClick = useCallback(() => {
    navigate("/tela-xloja03-areaparceiros");
  }, [navigate]);

  const onCalendrioTextClick = useCallback(() => {
    navigate("/tela-xloja04-areaparceiros");
  }, [navigate]);

  const onGroupContainer17Click = useCallback(() => {
    navigate("/tela-xloja01-areaparceiros");
  }, [navigate]);

  const onGroupContainer20Click = useCallback(() => {
    navigate("/desktop");
  }, [navigate]);

  const onGroupContainer24Click = useCallback(() => {
    navigate("/tela-home-areaparceiros");
  }, [navigate]);

  return (
    <div className={styles.telaXloja01AreaparceirosVaz}>
      <div className={styles.realizarVenda}>Realizar venda</div>
      <div className={styles.div}>12/04/2023 - 17:59</div>
      <div className={styles.vendasDeHoje} onClick={onVendasDeHojeClick}>
        Vendas de hoje
      </div>
      <div
        className={styles.histricoDeVendas}
        onClick={onHistricoDeVendasClick}
      >
        Histórico de vendas
      </div>
      <div
        className={styles.calendrio}
        onClick={onCalendrioTextClick}
      >{`Calendário `}</div>
      <img
        className={styles.telaXloja01AreaparceirosVazChild}
        alt=""
        src="/group-5601.svg"
      />
      <img
        className={styles.telaXloja01AreaparceirosVazItem}
        alt=""
        src="/line-30.svg"
      />
      <div className={styles.telaXloja01AreaparceirosVazInner} />
      <div className={styles.lineDiv} />
      <div className={styles.totalDaVendaParent}>
        <div className={styles.totalDaVenda}>Total da venda:</div>
        <div className={styles.groupParent}>
          <div className={styles.wrapper}>
            <div className={styles.totalAReceber}>0,00</div>
          </div>
          <img className={styles.rIcon} alt="" src="/r.svg" />
        </div>
      </div>
      <div className={styles.totalAReceberParent}>
        <div className={styles.totalAReceber}>Total a receber:</div>
        <div className={styles.antesDasTaxas}>*antes das taxas</div>
        <div className={styles.parent}>
          <div className={styles.div2}>0,00</div>
          <img className={styles.rIcon1} alt="" src="/r1.svg" />
        </div>
      </div>
      <div className={styles.cashbackParaOClienteParent}>
        <div className={styles.totalDaVenda}>Cashback para o cliente:</div>
        <div className={styles.rParent}>
          <img className={styles.rIcon} alt="" src="/r2.svg" />
          <div className={styles.group}>
            <div className={styles.enviarVenda}>0,00</div>
            <img className={styles.vectorIcon} alt="" src="/vector21.svg" />
          </div>
        </div>
      </div>
      <div className={styles.atualizaoVendaXloja}>
        <div className={styles.default}>
          <div className={styles.enviarVenda}>Enviar venda</div>
          <img className={styles.vectorIcon1} alt="" src="/vector22.svg" />
        </div>
        <div className={styles.rectangleParent}>
          <div className={styles.groupChild} />
          <div className={styles.esperandoEnvioDa}>
            Esperando envio da venda...
          </div>
        </div>
      </div>
      <div className={styles.groupContainer}>
        <div className={styles.nomeClienteParent}>
          <div className={styles.totalAReceber}>Nome cliente</div>
          <div className={styles.eMailCliente}>e-mail cliente</div>
        </div>
        <img className={styles.groupItem} alt="" src="/ellipse-2.svg" />
        <img className={styles.userIcon} alt="" src="/user@2x.png" />
      </div>
      <div className={styles.rectangleDiv} />
      <div className={styles.realizarVenda1}>Realizar venda</div>
      <div className={styles.telaXloja01AreaparceirosVazChild1} />
      <div className={styles.telaXloja01AreaparceirosVazChild2} />
      <div className={styles.telaXloja01AreaparceirosVazChild3} />
      <div className={styles.nomeEmpresaestabelecimentoParent}>
        <div className={styles.totalDaVenda}>Nome empresa/Estabelecimento*</div>
        <div className={styles.feitoComAmorFcamorWrapper}>
          <div className={styles.enviarVenda}>{`Feito Com Amor - FCAMOR `}</div>
        </div>
      </div>
      <div className={styles.nomeDoClienteParent}>
        <div className={styles.totalDaVenda}>Nome do cliente:*</div>
        <div className={styles.groupInner} />
      </div>
      <div className={styles.valorASerCobradoParent}>
        <div className={styles.totalDaVenda}>Valor a ser cobrado:</div>
        <div className={styles.rGroup}>
          <div className={styles.r}>R$</div>
          <div className={styles.div4}>0,00</div>
        </div>
      </div>
      <div className={styles.cdigoDaVendaParent}>
        <div className={styles.totalDaVenda}>Código da Venda*</div>
        <div className={styles.frameDiv} />
      </div>
      <div className={styles.chaveParent}>
        <div className={styles.totalDaVenda}>Chave:</div>
        <div className={styles.groupChild1} />
      </div>
      <div className={styles.cashbackParent}>
        <div className={styles.totalDaVenda}>Cashback*</div>
        <div className={styles.div5}>%</div>
        <div className={styles.container}>
          <div className={styles.enviarVenda}>12</div>
        </div>
      </div>
      <div className={styles.tipoDeChaveParent}>
        <div className={styles.totalDaVenda}>Tipo de chave:</div>
        <div className={styles.defalut}>
          <div className={styles.cpf}>CPF</div>
          <img className={styles.vectorIcon2} alt="" src="/vector23.svg" />
        </div>
      </div>
      <div className={styles.vectorParent} onClick={onGroupContainer17Click}>
        <img className={styles.vectorIcon3} alt="" src="/vector24.svg" />
        <div className={styles.centralDeAjuda}>Central de ajuda</div>
      </div>
      <div className={styles.telaXloja01AreaparceirosVazChild4} />
      <div className={styles.ellipseParent}>
        <img className={styles.ellipseIcon} alt="" src="/ellipse-1@2x.png" />
        <div className={styles.feitoComAmor1}>FEITO COM AMOR</div>
        <div className={styles.feitocomamorxulizcombr}>
          feitocomamor@xuliz.com.br
        </div>
      </div>
      <img className={styles.lineIcon} alt="" src="/line-13.svg" />
      <div className={styles.vectorGroup}>
        <img className={styles.vectorIcon3} alt="" src="/vector2.svg" />
        <div className={styles.centralDeAjuda}>Configurações</div>
      </div>
      <div
        className={styles.closeTracedParent}
        onClick={onGroupContainer20Click}
      >
        <img
          className={styles.closeTracedIcon}
          alt=""
          src="/close-traced.svg"
        />
        <div className={styles.centralDeAjuda}>Sair</div>
      </div>
      <div className={styles.vectorContainer}>
        <img className={styles.vectorIcon5} alt="" src="/vector3.svg" />
        <div className={styles.centralDeAjuda}>Contas</div>
      </div>
      <div className={styles.groupDiv}>
        <img className={styles.vectorIcon3} alt="" src="/vector4.svg" />
        <div className={styles.centralDeAjuda}>Central de ajuda</div>
      </div>
      <div className={styles.lineParent}>
        <div className={styles.groupChild2} />
        <div className={styles.sentTracedParent}>
          <img
            className={styles.sentTracedIcon}
            alt=""
            src="/sent-traced1.svg"
          />
          <div className={styles.produtos}>Produtos</div>
        </div>
        <div className={styles.vectorParent1} onClick={onGroupContainer24Click}>
          <img className={styles.vectorIcon7} alt="" src="/vector5.svg" />
          <div className={styles.home}>Home</div>
        </div>
        <div className={styles.colorPaletteTracedParent}>
          <img
            className={styles.colorPaletteTraced}
            alt=""
            src="/color-palette-traced1.svg"
          />
          <div className={styles.relatriosWrapper}>
            <div className={styles.relatrios}>Relatórios</div>
          </div>
        </div>
        <div className={styles.vectorParent2}>
          <img className={styles.vectorIcon8} alt="" src="/vector25.svg" />
          <div className={styles.xloja}>XLoja</div>
        </div>
        <div className={styles.financeTracedParent}>
          <img
            className={styles.financeTracedIcon}
            alt=""
            src="/finance-traced1.svg"
          />
          <div className={styles.saque}>Saque</div>
        </div>
      </div>
      <img
        className={styles.logomarcaXulizIcon}
        alt=""
        src="/logomarca-xuliz1@2x.png"
      />
      <img className={styles.parceirosIcon} alt="" src="/parceiros.svg" />
      <img
        className={styles.telaXloja01AreaparceirosVazChild5}
        alt=""
        src="/line-6.svg"
      />
    </div>
  );
};

export default TelaXloja01AreaparceirosVAZ;
