import styles from "./LoginAreaparceiros.module.css";
import img from './assets/img1.png'; // Tell webpack this JS file uses this image
import logo1 from './assets/Logo2.png'; // Tell webpack this JS file uses this image
// import Input from "./Input";
import { useState,useEffect } from "react";
import axios from "axios";


const Swal = require('sweetalert2')

const LoginAreaparceiros = () => {

  const [isMobile, setIsMobile] = useState(false);
  const [meuIP, setMeuIP] = useState('');

  
  
  useEffect(() => {

    obterIP()

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);


  const obterIP = async ()=>{

    const response = await axios.get('https://geolocation-db.com/json/');
    setMeuIP(JSON.stringify(response.data));
   }

  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
 
  };


  const clickEntrar = async ()=>{

    var email = document.getElementById('email_id').value;
    var senha = document.getElementById('senha_id').value;

    if(email == ''){
      return false;
    }

    if(senha == ''){
      return false;
    }

    try {
      const form = new FormData();
      form.append("metodo", "ConsultaUsuarioParceiro");
      form.append("idempresa", "1350");
      form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
      form.append("usuario", email);
      form.append("senha", senha);
      form.append("ip", meuIP);

      const options = {
        method: 'POST',
      };

      options.body = form;

      fetch('https://xuliz.com.br/painel/integracao/login.php?t=2', options)
        .then(response => response.json())
        .then(response => {
          tratarInfo(response)
        })
        .catch(err => {
          Swal.fire(
            '',
            'Aconteceu um erro e caso o problema persista, favor entrar em contato com suporte@xuliz.com.br',
            'error'
          )
        });

    } catch (error) {
      console.log(error)
    }

    //  location.href = './tela-home-areaparceiros'
  
  }

 
  const tratarInfo = async (dados)=>{
    console.log(dados)
    try {

      if(dados.erro == "S"){
        Swal.fire(
          '',
          dados.mensagem,
          'warning'
        )

        return false;
      }


     await  Swal.fire(
        'Bem vindo',
        dados.nome,
        'success'
      )

      await localStorage.setItem('login',JSON.stringify(dados))
      await localStorage.setItem('tipoPerfil',dados.idusuariotipo)


      
    location.href = './xloja'



    } catch (error) {
      console.log(error)
      Swal.fire(
        'Ops!',
        'Aconteceu um erro e caso o problema persista, favor entrar em contato com suporte@xuliz.com.br',
        'error'
      )
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      clickEntrar();
    }
  };

  useEffect(() => {
      const senhaInput = document.getElementById('senha_id');
      const emailInput = document.getElementById('email_id');
      if (senhaInput) {
        senhaInput.addEventListener('keypress', handleKeyPress);
      }
      if (emailInput) {
        emailInput.addEventListener('keypress', handleKeyPress);
      }

    }, []);

  if(isMobile == true){
    return(

      <div style={{display:'flex',backgroundColor:'#f1e2c4',width:'100vw',height:'100vh',justifyContent:'center',alignItems:'center'}}>
       
        <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                  <img src={logo1} style={{width:100,marginBottom:50}}/>
  
                  <form>
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">Usuário</label>
                      <input type="email" class="form-control" id="email_id" aria-describedby="emailHelp"/>
                    </div>
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">Senha</label>
                      <input type="password" class="form-control" id="senha_id"/>
                    </div>
                    
                    <div class="d-grid gap-2" style={{textAlign:'-webkit-center'}}>
                      <button class="btn btn-primary" type="button" style={{height:50,backgroundColor:'#f1e2c4',borderColor:'#262626',color:'#262626',marginTop:40}}onClick={()=>clickEntrar()}>Entrar</button>
                      <a href="./esquecisenha" style={{color:'#262626'}}>Esqueci minha senha</a>
                    </div>

                  </form>


          </div>

      
        
      </div>
        

               

      
    )
  }


  if(isMobile == false){
  return (
    <div  className={styles.home}>

      
       
          <div style={{backgroundColor:'#f1e2c4',display:"flex"}} id="home2">
            <img src={img} style={{objectFit:'cover'}}/>
          </div>
          
          <div style={{backgroundColor:'#f1e2c4',display:"flex",flex:2,padding:50,maxWidth:1000}} id="home3">
             
              <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <img src={logo1} style={{width:100,marginBottom:50}}/>

                <form>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Usuário</label>
                    <input type="email" class="form-control" id="email_id" aria-describedby="emailHelp"/>
                   </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Senha</label>
                    <input type="password" class="form-control" id="senha_id"/>
                  </div>
                  
                  <div class="d-grid gap-2" style={{textAlign:'-webkit-center'}}>
                    <button class="btn btn-primary" type="button" style={{height:50,backgroundColor:'#f1e2c4',borderColor:'#262626',color:'#262626',marginTop:40}}onClick={()=>clickEntrar()}>Entrar</button>
                    <a href="./esquecisenha" style={{color:'#262626'}}>Esqueci minha senha</a>
                  </div>

                </form>


              </div>
         
          </div>
      
    </div>
  );
  }

  return (<></>)
};

export default LoginAreaparceiros;
