import React from 'react';
import { useEffect } from "react";

import { useState } from "react";
import './css.css'; // Certifique-se de importar o arquivo CSS


const Calendario = ({mes,dataCalendario,ano,filtrarDias})=>{

  const [datasSelecionadas, setDatasSelecionadas] = useState([]);

   
  
  useEffect(() => {
    showcalander()

  },[mes,dataCalendario])


  const adicionarSelecaoData = (id_data, tipo) => {
    var id = id_data.match(/\d+/g);
    id = parseInt(id);
    if (id < 10) {
        id = "0" + id;
    }

    var array_datas = datasSelecionadas // Cria uma cópia do array

    if (tipo == "adicionar") {
        array_datas.push('' +id);
        setDatasSelecionadas(array_datas);
    }

    if (tipo == "remover") {
        const index = array_datas.indexOf(id.toString());
        if (index !== -1) {
            array_datas.splice(index, 1); // Remove o item do array
        }

        setDatasSelecionadas(array_datas);
    }
    console.log(array_datas)

    filtrarDias(array_datas)


}


 async function showcalander(day) {
 
   async function createCalendar(year, month) {
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      const daysInMonth = lastDay.getDate();
      const startingDay = firstDay.getDay();  

      const calendarBody = document.getElementById("calendar");
      calendarBody.innerHTML = "";
 
      var table = document.createElement("table");
      table.style.width = '100%';
      var header = document.createElement("tr");
      header.style.backgroundColor = '#3d3d3d';
      header.style.color = '#F2E2C4';
      header.style.textAlign = '-webkit-center';

      
      const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
      daysOfWeek.forEach(day => {
          var th = document.createElement("th");

          th.style.borderTopLeftRadius = 5;
          th.style.padding = 4;
          th.style.fontSize = 10;
          th.style.padding = '10px';

          th.textContent = day;
          header.appendChild(th);
      });
      table.appendChild(header);

      let day = 1;
      for (let i = 0; i < 6; i++) {
          const row = document.createElement("tr");
          for (let j = 0; j < 7; j++) {
              if (i === 0 && j < startingDay) {
                  var cell = document.createElement("td");

                 


                  row.appendChild(cell);
              } else if (day > daysInMonth) {
                  break;
              } else {
                  var cell = document.createElement("td");
                  // cell.textContent = day;
                  cell.style.backgroundColor = 'white';
                  cell.style.height = 80;
                  cell.style.borderWidth = 0.4;
                  cell.style.borderColor = 'gray';
                  cell.style.padding = '10px';
                  cell.style.border = '1px solid black;';
                  cell.style.borderWidth = '0.2px';
                  cell.style.cursor = 'pointer';
 
                  cell.addEventListener('click', function() {
                    // console.log(this)
                    // console.log(this.style.border)
                    // alert(this.id)


                      if (this.style.border === '1px solid gray' || this.style.border == "" ) {
                          this.style.border = '3px solid black'; 
                          this.style.backgroundColor = "#ffefd2"

                          adicionarSelecaoData(this.id,'adicionar')

                      } else {
                          this.style.border = '1px solid gray';
                          this.style.backgroundColor = "white"

                          adicionarSelecaoData(this.id,'remover')

                      }
                  });
                  var getApi = await getValorCalendario(day)
                  var ref_cell =  "dia_"+day;
                  cell.id =ref_cell

                  // console.log('asdf'+ref_cell)
                  // var diasselecionados = ["01-05-2024","02-05-2024","03-05-2024"]

                  // for(var x=0;x<API;x++){
                  //   var getRe = API[x].data;

                  //   var itemSelecionado = false;

                  //   for(var y=0;y< diasselecionados.length;y++){
                  //     if(getRe == diasselecionados[y]){
                  //       itemSelecionado = true
                  //       break
                  //     } 
                  //   }

                  //   ///
                  // }
                 

 
                  var dia_valor = getApi.valor;
                  var pendente = getApi.futuro;
                  dia_valor = dia_valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });


                  var div =  ` <div style="background-color: #E17E36; display: flex; width: 100%; justify-content: center; border-radius: 5px;">
                  <span style="font-size: 10px; padding: 4px;">receber</span>
              </div> `

                if(pendente == false){
                      div =  ` <div style="background-color: green; display: flex; width: 100%; justify-content: center; border-radius: 5px;">
                        <span style="font-size: 10px; padding: 4px;">Pago</span>
                    </div> `
                }



                  cell.innerHTML = `
                  <div style="display: flex;">
                      <div style="display: flex; width: 150%;">
                          <span style="color: #999999; font-size: 10px; padding: 4px;">`+day+`</span>
                      </div>
                      `+div+`
                  </div>
                  <div style="display: flex; flex-direction: column; ">
                      <span style="font-size: 12px; margin-top: 20px;"> `+dia_valor+`</span>
                      <span style="font-size: 12px; margin-top: 2px;">`+getApi.totalPedidos+` vendas</span>
                  </div>
              `;


                  row.appendChild(cell);
                  day++;
              }
          }
          table.appendChild(row);
          if (day > daysInMonth) {
              break;
          }
      }
      calendarBody.appendChild(table);
  }


  const getValorCalendario = async (dia)=>{

    var valor = 0;
    var futuro = true;
    var totalPedidos = 0;

    var getMes = parseInt(''+mes)+1;
    if(getMes < 10){
      getMes ='0'+getMes;
    }
    
    var getDia = parseInt(''+dia);
    

    if(getDia < 10){
      getDia ='0'+getDia;
    }

  
    for(var x=0;x<dataCalendario.length;x++){

      var dataGet = ''+dataCalendario[x].data;
      dataGet = dataGet.split('-');


 
      var mesRef = getMes+'-'+getDia;


      var mesAPI = dataGet[1]+'-'+dataGet[2];

      // console.log('CONSULTA',mesRef+' || '+dataCalendario[x].data+'  || '+mesAPI)

      var ano_api = parseInt(dataGet[0]);
      var mes_api = parseInt(dataGet[1]) - 1; // Meses são indexados de 0 a 11
      var dia_api = parseInt(dataGet[2]);
      if(mesRef != mesAPI){

        var today = new Date();
        var dia_hoje = today.getDate();
        // console.log(mesRef+" || "+mesAPI +"  || "+dia_api +" ||"+dia_hoje+" || "+dia)

        var mesHoje = today.getMonth()+1;
        var mesRef_int = parseInt(""+mesRef)

        if(mesRef_int < mesHoje){
          futuro = false
        }

        
        // console.log("mesAPI"+mesRef_int+" || mesHOJE:"+mesHoje)


        if(dia < dia_hoje){

          futuro = false
        }

        if(mesRef_int>mesHoje){
          futuro = true;
        }

 
         continue
      }


       
   
  
 
      var dataComparar = new Date(ano_api, mes_api, dia_api);
      var agora = new Date();
      
      // Comparando as datas
      // console.log('COMPARE '+dataComparar+'   __  '+agora +' -->'+dataGet +' REF->'+mesRef+'  ->'+mesAPI)

        if (dataComparar < agora) {
          // console.log('PASSADO '+dataComparar+'   __  '+agora )
          futuro = false;
        }  

  
      // console.log(mesAPI+' ||| '+mesRef+' ||| '+dataCalendario[x].total+' || '+agora +'  || '+dataComparar)
      valor = dataCalendario[x].total;
      totalPedidos = dataCalendario[x].totalPedidos;

    }
    // console.log('CONSULTA'+valor)
     
    return{
      valor:valor,
      futuro:futuro,
      totalPedidos:totalPedidos
    }

  }
 
  const now = new Date();
  // var currentMonth = now.getMonth();
  var currentMonth = parseInt(mes)
  var currentYear = parseInt(ano)

  

   createCalendar(currentYear, currentMonth);

  }

  return(<>
    <div className="calendar" style={{width:'100%'}}>
      <div id="calendar">
      </div>
  </div>
  </>
  )
 
  }


  export default Calendario;