import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./TelaXloja03Areaparceiros.module.css";
const TelaXloja03Areaparceiros = () => {
  const navigate = useNavigate();

  const onRealizarVendaTextClick = useCallback(() => {
    navigate("/tela-xloja01-areaparceiros");
  }, [navigate]);

  const onVendasDeHojeClick = useCallback(() => {
    navigate("/tela-xloja02-areaparceiros");
  }, [navigate]);

  const onCalendrioTextClick = useCallback(() => {
    navigate("/tela-xloja04-areaparceiros");
  }, [navigate]);

  const onGroupContainer36Click = useCallback(() => {
    navigate("/desktop");
  }, [navigate]);

  const onGroupContainer40Click = useCallback(() => {
    navigate("/tela-home-areaparceiros");
  }, [navigate]);

  return (
    <div className={styles.telaXloja03Areaparceiros}>
      <div className={styles.realizarVenda} onClick={onRealizarVendaTextClick}>
        Realizar venda
      </div>
      <div className={styles.div}>12/04/2023 - 17:59</div>
      <div className={styles.vendasDeHoje} onClick={onVendasDeHojeClick}>
        Vendas de hoje
      </div>
      <div className={styles.histricoDeVendas}>Histórico de vendas</div>
      <div
        className={styles.calendrio}
        onClick={onCalendrioTextClick}
      >{`Calendário `}</div>
      <img
        className={styles.telaXloja03AreaparceirosChild}
        alt=""
        src="/group-560.svg"
      />
      <img
        className={styles.telaXloja03AreaparceirosItem}
        alt=""
        src="/line-30.svg"
      />
      <div className={styles.datahora}>Data/Hora</div>
      <div className={styles.previsoDePagamentoContainer}>
        <p className={styles.previsoDe}>Previsão de</p>
        <p className={styles.previsoDe}>pagamento</p>
      </div>
      <div className={styles.estabelecimento}>Estabelecimento</div>
      <div className={styles.bandeira}>Bandeira</div>
      <div className={styles.formaDePagamento}>Forma de pagamento</div>
      <div className={styles.valorVenda}>Valor venda</div>
      <div className={styles.cashback}>Cashback</div>
      <div className={styles.valorDaTaxatarifaContainer}>
        <p className={styles.previsoDe}>Valor da</p>
        <p className={styles.previsoDe}>taxa/tarifa</p>
      </div>
      <div className={styles.valorLquido}>Valor líquido</div>
      <div className={styles.status}>Status</div>
      <div className={styles.telaXloja03AreaparceirosInner} />
      <div className={styles.rectangleDiv} />
      <div className={styles.telaXloja03AreaparceirosChild1} />
      <div className={styles.valorDaVenda}>Valor da venda (fim)</div>
      <div className={styles.valorDaVenda1}>Valor da venda (início)</div>
      <div className={styles.frameDiv}>
        <div className={styles.quantidadeDeVendasParent}>
          <div className={styles.quantidadeDeVendas}>Quantidade de vendas</div>
          <div className={styles.div1}>679</div>
          <div className={styles.valorBruto}>Valor bruto</div>
          <div className={styles.valorLquido1}>Valor líquido</div>
          <div className={styles.rParent}>
            <div className={styles.r}>R$</div>
            <div className={styles.div2}>79.047,78</div>
          </div>
          <div className={styles.rGroup}>
            <div className={styles.r}>R$</div>
            <div className={styles.div2}>77.469,19</div>
          </div>
          <img className={styles.groupChild} alt="" src="/line-29.svg" />
          <img className={styles.groupItem} alt="" src="/line-29.svg" />
        </div>
      </div>
      <div className={styles.filtrosSelecionadosParent}>
        <div
          className={styles.filtrosSelecionados}
        >{`Filtros selecionados: `}</div>
        <div className={styles.data0104202304042023Parent}>
          <div className={styles.data01042023}>
            Data: 01/04/2023 à 04/04/2023
          </div>
          <img className={styles.vectorIcon} alt="" src="/vector8.svg" />
        </div>
      </div>
      <div className={styles.exportarParent}>
        <div className={styles.exportar}>Exportar</div>
        <img className={styles.vectorIcon1} alt="" src="/vector9.svg" />
      </div>
      <div className={styles.vectorWrapper}>
        <img className={styles.vectorIcon2} alt="" src="/vector10.svg" />
      </div>
      <div className={styles.crditoParent}>
        <div className={styles.crdito}>Crédito</div>
        <div className={styles.rContainer}>
          <div className={styles.r}>R$</div>
          <div className={styles.div4}>274.305,88</div>
        </div>
      </div>
      <div className={styles.dbitoParent}>
        <div className={styles.crdito}>Débito</div>
        <div className={styles.groupDiv}>
          <div className={styles.r}>R$</div>
          <div className={styles.div4}>10.543,20</div>
        </div>
      </div>
      <div className={styles.pixParent}>
        <div className={styles.crdito}>Pix</div>
        <div className={styles.rParent1}>
          <div className={styles.r}>R$</div>
          <div className={styles.div4}>35.145,24</div>
        </div>
      </div>
      <div className={styles.xulizParent}>
        <div className={styles.crdito}>Xuliz</div>
        <div className={styles.rParent2}>
          <div className={styles.r}>R$</div>
          <div className={styles.div4}>52.869,47</div>
        </div>
      </div>
      <div className={styles.groupParent}>
        <div className={styles.lineParent}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon3} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineGroup}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon3} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineContainer}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon3} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineParent1}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon3} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineParent2}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon3} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineParent3}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon3} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineParent4}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon3} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineParent5}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon17} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineParent6}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon17} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineParent7}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon17} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineParent8}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon17} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineParent9}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon17} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
        <div className={styles.lineParent10}>
          <div className={styles.groupInner} />
          <div className={styles.r4590Parent}>
            <div className={styles.r4590}>R$ 45,90</div>
            <div className={styles.r162}>-R$ 1,62</div>
            <div className={styles.r648}>R$ 6,48</div>
            <div className={styles.r5400}>R$54,00</div>
            <div className={styles.xuliz1}>Xuliz</div>
            <div className={styles.div8}>16515615621561161</div>
            <div className={styles.div9}>23/04/2023 - 20:41</div>
            <div className={styles.div10}>23/04/2023</div>
            <div className={styles.aprovada}>
              <div className={styles.aprovado}>Aprovado</div>
              <img className={styles.vectorIcon17} alt="" src="/vector11.svg" />
            </div>
            <img className={styles.vectorIcon4} alt="" src="/vector12.svg" />
            <div className={styles.bandeiras}>
              <div className={styles.bandeirasChild} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-380@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.default}>
        <div className={styles.perodo}>Período</div>
        <img className={styles.vectorIcon29} alt="" src="/vector13.svg" />
      </div>
      <div className={styles.bandeiras13}>
        <div className={styles.perodo}>Bandeira</div>
        <img className={styles.vectorIcon30} alt="" src="/vector14.svg" />
      </div>
      <div className={styles.formaspagamento}>
        <div className={styles.formaDePagamento1}>Forma de pagamento</div>
        <img className={styles.vectorIcon31} alt="" src="/vector14.svg" />
      </div>
      <div className={styles.telaXloja03AreaparceirosChild2} />
      <div className={styles.ellipseParent}>
        <img className={styles.ellipseIcon} alt="" src="/ellipse-1@2x.png" />
        <div className={styles.feitoComAmor}>FEITO COM AMOR</div>
        <div className={styles.feitocomamorxulizcombr}>
          feitocomamor@xuliz.com.br
        </div>
      </div>
      <img className={styles.lineIcon} alt="" src="/line-13.svg" />
      <div className={styles.vectorParent}>
        <img className={styles.vectorIcon32} alt="" src="/vector2.svg" />
        <div className={styles.configuraes}>Configurações</div>
      </div>
      <div
        className={styles.closeTracedParent}
        onClick={onGroupContainer36Click}
      >
        <img
          className={styles.closeTracedIcon}
          alt=""
          src="/close-traced.svg"
        />
        <div className={styles.configuraes}>Sair</div>
      </div>
      <div className={styles.vectorGroup}>
        <img className={styles.vectorIcon33} alt="" src="/vector3.svg" />
        <div className={styles.configuraes}>Contas</div>
      </div>
      <div className={styles.vectorContainer}>
        <img className={styles.vectorIcon32} alt="" src="/vector4.svg" />
        <div className={styles.configuraes}>Central de ajuda</div>
      </div>
      <div className={styles.lineParent11}>
        <div className={styles.groupChild12} />
        <div className={styles.sentTracedParent}>
          <img
            className={styles.sentTracedIcon}
            alt=""
            src="/sent-traced.svg"
          />
          <div className={styles.produtos}>Produtos</div>
        </div>
        <div className={styles.vectorParent1} onClick={onGroupContainer40Click}>
          <img className={styles.vectorIcon35} alt="" src="/vector5.svg" />
          <div className={styles.home}>Home</div>
        </div>
        <div className={styles.colorPaletteTracedParent}>
          <img
            className={styles.colorPaletteTraced}
            alt=""
            src="/color-palette-traced.svg"
          />
          <div className={styles.relatriosWrapper}>
            <div className={styles.relatrios}>Relatórios</div>
          </div>
        </div>
        <div className={styles.vectorParent2}>
          <img className={styles.vectorIcon36} alt="" src="/vector6.svg" />
          <div className={styles.xloja}>XLoja</div>
        </div>
        <div className={styles.financeTracedParent}>
          <img
            className={styles.financeTracedIcon}
            alt=""
            src="/finance-traced.svg"
          />
          <div className={styles.saque}>Saque</div>
        </div>
      </div>
      <img
        className={styles.logomarcaXulizIcon}
        alt=""
        src="/logomarca-xuliz1@2x.png"
      />
      <img className={styles.parceirosIcon} alt="" src="/parceiros.svg" />
      <img
        className={styles.telaXloja03AreaparceirosChild3}
        alt=""
        src="/line-6.svg"
      />
    </div>
  );
};

export default TelaXloja03Areaparceiros;
