import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./TelaXloja02Areaparceiros.module.css";
const TelaXloja02Areaparceiros = () => {
  const navigate = useNavigate();

  const onRealizarVendaTextClick = useCallback(() => {
    navigate("/tela-xloja01-areaparceiros");
  }, [navigate]);

  const onHistricoDeVendasClick = useCallback(() => {
    navigate("/tela-xloja03-areaparceiros");
  }, [navigate]);

  const onCalendrioTextClick = useCallback(() => {
    navigate("/tela-xloja04-areaparceiros");
  }, [navigate]);

  const onGroupContainer20Click = useCallback(() => {
    navigate("/desktop");
  }, [navigate]);

  const onGroupContainer24Click = useCallback(() => {
    navigate("/tela-home-areaparceiros");
  }, [navigate]);

  return (
    <div className={styles.telaXloja02Areaparceiros}>
      <div className={styles.realizarVenda} onClick={onRealizarVendaTextClick}>
        Realizar venda
      </div>
      <div className={styles.div}>12/04/2023 - 17:59</div>
      <div className={styles.vendasDeHoje}>Vendas de hoje</div>
      <div
        className={styles.histricoDeVendas}
        onClick={onHistricoDeVendasClick}
      >
        Histórico de vendas
      </div>
      <div
        className={styles.calendrio}
        onClick={onCalendrioTextClick}
      >{`Calendário `}</div>
      <img
        className={styles.telaXloja02AreaparceirosChild}
        alt=""
        src="/group-5601.svg"
      />
      <img
        className={styles.telaXloja02AreaparceirosItem}
        alt=""
        src="/line-30.svg"
      />
      <div className={styles.horrio}>Horário</div>
      <div
        className={styles.filtrosSelecionados}
      >{`Filtros selecionados: `}</div>
      <div className={styles.bandeira}>Bandeira</div>
      <div className={styles.formaDePagamento}>Forma de pagamento</div>
      <div className={styles.nmeroDoCarto}>Número do cartão</div>
      <div className={styles.tid}>TID</div>
      <div className={styles.cdDeAturoizao}>Cód. de aturoização</div>
      <div className={styles.nsudoc}>NSU/DOC</div>
      <div className={styles.valorDaVenda}>Valor da venda</div>
      <div className={styles.cashback}>Cashback</div>
      <div className={styles.status}>Status</div>
      <div className={styles.telaXloja02AreaparceirosInner} />
      <div className={styles.groupParent}>
        <div className={styles.r21600Parent}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Xuliz</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.bandeirasChild} />
            <img
              className={styles.bandeirasItem}
              alt=""
              src="/rectangle-380@2x.png"
            />
          </div>
          <div className={styles.div4}>----------</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada}>
            <div className={styles.aprovado}>Aprovado</div>
            <img className={styles.vectorIcon} alt="" src="/vector11.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon1} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Group}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Crédito</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.rectangleParent}>
              <div className={styles.groupItem} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-388@2x.png"
              />
            </div>
          </div>
          <div className={styles.div4}>******98165</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada}>
            <div className={styles.aprovado}>Aprovado</div>
            <img className={styles.vectorIcon} alt="" src="/vector11.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon1} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Container}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Xuliz</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.bandeirasChild} />
            <img
              className={styles.bandeirasItem}
              alt=""
              src="/rectangle-380@2x.png"
            />
          </div>
          <div className={styles.div4}>----------</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada}>
            <div className={styles.aprovado}>Aprovado</div>
            <img className={styles.vectorIcon} alt="" src="/vector11.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon1} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.groupDiv}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Crédito</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.rectangleParent}>
              <div className={styles.groupItem} />
              <img
                className={styles.bandeirasItem}
                alt=""
                src="/rectangle-388@2x.png"
              />
            </div>
          </div>
          <div className={styles.div4}>****654896</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada}>
            <div className={styles.aprovado}>Aprovado</div>
            <img className={styles.vectorIcon} alt="" src="/vector11.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon1} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Parent1}>
          <div className={styles.r216004}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Débito</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.rectangleParent}>
              <div className={styles.groupChild4} />
              <img
                className={styles.groupChild5}
                alt=""
                src="/rectangle-382@2x.png"
              />
            </div>
          </div>
          <div className={styles.div4}>*****51929</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada4}>
            <div className={styles.rejeitado}>Rejeitado</div>
            <img className={styles.vectorIcon8} alt="" src="/vector16.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r25924}>R$ 25,92</div>
            <img className={styles.vectorIcon1} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Parent2}>
          <div className={styles.r216004}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Crédito</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.rectangleParent}>
              <div className={styles.groupChild7} />
              <img
                className={styles.groupChild8}
                alt=""
                src="/rectangle-394@2x.png"
              />
            </div>
          </div>
          <div className={styles.div4}>****92988</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada5}>
            <div className={styles.estornado}>Estornado</div>
            <img className={styles.vectorIcon10} alt="" src="/vector17.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r25924}>R$ 25,92</div>
            <img className={styles.vectorIcon1} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Parent3}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Xuliz</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.bandeirasChild} />
            <img
              className={styles.bandeirasItem}
              alt=""
              src="/rectangle-380@2x.png"
            />
          </div>
          <div className={styles.div4}>----------</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada}>
            <div className={styles.aprovado}>Aprovado</div>
            <img className={styles.vectorIcon} alt="" src="/vector11.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon1} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Parent4}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Xuliz</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.bandeirasChild} />
            <img
              className={styles.bandeirasItem}
              alt=""
              src="/rectangle-380@2x.png"
            />
          </div>
          <div className={styles.div4}>----------</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada7}>
            <div className={styles.pendente}>Pendente</div>
            <img className={styles.aprovadaChild} alt="" src="/group-643.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon1} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Parent5}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Xuliz</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.bandeirasChild} />
            <img
              className={styles.bandeirasItem}
              alt=""
              src="/rectangle-380@2x.png"
            />
          </div>
          <div className={styles.div4}>----------</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada}>
            <div className={styles.aprovado}>Aprovado</div>
            <img className={styles.vectorIcon15} alt="" src="/vector11.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon16} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Parent6}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Saldo Xuliz + Crédito</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.bandeirasChild} />
            <img
              className={styles.bandeirasItem}
              alt=""
              src="/rectangle-380@2x.png"
            />
          </div>
          <div className={styles.div4}>----------</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada}>
            <div className={styles.aprovado}>Aprovado</div>
            <img className={styles.vectorIcon17} alt="" src="/vector11.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon18} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Parent7}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Saldo Xuliz + Crédito</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.bandeirasChild} />
            <img
              className={styles.bandeirasItem}
              alt=""
              src="/rectangle-380@2x.png"
            />
          </div>
          <div className={styles.div4}>----------</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada}>
            <div className={styles.aprovado}>Aprovado</div>
            <img className={styles.vectorIcon17} alt="" src="/vector11.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon18} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Parent8}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Saldo Xuliz + Crédito</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.bandeirasChild} />
            <img
              className={styles.bandeirasItem}
              alt=""
              src="/rectangle-380@2x.png"
            />
          </div>
          <div className={styles.div4}>----------</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada}>
            <div className={styles.aprovado}>Aprovado</div>
            <img className={styles.vectorIcon17} alt="" src="/vector11.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon18} alt="" src="/vector15.svg" />
          </div>
        </div>
        <div className={styles.r21600Parent9}>
          <div className={styles.r21600}>R$ 216,00</div>
          <div className={styles.div1}>430121</div>
          <div className={styles.div2}>070093</div>
          <div className={styles.ql090bcoc}>28107163102QL090BCOC</div>
          <div className={styles.xuliz}>Saldo Xuliz + Crédito</div>
          <div className={styles.div3}>14:46</div>
          <div className={styles.bandeiras}>
            <div className={styles.bandeirasChild} />
            <img
              className={styles.bandeirasItem}
              alt=""
              src="/rectangle-380@2x.png"
            />
          </div>
          <div className={styles.div4}>----------</div>
          <div className={styles.groupChild} />
          <div className={styles.aprovada}>
            <div className={styles.aprovado}>Aprovado</div>
            <img className={styles.vectorIcon17} alt="" src="/vector11.svg" />
          </div>
          <div className={styles.r2592Parent}>
            <div className={styles.r2592}>R$ 25,92</div>
            <img className={styles.vectorIcon18} alt="" src="/vector15.svg" />
          </div>
        </div>
      </div>
      {/* <div className={styles.horrioDeVendaIncio01Parent}>
        <div className={styles.horrioDeVenda}>
          Horário de venda (início): 01
        </div>
        <img className={styles.vectorIcon25} alt="" src="/vector18.svg" />
      </div> */}
      {/* <div className={styles.horrioDeVendaFim03Parent}>
        <div className={styles.horrioDeVenda}>Horário de venda (fim): 03</div>
        <img className={styles.vectorIcon25} alt="" src="/vector19.svg" />
      </div> */}
      <div className={styles.rectangleParent2}>
        <div className={styles.groupChild17} />
        <div className={styles.nmeroDoCarto1}>Número do cartão</div>
      </div>
      <div className={styles.telaXloja02AreaparceirosChild1} />
      <div className={styles.cdigoDeAutorizao}>Código de autorização</div>
      <div className={styles.defalut}>
        <div className={styles.horrioDeVenda2}>Horário de venda (início)</div>
        <img className={styles.vectorIcon27} alt="" src="/vector14.svg" />
      </div>
      <div className={styles.defalut02}>
        <div className={styles.horrioDeVenda2}>Horário de venda (fim)</div>
        <img className={styles.vectorIcon27} alt="" src="/vector14.svg" />
      </div>
      <div className={styles.defalut021}>
        <div className={styles.formaDePagamento1}>Forma de pagamento</div>
        <img className={styles.vectorIcon29} alt="" src="/vector14.svg" />
      </div>
      <div className={styles.telaXloja02AreaparceirosChild2} />
      <div className={styles.ellipseParent}>
        <img className={styles.ellipseIcon} alt="" src="/ellipse-1@2x.png" />
        <div className={styles.feitoComAmor}>FEITO COM AMOR</div>
        <div className={styles.feitocomamorxulizcombr}>
          feitocomamor@xuliz.com.br
        </div>
      </div>
      <img className={styles.lineIcon} alt="" src="/line-13.svg" />
      <div className={styles.vectorParent}>
        <img className={styles.vectorIcon30} alt="" src="/vector2.svg" />
        <div className={styles.configuraes}>Configurações</div>
      </div>
      <div
        className={styles.closeTracedParent}
        onClick={onGroupContainer20Click}
      >
        <img
          className={styles.closeTracedIcon}
          alt=""
          src="/close-traced.svg"
        />
        <div className={styles.configuraes}>Sair</div>
      </div>
      <div className={styles.vectorGroup}>
        <img className={styles.vectorIcon31} alt="" src="/vector3.svg" />
        <div className={styles.configuraes}>Contas</div>
      </div>
      <div className={styles.vectorContainer}>
        <img className={styles.vectorIcon30} alt="" src="/vector4.svg" />
        <div className={styles.configuraes}>Central de ajuda</div>
      </div>
      <div className={styles.lineParent}>
        <div className={styles.groupChild18} />
        <div className={styles.sentTracedParent}>
          <img
            className={styles.sentTracedIcon}
            alt=""
            src="/sent-traced1.svg"
          />
          <div className={styles.produtos}>Produtos</div>
        </div>
        <div className={styles.vectorParent1} onClick={onGroupContainer24Click}>
          <img className={styles.vectorIcon33} alt="" src="/vector5.svg" />
          <div className={styles.home}>Home</div>
        </div>
        <div className={styles.colorPaletteTracedParent}>
          <img
            className={styles.colorPaletteTraced}
            alt=""
            src="/color-palette-traced.svg"
          />
          <div className={styles.relatriosWrapper}>
            <div className={styles.relatrios}>Relatórios</div>
          </div>
        </div>
        <div className={styles.vectorParent2}>
          <img className={styles.vectorIcon34} alt="" src="/vector20.svg" />
          <div className={styles.xloja}>XLoja</div>
        </div>
        <div className={styles.financeTracedParent}>
          <img
            className={styles.financeTracedIcon}
            alt=""
            src="/finance-traced.svg"
          />
          <div className={styles.saque}>Saque</div>
        </div>
      </div>
      <img
        className={styles.logomarcaXulizIcon}
        alt=""
        src="/logomarca-xuliz1@2x.png"
      />
      <img className={styles.parceirosIcon} alt="" src="/parceiros.svg" />
      <img
        className={styles.telaXloja02AreaparceirosChild3}
        alt=""
        src="/line-6.svg"
      />
    </div>
  );
};

export default TelaXloja02Areaparceiros;
