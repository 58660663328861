import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./TelaSaque01Areaparceiros.module.css";
const TelaSaque01Areaparceiros = () => {
  const navigate = useNavigate();

  const onGroupContainer2Click = useCallback(() => {
    navigate("/desktop");
  }, [navigate]);

  const onGroupContainer5Click = useCallback(() => {
    navigate("/tela-produtos01-areaparceiros");
  }, [navigate]);

  const onGroupContainer6Click = useCallback(() => {
    navigate("/tela-home-areaparceiros");
  }, [navigate]);

  const onGroupContainer8Click = useCallback(() => {
    navigate("/tela-relatorios01-areaparceiros");
  }, [navigate]);

  const onGroupContainer9Click = useCallback(() => {
    navigate("/tela-xloja01-areaparceiros");
  }, [navigate]);

  return (
    <div className={styles.telaSaque01Areaparceiros}>
      <div className={styles.telaSaque01AreaparceirosChild} />
      <div className={styles.ellipseParent}>
        <img className={styles.groupChild} alt="" src="/ellipse-1@2x.png" />
        <div className={styles.feitoComAmor}>FEITO COM AMOR</div>
        <div className={styles.feitocomamorxulizcombr}>
          feitocomamor@xuliz.com.br
        </div>
      </div>
      <img
        className={styles.telaSaque01AreaparceirosItem}
        alt=""
        src="/line-13.svg"
      />
      <div className={styles.vectorParent}>
        <img className={styles.vectorIcon} alt="" src="/vector2.svg" />
        <div className={styles.configuraes}>Configurações</div>
      </div>
      <div
        className={styles.closeTracedParent}
        onClick={onGroupContainer2Click}
      >
        <img
          className={styles.closeTracedIcon}
          alt=""
          src="/close-traced.svg"
        />
        <div className={styles.configuraes}>Sair</div>
      </div>
      <div className={styles.vectorGroup}>
        <img className={styles.vectorIcon1} alt="" src="/vector3.svg" />
        <div className={styles.configuraes}>Contas</div>
      </div>
      <div className={styles.vectorContainer}>
        <img className={styles.vectorIcon} alt="" src="/vector4.svg" />
        <div className={styles.configuraes}>Central de ajuda</div>
      </div>
      <div className={styles.lineParent}>
        <div className={styles.groupItem} />
        <div
          className={styles.sentTracedParent}
          onClick={onGroupContainer5Click}
        >
          <img
            className={styles.sentTracedIcon}
            alt=""
            src="/sent-traced1.svg"
          />
          <div className={styles.produtos}>Produtos</div>
        </div>
        <div className={styles.groupDiv} onClick={onGroupContainer6Click}>
          <img className={styles.vectorIcon3} alt="" src="/vector5.svg" />
          <div className={styles.home}>Home</div>
        </div>
        <div
          className={styles.colorPaletteTracedParent}
          onClick={onGroupContainer8Click}
        >
          <img
            className={styles.colorPaletteTraced}
            alt=""
            src="/color-palette-traced1.svg"
          />
          <div className={styles.relatriosWrapper}>
            <div className={styles.relatrios}>Relatórios</div>
          </div>
        </div>
        <div className={styles.vectorParent1} onClick={onGroupContainer9Click}>
          <img className={styles.vectorIcon4} alt="" src="/vector26.svg" />
          <div className={styles.xloja}>XLoja</div>
        </div>
        <div className={styles.financeTracedParent}>
          <img
            className={styles.financeTracedIcon}
            alt=""
            src="/finance-traced2.svg"
          />
          <div className={styles.saque}>Saque</div>
        </div>
      </div>
      <img
        className={styles.logomarcaXulizIcon}
        alt=""
        src="/logomarca-xuliz1@2x.png"
      />
      <img className={styles.parceirosIcon} alt="" src="/parceiros.svg" />
      <img
        className={styles.telaSaque01AreaparceirosInner}
        alt=""
        src="/line-6.svg"
      />
    </div>
  );
};

export default TelaSaque01Areaparceiros;
