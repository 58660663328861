import styles from "./LoginAreaparceiros.module.css";
import img from './assets/fudo_esqueciCopia.png'; // Tell webpack this JS file uses this image
import logo1 from './assets/Logo2.png'; // Tell webpack this JS file uses this image
// import Input from "./Input";
import grupo667 from './assets/grupo667.png'; // Tell webpack this JS file uses this image
import { useState,useEffect } from "react";

const Swal = require('sweetalert2')

const EsqueciSenha = () => {
    
    const [pageRef, setPageRef] = useState('sucesso');
    const [email, setEmail] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      updateDimensions();
      window.addEventListener('resize', updateDimensions);
  
      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    }, []);
  
  
    const updateDimensions = () => {
      setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
    };
  
    

  const clickEnviar = async ()=>{
    var email_id = document.getElementById('email_id').value;

    if(email_id == ""){
        Swal.fire({
            icon: 'warning',
            title:  'Digite seu email',
          })
        return false;
    }

    const form = new FormData();
    form.append("metodo", "ReenviarSenha");
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("usuario", email_id);

    const options = {
      method: 'POST',
    };

    options.body = form;

    setLoading(true)

    fetch('https://xuliz.com.br/painel/integracao/usuario.php?t=2', options)
      .then(response => response.json())
      .then(response => {
        tratarInfo(response)
      })
      .catch(err => {
        setLoading(false)
        Swal.fire(
          '',
          'Aconteceu um erro e caso o problema persista, favor entrar em contato com suporte@xuliz.com.br',
          'error'
        )
      });
  }
 
  const clickOK = async()=>{
    location.href = './';
  }

 
  const tratarInfo = async (dados)=>{
    console.log(dados)
    setLoading(false)
    try {

      if(dados.erro == "S"){
        Swal.fire(
          '',
          dados.mensagem,
          'warning'
        )

        return false;
      }


     await  Swal.fire(
        '',
        dados.mensagem,
        'success'
      ).then(()=>{
        location.href = './'
      })


 


    } catch (error) {
      console.log(error)
      Swal.fire(
        'Ops!',
        'Aconteceu um erro e caso o problema persista, favor entrar em contato com suporte@xuliz.com.br',
        'error'
      )
    }
   
    
  }


  //MOBIL SCREEN
  if(isMobile == true){
    
    return(
      <div style={{display:'flex',backgroundColor:'#f1e2c4',width:'100vw',height:'100vh',justifyContent:'center',alignItems:'center'}}>
           
              <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <img src={logo1} style={{width:100,marginBottom:50}}/>
 
                <form style={{maxWidth:300}}>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label" style={{width:'100%',textAlign:'-webkit-center'}}>Recuperar acesso</label>
                    <label for="exampleInputEmail1" class="form-label" style={{width:'100%',textAlign:'-webkit-center',fontSize:12,color:'#606060',marginBottom:30}}>Informe os seguintes dados e clique no botão enviar para que seja enviada o passo a passo, via e-mail.</label>

                   </div>

                  
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Usuário</label>
                    <input type="text" class="form-control" id="email_id"/>
                  </div>

                  <label for="exampleInputEmail1" class="form-label" style={{width:'100%',textAlign:'-webkit-center'}}>

                    <img src={grupo667} style={{height:15,marginBottom:20,marginTop:30}} ></img>

                    </label>
                                      
                  <div class="d-grid gap-2" style={{textAlign:'-webkit-center'}}>
                  {loading==false&&(<button class="btn btn-primary" type="button" style={{height:50,backgroundColor:'#f1e2c4',borderColor:'#262626',color:'#262626',marginTop:40}}onClick={()=>clickEnviar()}>Enviar</button>)}
                    <a href="./" style={{color:'#262626'}}>Voltar</a>
                  </div>

                


                </form>

         
          </div>
      </div>
    )
  }


  //DESKTOP SCREEN
  if(isMobile == false){
   

  return (
    <div  className={styles.home}>

      
       
          <div style={{backgroundColor:'#f1e2c4',display:"flex"}} id="home2">
            <img src={img} style={{objectFit:'cover'}}/>
          </div>

          <div style={{backgroundColor:'#f1e2c4',display:"flex",flex:2,padding:50,maxWidth:1000}} id="home3">
             
              <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <img src={logo1} style={{width:100,marginBottom:50}}/>
 
                <form style={{maxWidth:300}}>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label" style={{width:'100%',textAlign:'-webkit-center'}}>Recuperar acesso</label>
                    <label for="exampleInputEmail1" class="form-label" style={{width:'100%',textAlign:'-webkit-center',fontSize:12,color:'#606060',marginBottom:30}}>Informe os seguintes dados e clique no botão enviar para que seja enviada o passo a passo, via e-mail.</label>

                   </div>

                  
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Usuário</label>
                    <input type="text" class="form-control" id="email_id"/>
                  </div>

                  <label for="exampleInputEmail1" class="form-label" style={{width:'100%',textAlign:'-webkit-center'}}>

<img src={grupo667} style={{height:15,marginBottom:20,marginTop:30}} ></img>

</label>
                  
                  <div class="d-grid gap-2" style={{textAlign:'-webkit-center'}}>
                    {loading==false&&(<button class="btn btn-primary" type="button" style={{height:50,backgroundColor:'#f1e2c4',borderColor:'#262626',color:'#262626',marginTop:40}}onClick={()=>clickEnviar()}>Enviar</button>)}
                    <a href="./" style={{color:'#262626'}}>Voltar</a>
                  </div>

                


                </form>


              </div>
         
          </div>
      
    </div>
  );
};
}
// DESKTOP SCREEN

export default EsqueciSenha;
