import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
 
import { useEffect } from "react";
import more from './assets/more.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faShoppingBag,faChartPie,faBox,faCircleDollarToSlot,faBullhorn,faQuestionCircle,faUsers,faGear,faSquareXmark,faMobile ,faCalendar,faChevronDown,faCircleCheck,faEllipsisVertical} from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import xuliz_parceiros_icon from './assets/xuliz_parceiros.png'; // Tell webpack this JS file uses this image
import xuliz_logo2 from './assets/xuliz_logo2.png'; // Tell webpack this JS file uses this image



import home_icon from './assets/home_icon.png'; // Tell webpack this JS file uses this image
import finance_icon from './assets/finance_icon.png'; // Tell webpack this JS file uses this image
import loja_icon from './assets/loja_icon.png'; // Tell webpack this JS file uses this image
import relatorios_icon from './assets/relatorio_icon.png'; // Tell webpack this JS file uses this image
import produtos_icon from './assets/produtos_icon.png'; // Tell webpack this JS file uses this image
import saque_icon from './assets/saque_icon.png'; // Tell webpack this JS file uses this image
import marketing_icon from './assets/marketing_icon.png'; // Tell webpack this JS file uses this image

import central_contas_icon from './assets/contas.png'; // Tell webpack this JS file uses this image
import central_ajuda from './assets/central_ajuda.png'; // Tell webpack this JS file uses this image
import configuracoes from './assets/configuracoes.png'; // Tell webpack this JS file uses this image
import sair_button from './assets/sair_button.png'; // Tell webpack this JS file uses this image
 
import ambiente_seguro from './assets/ambiente_seguro.png'; // Tell webpack this JS file uses this image

import frame5428 from './assets/frame5428.png'; // Tell webpack this JS file uses this image
import Frame_54282 from './assets/Frame_54282.png'; // Tell webpack this JS file uses this image
import bancario_dados from './assets/bancario_dados.png'; // Tell webpack this JS file uses this image
import frame_privacidade from './assets/frame_privacidade.png'; // Tell webpack this JS file uses this image
import sobre_xuliz from './assets/sobre_xuliz.png'; // Tell webpack this JS file uses this image









import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from 'xlsx';
import { Button } from "react-bootstrap";


import { saveAs } from 'file-saver';


import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
 
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Configuracoes = () => {
  const navigate = useNavigate();

  const [emailRef, setEmailRef] = useState('');
  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [dadosX, setDadosX] = useState([]);
  const [rows, setRow] = useState([]);
  const [tokenEsta, setTokenEstabelecimento] = useState('');

  const [quantidadeVendas, setQuantidadeVendas] = useState('0');
  const [valorTotal, setValorTotal] = useState('0');
  const [valorLiquido, setValorLiquido] = useState('0');

  const [nomeCompleto, setNomeCompleto] = useState('-');
  const [emailEditar, setEmailEditar] = useState('-');
  const [celular, setCelular] = useState('-');

   const [urlLogo, setUrlLogo] = useState('');

   const [dataAbertura,setDataAbertura] = useState('')


  const [cpf, setCPF] = useState('-');
  const [dataNascimento, setDataNascimento] = useState('-');
 
  
  

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const [vendaInicio, setVendaInicio] = useState('');
  const [vendaFinal, setVendaFinal] = useState('');

  const [usuario, setUsuario] = useState('');
  const [parte, setParte] = useState('dados_cadastrais'); //dados_cadastrais

  const Swal = require('sweetalert2')

    
  useEffect(() => {
    getDados()
  }, []);
   
 

  const getDados = async()=>{
    var dados = await localStorage.getItem('login')

 
    if(dados == null){    
      return false;
    }


    dados = JSON.parse(dados)
    var dadosX = dados;

    console.log('dadosX',dadosX)
    setNomeCompleto(dadosX.nome)
    setEmailEditar(dadosX.email)
    setCelular(dadosX.celular)
    setDataAbertura(dadosX.dataabertura)

    setEmailRef(dadosX.email)

      setUsuario(dadosX.empresa)
      setCPF(dadosX.cpf)
      setDataNascimento(dadosX.datanascimento)
    //   setUsuario(dadosX.usuario)
      setDadosX(dadosX)
      setUrlLogo(dadosX.urllogo)

 }

 const sair = ()=>{
    localStorage.clear()
    navigate("/");
 }
  
const MenuDesktop = ()=>{

    return (
        <>
    
        <div style={{height:100,width:'100%',display:'flex',padding:20,backgroundColor:'#262626'}}>
            <div style={{width:'100%',display:'flex',flex:1,justifyContent:'center'}}>
                <img src={xuliz_parceiros_icon} style={{width:'100%',maxWidth:200}}/>
            </div>
    
            <div style={{width:'100%',display:'flex',flex:3}}>
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
                    <img src={home_icon} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'gray'}}>Home</span>
                </div>
    
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
                    <img src={finance_icon} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'gray'}}>Recebiveis</span>
                </div>
    
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
                    <img src={loja_icon} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'gray'}}>XLoja</span>
                </div>
    
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
                    <img src={relatorios_icon} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'gray'}}>Relátorios</span>
                </div>
    
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
                    <img src={produtos_icon} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'gray'}}>Produtos</span>
                </div>
    
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
                    <img src={saque_icon} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'gray'}}>Saque</span>
                </div>
    
    
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
                    <img src={marketing_icon} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'gray'}}>Marketing</span>
                </div>
            </div>
    
            <div style={{width:'100%',display:'flex',flex:1.7,marginLeft:30}}>
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
                    <img src={central_contas_icon} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'#f2e2c4'}}>Contas</span>
                </div>
    
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
                    <img src={central_ajuda} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'#f2e2c4'}}>Central de ajuda</span>
                </div>
    
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}}>
                    <img src={configuracoes} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'#f2e2c4'}}>Configurações</span>
                </div>
    
                <div style={{width:'100%',flex:1,justifyContent:'center',display:'flex',alignItems:'center'}} onClick={()=>{sair()}}>
                    <img src={sair_button} style={{width:13,height:13,marginRight:5}}/>
                    <span style={{fontSize:10,color:'#f2e2c4'}}>Sair</span>
                </div>
            </div>
    
            <div style={{width:'100%',display:'flex',flex:1}}>
                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                    <img src={urlLogo} style={{width:50,height:50,borderRadius:25}}/>
    
                </div>
    
                <div style={{display:'flex',flex:1,flexDirection:'column',justifyContent:'center',marginLeft:10}}>
                    <span style={{fontSize:10,fontWeight:'bold',color:'white'}}>{usuario}</span>
                    <span style={{fontSize:10,color:'white'}}>{emailRef}</span>
    
                </div>
            </div>
        </div>
         
        </>
    
      )

}

const SubMenuDesktop = ()=>{
    return(
        <>
            <div style={{height:40,backgroundColor:'#3d3d3d',width:'100%',display:'flex'}}>
                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',borderBottom: '2px solid #f2e2c4'}}>
                    <span style={{fontSize:10,color:'white'}}>Situação cadastral</span>
                </div>

                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                    <span style={{fontSize:10,color:'white'}}>Informações de login</span>
                </div>

                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                    <span style={{fontSize:10,color:'white'}}>Dados cadastrais</span>
                </div>

                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                    <span style={{fontSize:10,color:'white'}}>Dados de contato</span>
                </div>

                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                    <span style={{fontSize:10,color:'white'}}>Dados bancários</span>
                </div>

                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                    <span style={{fontSize:10,color:'white'}}>Meu plano</span>
                </div>

                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                    <span style={{fontSize:10,color:'white'}}>Contas</span>
                </div>

                
            </div>
        </>
    )
}

const StatusConta = ()=>{
    return(
        <>
            <div style={{display:'flex',flex:1,height:200,marginLeft:30,marginRight:30,marginTop:30}}>
                    <div style={{flex:4,flexDirection:'column',display:'flex'}}>
                        <span style={{fontSize:20,fontWeight:'bold'}}>Olá, {nomeCompleto}</span>
                        <span style={{fontSize:12,marginTop:10}}>Conta criada no dia <span style={{fontWeight:'bold'}}>{dataAbertura}</span></span>
                        
                        <div style={{backgroundColor:'#e17e36',width:200,height:35,marginTop:20,borderRadius:5,justifyContent:'center',alignItems:'center',display:'flex'}}>
                            <span style={{color:'white'}}>Conta em análise</span>
                        </div>
                    </div>

                    <div style={{flex:1,display:'flex'}}>
                    <img src={ambiente_seguro} style={{width:130,height:20}}/>
                    
                    </div>


                   
            </div>

            <div style={{marginLeft:30,marginRight:30}}>
                        <span style={{fontSize:20,fontWeight:'bold'}}>Itens necessários para liberação de contas</span>
                        <p style={{fontSize:14,marginTop:10}}>É necessário colocar todos os dados solicitados abaixo para a liberação total da conta.</p>
                    
            </div>

            <div style={{display:'flex'}}>

        
                    <div style={{marginLeft:30,marginRight:30,marginTop:20}}>
                            <div style={{width:170,height:180,backgroundColor:'#fcf2ea',borderRadius:20,display:'flex',padding:30,flexDirection:'column',justifyContent:'center'}}>
                                <img src={frame5428} style={{width:60,height:60}}></img>
                                <p style={{color:'#e17e36',fontSize:20,marginTop:20}}>Dados cadastrais</p>
                            </div>
                    </div>

                    <div style={{marginRight:30,marginTop:20}}>
                            <div style={{width:170,height:180,backgroundColor:'#fcf2ea',borderRadius:20,display:'flex',padding:30,flexDirection:'column',justifyContent:'center'}}>
                                <img src={Frame_54282} style={{width:60,height:60}}></img>
                                <p style={{color:'#e17e36',fontSize:20,marginTop:20}}>Dados de contato</p>
                            </div>
                    </div>
            </div>


            <div style={{marginLeft:30,marginRight:30,marginTop:20}}>
                       <div style={{width:170,height:180,backgroundColor:'#fcf2ea',borderRadius:20,display:'flex',padding:30,flexDirection:'column',justifyContent:'center'}}>
                        <img src={bancario_dados} style={{width:60,height:60}}></img>
                        <p style={{color:'#e17e36',fontSize:20,marginTop:20}}>Dados bancários</p>
                       </div>
            </div>


            <div style={{marginLeft:30,marginRight:30,marginTop:20}}>
                        <span style={{fontSize:20,fontWeight:'bold'}}>Configurações</span>
                    
            </div>


            <div style={{display:'flex'}}>

        
                    <div style={{marginLeft:30,marginRight:30,marginTop:20}}>
                            <div style={{width:170,height:180,backgroundColor:'#f1f1f1',borderRadius:20,display:'flex',padding:30,flexDirection:'column',justifyContent:'center'}}>
                                <img src={frame_privacidade} style={{width:60,height:60}}></img>
                                <p style={{fontSize:20,marginTop:20}}>Privacidade e uso</p>
                            </div>
                    </div>

                    <div style={{marginRight:30,marginTop:20}}>
                            <div style={{width:170,height:180,backgroundColor:'#f1f1f1',borderRadius:20,display:'flex',padding:30,flexDirection:'column',justifyContent:'center'}}>
                                <img src={sobre_xuliz} style={{width:60,height:60}}></img>
                                <p style={{fontSize:20,marginTop:20}}>Sobre o Xuliz</p>
                            </div>
                    </div>
            </div>



        </>
    )
}

 
   
  return(
    <>
        <MenuDesktop/>
        <SubMenuDesktop/>
        <StatusConta/>
    </>
  )
 
};

export default Configuracoes;
