import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import bag from "../assets/bag.png"
import bag1 from "../assets/bag1.png"
import recebiveis from "../assets/recebiveis.png"
import recebiveis1 from "../assets/recebiveis1.png"
import marketing from "../assets/marketing.png"
import marketing1 from "../assets/marketing1.png"
import configuracoes from "../assets/config.png"
import sair from "../assets/sair.png"
 
import { useEffect } from "react";
import logo_parceiros from '../assets/grupo677.png'; // Tell webpack this JS file uses this image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faShoppingBag,faChartPie,faBox,faCircleDollarToSlot,faBullhorn,faQuestionCircle,faUsers,faGear,faSquareXmark,faDollar } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
 
const MenuMobile = ({page})=>{
    const navigate = useNavigate();
    const Swal = require('sweetalert2')

    const [pageRef, setPageRef] = useState(page);
    const [emailRef, setEmailRef] = useState('');
    const [usuario, setUsuario] = useState('');
    const [urlLogo, setUrlLogo] = useState('');

    
    

    const onGroupContainer13Click = useCallback(() => {

        localStorage.clear()
        navigate("/");
      }, [navigate]);

    useEffect(() => {
       
        getDados()
         
     }, []);


     const getDados = async()=>{
        var dados = await localStorage.getItem('login')

     
        if(dados == null){    
          return false;
        }


        dados = JSON.parse(dados)
        var dadosX = dados;
       
         setEmailRef(dadosX.nome)
         setUsuario(dadosX.empresa)
         setUrlLogo(dadosX.urllogo)

         verificarCDL(dadosX)
    }

  

    const verificarCDL =(dadosX)=>{
        var idparceiro = dadosX.parceiros;

        // console.log('idparceiro',idparceiro)

        if(idparceiro == null){
        document.getElementById('menu_marketing').style.display = 'none'
        return false;
        }

        if(idparceiro.length == 0){
            document.getElementById('menu_marketing').style.display = 'none'
        return false;
        }

        if(idparceiro[0] == null){
            document.getElementById('menu_marketing').style.display = 'none'
        return false;
        }
    }


    const irPage = (page)=>{
        location.href = './'+page
    }
    return (
        <>
            <div style={{width:319,backgroundColor:"#262626",display:"flex",flexDirection:'column',padding:"20px 25px 20px 25px",height:'100vh'}}>
                <div style={{display:"flex",width:'100%',marginLeft:38,paddingTop:50}}>
                        <img src={logo_parceiros} style={{width:171,height:28}} />
                </div>

                <div style={{display:"flex",flex:5,width:'100%',flexDirection:'column',marginTop:60,marginLeft:38}}>
                        {/* <div style={{display:"flex",width:'100%',height:50,justifyContent:'left',alignItems:'center',cursor:"pointer"}}>       
                            <FontAwesomeIcon icon={faHouse} style={{fontSize:20,color:pageRef== "home"?"#f1e2c4":'#474444'}} /> <span style={{paddingLeft:10,fontWeight:'bold',color:pageRef== "home"?"#f1e2c4":'#474444'}}>Home</span>      
                         </div> */}
{/* 
                         <div style={{display:"flex",width:'100%',height:35,justifyContent:'left',alignItems:'center',cursor:"pointer"}} onClick={()=>irPage('xloja')}>       
                            <FontAwesomeIcon icon={faShoppingBag} style={{borderLeft:pageRef== "xloja"?"2px solid #f1e2c4":"0px",paddingLeft:13,fontSize:18,color:pageRef== "xloja"?"#f1e2c4":'#474444'}} /> <span style={{paddingLeft:10,fontWeight:'bold',color:pageRef== "xloja"?"#f1e2c4":'#474444'}}>XLoja</span>      
                         </div> */}
                         <div style={{display:"flex",width:'100%',height:15,justifyContent:'left',alignItems:'center',cursor:"pointer",marginBottom:18}} onClick={()=>irPage('xloja')}>       
                            <img src={pageRef=="xloja" ? bag1 : bag} style={{borderLeft:pageRef== "xloja"?"2px solid #f1e2c4":"0px",paddingLeft:13,width:32}} /> <span style={{paddingLeft:10,fontWeight:'600',color:pageRef== "xloja"?"#fff":'#474444'}}>XLoja</span>      
                         </div>

                       

                         {/* <div style={{display:"flex",width:'100%',height:50,justifyContent:'left',alignItems:'center',cursor:"pointer"}}>       
                            <FontAwesomeIcon icon={faBox} style={{fontSize:20,color:pageRef== "produtos"?"#f1e2c4":'#474444'}} /> <span style={{paddingLeft:10,fontWeight:'bold',color:pageRef== "produtos"?"#f1e2c4":'#474444'}}>Produtos</span>      
                         </div> */}

                         {/* <div style={{display:"flex",width:'100%',height:50,justifyContent:'left',alignItems:'center',cursor:"pointer"}}>       
                            <FontAwesomeIcon icon={faCircleDollarToSlot} style={{fontSize:20,color:pageRef== "saque"?"#f1e2c4":'#474444'}} /> <span style={{paddingLeft:10,fontWeight:'bold',color:pageRef== "saque"?"#f1e2c4":'#474444'}}>Saque</span>      
                         </div> */}

                         {/* <div style={{display:"flex",width:'100%',height:35,justifyContent:'left',alignItems:'center',cursor:"pointer"}} onClick={()=>irPage('marketing')} id="menu_marketing">       
                            <FontAwesomeIcon icon={faBullhorn} style={{borderLeft:pageRef== "marketing"?"2px solid #f1e2c4":"0px",paddingLeft:13,fontSize:20,color:pageRef== "marketing"?"#f1e2c4":'#474444'}} /> <span style={{paddingLeft:10,fontWeight:'bold',color:pageRef== "marketing"?"#f1e2c4":'#474444'}}>Marketing</span>      
                         </div> */}

                         <div style={{display:"flex",width:'100%',height:15,justifyContent:'left',alignItems:'center',cursor:"pointer",marginBottom:18}} onClick={()=>irPage('marketing')} id="menu_marketing">       
                            <img src={pageRef=="marketing" ? marketing1 : marketing} style={{borderLeft:pageRef== "marketing"?"2px solid #f1e2c4":"0px",paddingLeft:13,width:32}} /> <span style={{paddingLeft:10,fontWeight:'600',color:pageRef== "marketing"?"#fff":'#474444'}}>Marketing</span>      
                         </div>
                        
                         <div style={{display:"flex",width:'100%',height:18,justifyContent:'left',alignItems:'center',cursor:"pointer",marginBottom:18}} onClick={()=>irPage('recebiveis')} id="menu_recebiveis">       
                            <img src={pageRef=="recebiveis" ? recebiveis1 : recebiveis} style={{borderLeft:pageRef== "recebiveis"?"2px solid #f1e2c4":"0px",paddingLeft:13,width:32}} /> <span style={{paddingLeft:10,fontWeight:'600',color:pageRef== "recebiveis"?"#fff":'#474444'}}>Recebíveis</span>      
                         </div>

                   

                </div>

                

                <div style={{display:"flex",width:205,flexDirection:'column',marginTop:70,marginLeft:38}}>

                        {/* <div style={{display:"flex",width:'100%',height:30,justifyContent:'left',alignItems:'center',cursor:"pointer"}}>       
                            <FontAwesomeIcon icon={faQuestionCircle} style={{fontSize:15,color:"#474444"}} /> <span style={{paddingLeft:10,color:'#474444',fontSize:13}}>Central de ajuda</span>      
                         </div>

                         <div style={{display:"flex",width:'100%',height:30,justifyContent:'left',alignItems:'center',cursor:"pointer"}}>       
                            <FontAwesomeIcon icon={faUsers} style={{fontSize:15,color:"#474444"}} /> <span style={{paddingLeft:10,color:'#474444',fontSize:13}}>Contas</span>      
                         </div> */}

                         {/* <div style={{display:"flex",width:'100%',height:80,justifyContent:'left',alignItems:'center',cursor:"pointer",width:'100%',flexDirection:'column'}}>       
                             <div style={{display:'flex',backgroundColor:'red',flex:1,width:'100%',height:'100%'}}>
                             
                             </div>  

                              <div style={{display:'flex',backgroundColor:'blue',flex:1,width:'100%',height:'100%'}}>
                                <span>Trocar estabelecimenro</span>
                             </div>     
                         </div> */}
                         

                         <div style={{display:"flex",width:'100%',height:15,justifyContent:'left',alignItems:'center',cursor:"pointer",marginBottom:15}} id="menu_configuracoes" onClick={()=>irPage('configuracoes')}>       
                            <img src={configuracoes} style={{width:15,color:"#f1e2c4"}} /> <span style={{paddingLeft:10,color:"#fff",fontSize:12,fontWeight:600}}>Configurações</span>      
                         </div>
                         
                         <div style={{display:"flex",width:'100%',height:15,justifyContent:'left',alignItems:'center',cursor:"pointer",marginBottom:10}}  onClick={onGroupContainer13Click}>       
                            <img src={sair} style={{width:15}} /> <span style={{paddingLeft:10,color:'#fff',fontSize:12,fontWeight:600}}>Sair</span>      
                         </div>

               <div style={{borderTop:"1px solid #3d3d3d",width:190,marginTop:15}}></div>
                </div>

                <div style={{display:"flex",width:'205px',padding:"30px 12px 20px 0px",alignItems:'center',marginLeft:38}}>
                    <div style={{display:"flex",flex:1,width:'100%',borderRadius:50,width:50,height:50}}>
                        <img src={urlLogo} style={{width:"auto",width:60,height:"auto",objectFit:"cover",borderRadius:30}} />
                    </div>

                    <div style={{display:"flex",flex:3,width:'100%',flexDirection:'column',marginLeft:10}}>
                        <p style={{marginBottom:1,fontSize:15,color:'#ffffff',fontWeight:700}}>{usuario}</p>
                        <p style={{marginBottom:1,fontSize:13,color:'#ffffff8f'}}>{emailRef}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MenuMobile;
