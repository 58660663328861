import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";
import bXuliz from "./assets/bXuliz.png";
import "./RecebiveisContestacao.css";

import { useEffect } from "react";
import xloja_12 from './assets/xloja_12.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg'; 
import banco_img from './assets/banco.png'; 


import xloja from './assets/xloja.png'; // Tell webpack this JS file uses this image


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faShoppingBag,faMoneyCheckAlt,faFileAlt,faGavel,faChartPie,faBox,faCircleDollarToSlot,faBullhorn,faQuestionCircle,faUsers,faGear,faSquareXmark,faMobile,faBars,faBookReader } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import axios from "axios";
import logo_parceiros from './assets/grupo677.png'; // Tell webpack this JS file uses this image
 
 import Calendario from "./componentes/Recebiveis/calendario";
 

var vendaPaga = false;
   
const Recebiveis = () => {
  const navigate = useNavigate();

  const Swal = require('sweetalert2')

   
  const events = [{
    id: 1,
    color: '#fd3153',
    from: '2019-05-02T18:00:00+00:00',
    to: '2019-05-05T19:00:00+00:00',
    title: 'This is an event'
}, {
    id: 2,
    color: '#1ccb9e',
    from: '2019-05-01T13:00:00+00:00',
    to: '2019-05-05T14:00:00+00:00',
    title: 'This is another event'
}, {
    id: 3,
    color: '#3694DF',
    from: '2019-05-05T13:00:00+00:00',
    to: '2019-05-05T20:00:00+00:00',
    title: 'This is also another event'
}];

  const [emailRef, setEmailRef] = useState('');
  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [cashbackCliente, setCaschbackCliente] = useState('R$ 0,00');
  const [dadosClinte, setDadosCliente] = useState([]);
  const [dadosX, setDadosX] = useState([]);
  const [primeiroNome, setPrimeiroNome] = useState('');
  const [nomeUser, setNome] = useState('');
  const [rows, setRow] = useState([]);


  const [dadosREF, setDADOSREF] = useState(null);

  const [total_receber, setTotalReceber] = useState('');
  const [geralTaxas, setTotalTaxas] = useState('R$ 0,00');

  

  const now = new Date();
  const [selectedValue, setSelectedValue] = useState(now.getMonth());
  const [ano, setAno] = useState(now.getFullYear());
  const [dataCalendario, setDataCalendario] = useState([]);
  const [geralDebito,setGeralDebito] = useState(0)
  const [geralCredito,setGeralCredito] = useState(0)
  const [geralXuliz,setGeralXuliz] = useState(0)
  const [geralPix,setGeralPix] = useState(0)
  const [geralBruto,setGeralBruto] = useState(0)
  const [totalPedidos,setTotalPedidos] = useState(0)

  const [dataRef,setDataRef] = useState('')

  

  

  

  const [clickEnviou, setClickEnviou] = useState(false);
  const [tokenEsta, setTokenEstabelecimento] = useState('');
  const [idvenda, setIdvenda] = useState('');
  const [isMobile, setIsMobile] = useState(true);
  const [urlLogo, setUrlLogo] = useState('');

  
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };

    
  }, []);



  useEffect(() => {

    getDadosSQL()

  },[]);





  const getDadosSQL = async()=>{

    setRow([])

   

    await  getDados()

    var dados = await localStorage.getItem('login')
    dados = JSON.parse(dados);
    var idestabele = dados.idestabelecimento;




    var getTokenEstabelecimneto = await getTokenEstabelciment(idestabele);
    


    if(getTokenEstabelecimneto == null){
      getTokenEstabelecimneto = dados.tokenestabelecimento;
    }



    var datainicial = await getDataInicial()
    var datafinal = await getDataFinal()

 


   

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: new URLSearchParams({
        idempresa: '1350',
        token: getTokenEstabelecimneto,
        idvendaxuliz: '',
        metodo: 'Lista',
        idestabelecimento: idestabele,
        idvenda: '',
        datainicial: datainicial,
        datafinal: datafinal
      })
    };
    
    fetch('https://xuliz.com.br/painel/integracao/vendaxuliz.php', options)
      .then(response => response.json())
      .then(response => tradarDados(response))
      .catch(err => console.error(err));
  }

  const tradarDados = async(dadosG,newMes)=>{

    setDADOSREF(dadosG)

    var lista = dadosG.lista;

    var mesREF = getCurrentMonthAndYear(newMes);

 
    console.log(mesREF,'mesREF')
     console.log('dados_api',lista);

     var geralLiquido = 0;
     var totalDebitos = 0;
     var totalCredito = 0;
     var totalXuliz  = 0;
     var totalPix= 0;
     var totalTaxas = 0;
     var totalBruto = 0;



 
     var dadosCalendario = []
     //setDataCalendario

     
     for(var x=0;x<lista.length;x++){
      

      if(lista[x].detalhe.length == 0){
        continue;
      }

       var detalhesArra = lista[x].detalhe;

       if(detalhesArra.length == 0){
        continue
       }

       for(var b=0;b<detalhesArra.length;b++){

        var data = detalhesArra[b].datavencimento;
        var dataVencimento = detalhesArra[b].datavencimento;

        
        data = data.split('-');
        var diaRef = data[2];
        data = data[0]+'-'+data[1];


        // console.log(detalhesArra[b].datavencimento)



    
        if(data != mesREF){
          continue
        }

        // console.log(mesREF+'  ||| '+data+' -->'+diaRef)

 

 
          var valorbruto = detalhesArra[b].valorbruto
          valorbruto = parseFloat(valorbruto);

          if(valorbruto == 0){
             valorbruto = detalhesArra[b].valor
              valorbruto = parseFloat(valorbruto);
          }

          totalBruto = totalBruto+valorbruto;

          var valorxuliz = detalhesArra[b].valorxuliz
          valorxuliz = parseFloat(valorxuliz);

          totalTaxas = totalTaxas+valorxuliz;

          var totalLiquido = valorbruto-valorxuliz;
          geralLiquido = geralLiquido+totalLiquido;

          var refBandeira = detalhesArra[b].bandeira;

          // console.log('Bandeira'+refBandeira,totalDebitos+' -- '+valorbruto+'||'+valorxuliz)

          if(refBandeira.includes('Débito')){
            totalDebitos = totalDebitos+totalLiquido
          }

          if(refBandeira.includes('Crédito')){
            totalCredito = totalCredito+totalLiquido;
          }

          if(refBandeira.includes('Xuliz')){
            totalXuliz = totalXuliz+totalLiquido;
          }

          if(refBandeira.includes('Pix')){
            totalPix = totalPix+totalLiquido;
          }


          
          

          // console.log(geralLiquido,totalLiquido+'|| '+data)

          // console.log(dadosCalendario,data)

          dadosCalendario.push({
            idvenda:detalhesArra[b].idvenda,
            liquido:totalLiquido,
            valorbruto:valorbruto,
            valorxuliz:valorxuliz,
            detalhes:detalhesArra[b],
            diaRef:diaRef,
            data:dataVencimento
          })

       }
 


        
     }

     console.log('dadosCalendario',dadosCalendario)
     

     var somaPorDia = {};
     var totalPedidosPorDia = {};

     // Iterar sobre o array de vendas
     dadosCalendario.forEach(venda => {

        // Verificar se a data já está presente no objeto somaPorDia
        if (somaPorDia[venda.data]) {
            // Se sim, adicionar o valor líquido à soma existente para esse dia
            somaPorDia[venda.data] += venda.liquido;
            totalPedidosPorDia[venda.data]++;
         } else {
            // Se não, criar uma entrada para esse dia e atribuir o valor líquido
            somaPorDia[venda.data] = venda.liquido;
            totalPedidosPorDia[venda.data] = 1;
        }
      });

      // Converter o objeto em um array de objetos para o formato desejado
    var resultadoCalendario = Object.keys(somaPorDia).map(data => {

      return { data: data, total: somaPorDia[data],totalPedidos: totalPedidosPorDia[data] };
    });
    

    console.log('resultadoCalendario',resultadoCalendario)

    var total_pedido = 0;

    for(var x=0;x<resultadoCalendario.length;x++){
      var total = resultadoCalendario[x].totalPedidos;

      total_pedido = total_pedido+total

    }


    var primeiroObjeto = resultadoCalendario[0];
    var ultimoObjeto = resultadoCalendario[resultadoCalendario.length - 1];
 
    if(primeiroObjeto != null){
      primeiroObjeto = primeiroObjeto['data']
    }

    if(ultimoObjeto != null){
      ultimoObjeto = ultimoObjeto['data']
    }




    setDataCalendario(resultadoCalendario)
     

     geralLiquido = geralLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalDebitos = totalDebitos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalCredito = totalCredito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalXuliz = totalXuliz.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalPix = totalPix.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalTaxas = totalTaxas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalBruto = totalBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

     setTotalReceber(geralLiquido)
     setGeralDebito(totalDebitos)
     setGeralCredito(totalCredito)
     setGeralXuliz(totalXuliz)
     setGeralPix(totalPix)
     setTotalTaxas(totalTaxas)
     setGeralBruto(totalBruto)
     setTotalPedidos(total_pedido)

 

     primeiroObjeto = primeiroObjeto.split('-');
     primeiroObjeto =  primeiroObjeto[2]+'/'+primeiroObjeto[1]+'/'+primeiroObjeto[0]

     ultimoObjeto = ultimoObjeto.split('-');
     ultimoObjeto =  ultimoObjeto[2]+'/'+ultimoObjeto[1]+'/'+ultimoObjeto[0]

     setDataRef('Data: '+primeiroObjeto+' à '+ultimoObjeto)

  }


  function getCurrentMonthAndYear(newMes) {
    // Cria um objeto de data para o mês atual
    var currentDate = new Date();

    

    var selectedValueGet = document.getElementById('selectInput').value;
    selectedValueGet = parseInt(selectedValue)

    if(newMes != null){
      selectedValueGet = ''+newMes
      selectedValueGet = parseInt(selectedValueGet)
    }
    // Obtém o mês e o ano atual
    var month = selectedValueGet + 1; // Adiciona 1 pois os meses em JavaScript são indexados de 0 a 11
    var year = currentDate.getFullYear();




    // Retorna o mês e o ano formatados
    return `${year}-${(month < 10 ? '0' : '') + month}`;
}



  const getDataFinal = async()=>{
    var data = new Date();
  
  var ano = data.getFullYear();
  var mes = String(data.getMonth() + 1).padStart(2, '0');
  var dia = String(data.getDate()).padStart(2, '0');
  
  return ano + '-' + mes + '-' + dia;
  }

  const getDataInicial = async()=>{
    var data = new Date();
    data.setDate(data.getDate() - 32);
    
    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var dia = String(data.getDate()).padStart(2, '0');
    
    return ano + '-' + mes + '-' + dia;
  }


  const getTokenEstabelciment = async(token)=>{
    const form = new FormData();
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("metodo", "ListaEstabelecimentos");
      
    
    const options = {
      method: 'POST',
    };
    
    options.body = form;
    
   var rres = await  fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options).then(response => response.json()); 
    
   var arrrayEstabelecimnto = rres.estabelecimentos;

   var tokenEstabelecimento = '';
   for(var x=0;x<arrrayEstabelecimnto.length;x++){
    if(arrrayEstabelecimnto[x].idestabelecimento == token){
      tokenEstabelecimento = arrrayEstabelecimnto[x].token;
      break;
    }
   }

  //  console.log(rres)
  //  console.log(tokenEstabelecimento)
   setTokenEstabelecimento(tokenEstabelecimento)
   return tokenEstabelecimento;
 

  }


  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
  
    verificarNiveisLogin(dadosX)
  };

 
  

 

  const getDados = async()=>{
    var dados = await localStorage.getItem('login')

    if(dados == null){
      navigate("/");

      return false;
    }
    dados = JSON.parse(dados)
     setDadosX(dados)

     setUrlLogo(dados.urllogo)
 
     console.log('dadosX',dados)

    setEmailRef(dados.email)
     console.log(dados)

      
  
     
  }


  const verificarNiveisLogin = async ()=>{
    var idperfil = await localStorage.getItem('tipoPerfil');
    
      
    if(idperfil == "7"){
        //financeiro somente relatorios
        location.href = "./minhasvendas";
        
    }

}
  
 
  


  

   

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
 

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    tradarDados(dadosREF,event.target.value)
};

const Detalhes = ({minWidth})=>{

  return(
    <>
    <div style={{display:'flex',padding:10,flexDirection:'column'}}>
            
            <div id="blockUser" style={{flexDirection:'column',display:"flex",height:"100%",padding:5,flex:1,minWidth:minWidth,padding:20}}>
                      
                      <span style={{fontFamily:'Montserrat',fontSize:20,color:'#262626',fontWeight:'bold'}}>Detalhamento do calendário</span>
                      <span style={{fontFamily:'Montserrat',fontSize:12,backgroundColor:'#F2E2C4',width:200,padding:5,borderRadius:5,marginTop:10}}>{dataRef}</span>
  
  
                      <div style={{backgroundColor:'#F1F1F1',display:'flex',height:700,marginTop:20,borderRadius:10,flexDirection:'column',flex:1}}>
                          <div style={{display:'flex',flex:1}}>
                                
                                <div style={{flex:1,justifyContent:'center',display:'flex',flexDirection:'column',padding:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:17,color:'#999999',fontWeight:'bold'}}>Vendas no total</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#999999',fontWeight:'bold'}}>{totalPedidos} vendas</span>
  
                                </div>
  
                                <div style={{flex:1,justifyContent:'center',display:'flex',flexDirection:'column',padding:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:17,color:'#999999',fontWeight:'bold'}}>{geralBruto}</span>
                                </div>
                          </div>
  
                          <div style={{display:'flex',flex:6,borderTop:"4px solid #3D3D3D",padding:20,flexDirection:'column'}}>
                              
                              <span style={{fontFamily:'Montserrat',fontSize:17,color:'#262626',fontWeight:'bold'}}>Formas de pagamento</span>
  
  
                                <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Crédito</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralCredito}</span>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
  
                                <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Débito</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralDebito}</span>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
                               
  
  
                                <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Pix</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralPix}</span>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
                               
  
  
                                <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Saldo Xuliz</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralXuliz}</span>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
                               
  
                                <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Saldo Xuliz + Crédito</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralXuliz}</span>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
                               
  
                               <div style={{width:'100%',height:150,textAlign:'-webkit-right',marginTop:20,placeContent:'center',flexDirection:'column',display:'flex'}}>
                                    
                                    <span style={{fontFamily:'Montserrat',fontSize:15,color:'#999999'}}>Taxa/tarifa paga:<span style={{color:'red'}}>  - {geralTaxas}</span></span>
                                    <span style={{fontFamily:'Montserrat',fontSize:15,color:'#999999',marginTop:10}}>Cashback: <span style={{color:'#279C33'}}> {geralXuliz} </span></span>
  
                                    <span style={{fontFamily:'Montserrat',fontSize:20,color:'#999999',marginTop:50}}>Total líquido: <span style={{color:'black'}}>  {total_receber}</span></span>
  
  
                               </div>
  
  
  
                          </div>
                      </div>
  
  
  
                
  
                          {/* CODE */}
                      
            </div>  
            </div>
    
    </>
  )
}



  if(isMobile == true){
      return(
      <>
        <MenuMobile page="recebiveis"/>

        <div style={{height:'100vh',display:"flex",width:"100%",overflow:'auto',flex:1,padding:20}}>
        <div className="container-principal-mobile" style={{width:'100%',  padding:'13px'}}>
          <div style={{ borderBottom:'solid 1.5px #999'}}>
            <div style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:' center'}}>
              <div className="contestacao" style={{ display:'inline-block'}}>
                <div style={{display: 'flex', cursor:'pointer'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"  viewBox="0 0 16 16" fill="none">
                  <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p style={{color:'#999',fontFamily:'Montserrat', fontSize:'13px', fontWeight:'600', marginLeft:'10px'}}>Voltar</p>
                </div>
                <div>
                  <p style={{fontFamily:'Montserrat', fontSize:'24px', fontWeight:'600'}}>Contestação de vendas realizadas</p>
                  <div style={{display:'inline-flex',alignItems:'center', background:'#F2E2C4', borderRadius: '5px', padding:'5px', justifyContent:'center'}}><p style={{margin:'0px', marginRight:'5px', fontFamily:'Montserrat', fontSize:'10px', fontWeight:'600'}}>Recebíveis</p> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 4 7" fill="none">
                  <path d="M1 6L3.14286 3.5L1 1" stroke="#3D3D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p style={{margin:'0px', marginLeft:'5px', fontFamily:'Montserrat', fontSize:'10px', fontWeight:'400'}}>Contestação</p></div>  
                </div>             
              </div>
              <div style={{display:'inline-flex',
              alignItems:'center', height:'40px',}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 17" fill="none">
                <path d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z" fill="#262626"/>
                <path d="M6.3175 6.25C6.49383 5.74875 6.84186 5.32608 7.29997 5.05685C7.75807 4.78762 8.29667 4.6892 8.82038 4.77903C9.34409 4.86886 9.81911 5.14114 10.1613 5.54765C10.5035 5.95415 10.6908 6.46864 10.69 7C10.69 8.5 8.44 9.25 8.44 9.25" fill="#262626"/>
                <path d="M6.3175 6.25C6.49383 5.74875 6.84186 5.32608 7.29997 5.05685C7.75807 4.78762 8.29667 4.6892 8.82038 4.77903C9.34409 4.86886 9.81911 5.14114 10.1613 5.54765C10.5035 5.95415 10.6908 6.46864 10.69 7C10.69 8.5 8.44 9.25 8.44 9.25M8.5 12.25H8.5075M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z" stroke="#F1F1F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg><a href="#" style={{textDecoration:'none', color:'#262626'}}>
                  <p style={{margin:'0px', marginLeft:'5px', fontFamily:'Montserrat', fontSize:'8px', fontWeight:'600', whiteSpace:'nowrap'}}>Central de ajuda</p>
                </a>
              </div>
            </div>
            <div className="container-valores" style={{display:'block', alignItems:'center', width:'700px'}}>
              <div style={{display:'flex'}}>
                <div className="valor-total" style={{paddingTop: '20px',paddingBottom: '20px', marginRight:'15px', width:'270px'}}>
                  <div style={{display:'flex'}}>
                    <div style={{backgroundColor:'#3D3D3D', padding:'12px 25px', borderRadius:'5px 0px 0px 5px', cursor:'pointer'}}><p style={{margin:'0', color:'#F2E2C4', fontFamily:'Montserrat', fontSize:'13px', fontWeight:'400'}}>A ser tratada</p></div>
                    <div style={{backgroundColor:'#F1F1F1', padding:'12px 41px', borderRadius:'0px 5px 5px 0px', cursor:'pointer'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'13px', fontWeight:'400'}}>Tratadas</p></div>
                  </div>
                  <input style={{width:'100%', padding:'8px 18px', borderRadius:'5px', border:'solid 1px #999', marginTop:'10px', cursor:'pointer'}} type="date" placeholder="Periodo"></input>
                </div>
                <div className="valor-total" style={{ paddingTop: '20px',paddingBottom: '20px', marginRight:'15px'}}>
                  <div style={{ background:'#F1F1F1', display:'flex', alignItems:'center', justifyContent:'space-between',  borderRadius:'10px', padding:'16px 45px'}}>
                    <div style={{ margin:'10px', marginRight:'45px', display:'flex', flexDirection:'column'}}>
                      <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'10px', fontWeight:'400px'}}>Valor total</p>
                      <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'15px', fontWeight:'600'}}>contestado</p>
                    </div>
                    <div style={{ display:'flex', alignItems:'center', margin:'10px',}}>
                      <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'30px', marginRight:'5px', color:'#3D3D3D'}}>R$</p>
                      <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'30px', fontWeight:'600', color:'#3D3D3D'}}>127,00</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="valor-total"  style={{ paddingTop: '20px',paddingBottom: '20px'}}>
                <div style={{marginBottom:'10px'}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'10px', fontWeight:'400', color:'#999'}}>Você pode aceitar ou recusar as contestações que estão pendentes, ou acompanhar <br/> a linha do tempo das contestações que já foram tratadas.</p>
                </div>
                <div style={{display:'flex', alignItems:'center', backgroundColor:'#F1F1F1', padding:'10px 15px', width:'675px'}}>
                  <div style={{marginRight:'5px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" fill="none">
                    <path d="M15 28.126C7.76254 28.126 1.875 22.2384 1.875 15.001C1.875 7.76353 7.76254 1.875 15 1.875C22.2375 1.875 28.125 7.76354 28.125 15C28.125 22.2365 22.2375 28.126 15 28.126ZM15 3.28013C8.53737 3.28013 3.28013 8.53736 3.28013 15C3.28013 21.4627 8.53737 26.7199 15 26.7199C21.4627 26.7199 26.7199 21.4627 26.7199 15C26.7199 8.53736 21.4627 3.28013 15 3.28013Z" fill="#3D3D3D"/>
                    <path d="M14.9994 20.7755C14.611 20.7755 14.2969 20.4614 14.2969 20.073V14.4384C14.2969 14.05 14.611 13.7358 14.9994 13.7358C15.3878 13.7358 15.702 14.05 15.702 14.4384V20.073C15.702 20.4604 15.3878 20.7755 14.9994 20.7755Z" fill="#999999"/>
                    <path d="M15.0008 12.5398C15.7575 12.5398 16.3709 11.9264 16.3709 11.1699C16.3709 10.4132 15.7575 9.7998 15.0008 9.7998C14.2442 9.7998 13.6309 10.4132 13.6309 11.1699C13.6309 11.9264 14.2442 12.5398 15.0008 12.5398Z" fill="#999999"/>
                    </svg>
                  </div>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'10px', fontWeight:'400', color:'#3D3D3D'}}>Contestações que não tiverem ação até o prazo de expiração, serão aceitas <br/> automaticamente.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-lancamentos" style={{ display:'flex'}}>
            <div style={{display:'inline-block', width:'100%'}}>
              <div  style={{background:'#F1F1F1', borderRadius:'10px', marginTop:'40px',
              marginBottom:'20px',  height:'840px', width:'100%'}}>
                <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center', borderBottom:'solid 5px #3D3D3D', padding: '38px 308px 38px 20px'}}>
                  <p className="display-p" style={{whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'15px', fontWeight:'600', margin:'0', marginRight:'46%'}}>Contestações a serem tratadas</p>
                  <div className="display-contestacao" style={{background:'#3D3D3D', padding:'8px 40px 8px 10px', borderRadius:'5px', display:'flex', alignItems:'center'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M11 11L7.66667 7.66667M8.77778 4.88889C8.77778 7.03666 7.03666 8.77778 4.88889 8.77778C2.74111 8.77778 1 7.03666 1 4.88889C1 2.74111 2.74111 1 4.88889 1C7.03666 1 8.77778 2.74111 8.77778 4.88889Z" stroke="#F2E2C4" stroke-width="1.77724" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <input class="custom-input" style={{border:'none', background:'#3D3D3D', outline: 'none', marginLeft:'5px'}} type="text" placeholder="Procurar valor da contestação"></input>
                  </div>
                </div>
                <div style={{margin:'10px', height:'600px'}}>

                  <div style={{overflowX:'scroll'}}>
                    <div style={{borderBottom:'solid 1px #999', margin: '10px', width:'1200px'}}>
                      <div style={{ marginTop:'40px', marginBottom:'10px'}}>
                        <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                          <p style={{marginRight:'53px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Prazo de <br/>
                          expiração</p>
                          <p style={{marginRight:'28px', whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Data de <br/>
                          contestação</p>
                          <p style={{marginRight:'65px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Protocolo</p>
                          <p style={{marginRight:'42px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', whiteSpace: 'nowrap'}}>Bandeira</p>
                          <p style={{marginRight:'31px', whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Valor da <br/>
                          contestação</p>
                          <p style={{marginRight:'72px',whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Valor <br/>
                          líquido</p>
                          <p style={{whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Tomada <br/>
                          de decisão</p>
                        </div>
                      </div>
                    </div>
                    <div style={{borderBottom:'solid 1px #999', margin: '10px', width:'1200px'}}>
                      <div style={{marginBottom:'10px'}}>
                        <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                          <div style={{ marginLeft:'50px'}}>
                            <p style={{ fontSize:'12px', fontWeight:'300', color:'#3D3D3D', margin: '0' }}>16/12/2022</p>
                          </div>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'45px', whiteSpace: 'nowrap'}}>0000003565</p>
                          <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'50px'}}><img style={{width:'36px', height:'19px'}} src={bXuliz}/></div>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'50px', whiteSpace: 'nowrap'}}>R$ 150,00</p>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'50px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 148,00</p>
                          <div style={{ display:'flex', alignItems:'center', marginLeft:'40px',}}>
                            <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Acatar</p></div>
                            <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Defender</p></div>
                            <div style={{padding:'6px 35px', margin:'10px', border:'2px solid #3D3D3D', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', margin: '0', }}>Exportar</p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{borderBottom:'solid 1px #999', margin: '10px', width:'1200px'}}>
                      <div style={{ marginBottom:'10px'}}>
                      <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                          <div style={{ marginLeft:'50px'}}>
                            <p style={{ fontSize:'12px', fontWeight:'300', color:'#3D3D3D', margin: '0' }}>16/12/2022</p>
                          </div>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'45px', whiteSpace: 'nowrap'}}>0000003565</p>
                          <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'50px'}}><img style={{width:'36px', height:'19px'}} src={bXuliz}/></div>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'50px', whiteSpace: 'nowrap'}}>R$ 150,00</p>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'50px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 148,00</p>
                          <div style={{ display:'flex', alignItems:'center', marginLeft:'40px',}}>
                            <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Acatar</p></div>
                            <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Defender</p></div>
                            <div style={{padding:'6px 35px', margin:'10px', border:'2px solid #3D3D3D', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', margin: '0', }}>Exportar</p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{borderBottom:'solid 1px #999', margin: '10px', width:'1200px'}}>
                      <div style={{marginBottom:'10px'}}>
                      <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                          <div style={{ marginLeft:'50px'}}>
                            <p style={{ fontSize:'12px', fontWeight:'300', color:'#3D3D3D', margin: '0' }}>16/12/2022</p>
                          </div>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'45px', whiteSpace: 'nowrap'}}>0000003565</p>
                          <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'50px'}}><img style={{width:'36px', height:'19px'}} src={bXuliz}/></div>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'50px', whiteSpace: 'nowrap'}}>R$ 150,00</p>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'50px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 148,00</p>
                          <div style={{ display:'flex', alignItems:'center', marginLeft:'40px',}}>
                            <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Acatar</p></div>
                            <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Defender</p></div>
                            <div style={{padding:'6px 35px', margin:'10px', border:'2px solid #3D3D3D', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', margin: '0', }}>Exportar</p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{borderBottom:'solid 1px #999', margin: '10px', width:'1200px'}}>
                      <div style={{marginBottom:'10px'}}>
                      <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                          <div style={{ marginLeft:'50px'}}>
                            <p style={{ fontSize:'12px', fontWeight:'300', color:'#3D3D3D', margin: '0' }}>16/12/2022</p>
                          </div>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'45px', whiteSpace: 'nowrap'}}>0000003565</p>
                          <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'50px'}}><img style={{width:'36px', height:'19px'}} src={bXuliz}/></div>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'50px', whiteSpace: 'nowrap'}}>R$ 150,00</p>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'50px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 148,00</p>
                          <div style={{ display:'flex', alignItems:'center', marginLeft:'40px',}}>
                            <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Acatar</p></div>
                            <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Defender</p></div>
                            <div style={{padding:'6px 35px', margin:'10px', border:'2px solid #3D3D3D', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', margin: '0', }}>Exportar</p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{borderBottom:'solid 1px #999', margin: '10px', width:'1200px'}}>
                      <div style={{marginBottom:'10px'}}>
                      <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                          <div style={{ marginLeft:'50px'}}>
                            <p style={{ fontSize:'12px', fontWeight:'300', color:'#3D3D3D', margin: '0' }}>16/12/2022</p>
                          </div>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'45px', whiteSpace: 'nowrap'}}>0000003565</p>
                          <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'50px'}}><img style={{width:'36px', height:'19px'}} src={bXuliz}/></div>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'50px', whiteSpace: 'nowrap'}}>R$ 150,00</p>
                          <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'50px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 148,00</p>
                          <div style={{ display:'flex', alignItems:'center', marginLeft:'40px',}}>
                            <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Acatar</p></div>
                            <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Defender</p></div>
                            <div style={{padding:'6px 35px', margin:'10px', border:'2px solid #3D3D3D', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', margin: '0', }}>Exportar</p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop:'50px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div style={{paddingLeft:'19px', display:'inline-flex', paddingTop:'13px'}}>
                      <p style={{fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400', color:'#999', margin: '0',}}>0-6 de 50 recebíveis</p>
                    </div>

                    <div style={{ paddingRight:'19px', display:'inline-flex'}}>
                      <button style={{background:'#FFF', padding:'3px', border:'1px solid #0006241A', borderRadius:'5px', marginRight:'12px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 18 18" fill="none">
                        <path d="M11.1447 6.10886C11.2912 6.2553 11.2912 6.49274 11.1447 6.63919L8.41027 9.37501L11.1447 12.11C11.2912 12.2564 11.2912 12.4938 11.1447 12.6403C10.9983 12.7867 10.7608 12.7867 10.6144 12.6403L7.34961 9.37501L10.6144 6.10886C10.7608 5.96241 10.9983 5.96241 11.1447 6.10886Z" fill="#000624" fill-opacity="0.3"/>
                        </svg>
                      </button>

                      <div style={{background:'#3D3D3D', borderRadius:'5px', width:'33px', height:'30px', display:'flex', justifyContent:'center', alignItems:'center', marginRight:'8px'}}>
                        <p style={{paddingTop:'15px', color:'#FFF'}}>1</p>
                      </div>
                      
                      <div style={{background:'#FFF', borderRadius:'5px', width:'33px', height:'30px', display:'flex', justifyContent:'center', alignItems:'center', border:'solid 1px #3D3D3D'}}>
                        <p style={{paddingTop:'15px', color:'#3D3D3D'}}>2</p>
                      </div>

                      <button style={{background:'#FFF', padding:'3px', border:'1px solid #3D3D3D', borderRadius:'5px', marginLeft:'12px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 18 18" fill="none">
                        <path d="M6.85528 11.8911C6.70883 11.7447 6.70883 11.5073 6.85528 11.3608L9.58973 8.62499L6.85528 5.89004C6.70883 5.7436 6.70883 5.50616 6.85528 5.35971C7.00173 5.21327 7.23916 5.21327 7.38561 5.35971L10.6504 8.62499L7.38561 11.8911C7.23916 12.0376 7.00173 12.0376 6.85528 11.8911Z" fill="#000624" fill-opacity="0.3"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        
        
      </>
      )
  }



 return(
  <>

  <div style={{height:'100vh',display:"flex", overflow:'auto'}}>
    <div style={{backgroundColor:'#262626'}}>
        <Menu page="recebiveis"/>
    </div>
    

    <div style={{height:'100vh',display:"flex",width:"100%",overflow:'auto',flex:1,padding:20,}}>

      <div className="container-principal" style={{width:'100%',  padding:'13px'}}>
          <div style={{ borderBottom:'solid 1.5px #999'}}>
            <div style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:' center'}}>
              <div className="contestacao" style={{ display:'inline-block'}}>
                <div style={{display: 'flex', cursor:'pointer'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"  viewBox="0 0 16 16" fill="none">
                  <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#999999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p style={{color:'#999',fontFamily:'Montserrat', fontSize:'13px', fontWeight:'600', marginLeft:'10px'}}>Voltar</p>
                </div>
                <div>
                  <p style={{fontFamily:'Montserrat', fontSize:'24px', fontWeight:'600'}}>Contestação de vendas realizadas</p>
                  <div style={{display:'inline-flex',alignItems:'center', background:'#F2E2C4', borderRadius: '5px', padding:'5px', justifyContent:'center'}}><p style={{margin:'0px', marginRight:'5px', fontFamily:'Montserrat', fontSize:'10px', fontWeight:'600'}}>Recebíveis</p> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 4 7" fill="none">
                  <path d="M1 6L3.14286 3.5L1 1" stroke="#3D3D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p style={{margin:'0px', marginLeft:'5px', fontFamily:'Montserrat', fontSize:'10px', fontWeight:'400'}}>Contestação</p></div>  
                </div>             
              </div>
              <div style={{display:'inline-flex',
              alignItems:'center', height:'40px',}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 17" fill="none">
                <path d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z" fill="#262626"/>
                <path d="M6.3175 6.25C6.49383 5.74875 6.84186 5.32608 7.29997 5.05685C7.75807 4.78762 8.29667 4.6892 8.82038 4.77903C9.34409 4.86886 9.81911 5.14114 10.1613 5.54765C10.5035 5.95415 10.6908 6.46864 10.69 7C10.69 8.5 8.44 9.25 8.44 9.25" fill="#262626"/>
                <path d="M6.3175 6.25C6.49383 5.74875 6.84186 5.32608 7.29997 5.05685C7.75807 4.78762 8.29667 4.6892 8.82038 4.77903C9.34409 4.86886 9.81911 5.14114 10.1613 5.54765C10.5035 5.95415 10.6908 6.46864 10.69 7C10.69 8.5 8.44 9.25 8.44 9.25M8.5 12.25H8.5075M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z" stroke="#F1F1F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg><a href="#" style={{textDecoration:'none', color:'#262626'}}>
                  <p style={{margin:'0px', marginLeft:'5px', fontFamily:'Montserrat', fontSize:'10px', fontWeight:'600'}}>Central de ajuda</p>
                </a>
              </div>
            </div>
            <div className="container-valores" style={{display:'flex', alignItems:'center'}}>
              <div className="valor-total" style={{paddingTop: '20px',paddingBottom: '20px', marginRight:'15px'}}>
                <div style={{display:'flex'}}>
                  <div style={{backgroundColor:'#3D3D3D', padding:'12px 25px', borderRadius:'5px 0px 0px 5px', cursor:'pointer'}}><p style={{margin:'0', color:'#F2E2C4', fontFamily:'Montserrat', fontSize:'13px', fontWeight:'400'}}>A ser tratada</p></div>
                  <div style={{backgroundColor:'#F1F1F1', padding:'12px 41px', borderRadius:'0px 5px 5px 0px', cursor:'pointer'}}><p style={{margin:'0', color:'#262626', fontFamily:'Montserrat', fontSize:'13px', fontWeight:'400'}}>Tratadas</p></div>
                </div>
                <input style={{width:'100%', padding:'8px 18px', borderRadius:'5px', border:'solid 1px #999', marginTop:'10px', cursor:'pointer'}} type="date" placeholder="Periodo"></input>
              </div>
              <div className="valor-total" style={{ paddingTop: '20px',paddingBottom: '20px', marginRight:'15px'}}>
                <div style={{ background:'#F1F1F1', display:'flex', alignItems:'center', justifyContent:'space-between',  borderRadius:'10px', padding:'16px 45px'}}>
                  <div style={{ margin:'10px', marginRight:'45px', display:'flex', flexDirection:'column'}}>
                    <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'10px', fontWeight:'400px'}}>Valor total</p>
                    <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'15px', fontWeight:'600'}}>contestado</p>
                  </div>
                  <div style={{ display:'flex', alignItems:'center', margin:'10px',}}>
                    <p style={{fontFamily:'Montserrat', margin:'0', padding:'0', fontSize:'30px', marginRight:'5px', color:'#3D3D3D'}}>R$</p>
                    <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'30px', fontWeight:'600', color:'#3D3D3D'}}>127,00</p>
                  </div>
                </div>
              </div>
              <div className="valor-total"  style={{ paddingTop: '20px',paddingBottom: '20px'}}>
                <div style={{marginBottom:'10px'}}>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'10px', fontWeight:'400', color:'#999'}}>Você pode aceitar ou recusar as contestações que estão pendentes, ou acompanhar <br/> a linha do tempo das contestações que já foram tratadas.</p>
                </div>
                <div style={{display:'flex', alignItems:'center', backgroundColor:'#F1F1F1', padding:'10px 15px'}}>
                  <div style={{marginRight:'5px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" fill="none">
                    <path d="M15 28.126C7.76254 28.126 1.875 22.2384 1.875 15.001C1.875 7.76353 7.76254 1.875 15 1.875C22.2375 1.875 28.125 7.76354 28.125 15C28.125 22.2365 22.2375 28.126 15 28.126ZM15 3.28013C8.53737 3.28013 3.28013 8.53736 3.28013 15C3.28013 21.4627 8.53737 26.7199 15 26.7199C21.4627 26.7199 26.7199 21.4627 26.7199 15C26.7199 8.53736 21.4627 3.28013 15 3.28013Z" fill="#3D3D3D"/>
                    <path d="M14.9994 20.7755C14.611 20.7755 14.2969 20.4614 14.2969 20.073V14.4384C14.2969 14.05 14.611 13.7358 14.9994 13.7358C15.3878 13.7358 15.702 14.05 15.702 14.4384V20.073C15.702 20.4604 15.3878 20.7755 14.9994 20.7755Z" fill="#999999"/>
                    <path d="M15.0008 12.5398C15.7575 12.5398 16.3709 11.9264 16.3709 11.1699C16.3709 10.4132 15.7575 9.7998 15.0008 9.7998C14.2442 9.7998 13.6309 10.4132 13.6309 11.1699C13.6309 11.9264 14.2442 12.5398 15.0008 12.5398Z" fill="#999999"/>
                    </svg>
                  </div>
                  <p style={{fontFamily:'Montserrat', margin:'0', padding:'0',  fontSize:'10px', fontWeight:'400', color:'#3D3D3D'}}>Contestações que não tiverem ação até o prazo de expiração, serão aceitas <br/> automaticamente.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-lancamentos" style={{ display:'flex'}}>
            <div style={{display:'inline-block', width:'100%'}}>
              <div style={{background:'#F1F1F1', borderRadius:'10px', marginTop:'40px',
              marginBottom:'20px',  height:'800px', width:'100%'}}>
                <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center', borderBottom:'solid 5px #3D3D3D', padding: '38px 308px 38px 20px'}}>
                  <p style={{whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'24px', fontWeight:'600', margin:'0', marginRight:'46%'}}>Contestações a serem tratadas</p>
                  <div style={{background:'#3D3D3D', padding:'8px 120px 8px 10px', borderRadius:'5px', display:'flex', alignItems:'center'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M11 11L7.66667 7.66667M8.77778 4.88889C8.77778 7.03666 7.03666 8.77778 4.88889 8.77778C2.74111 8.77778 1 7.03666 1 4.88889C1 2.74111 2.74111 1 4.88889 1C7.03666 1 8.77778 2.74111 8.77778 4.88889Z" stroke="#F2E2C4" stroke-width="1.77724" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <input class="custom-input" style={{border:'none', background:'#3D3D3D', outline: 'none', marginLeft:'5px'}} type="text" placeholder="Procurar valor da contestação"></input>
                  </div>
                </div>
                <div style={{margin:'10px', height:'600px'}}>
                  <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                    <div style={{ marginTop:'40px', marginBottom:'10px'}}>
                      <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                        <p style={{marginRight:'53px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Prazo de <br/>
                        expiração</p>
                        <p style={{marginRight:'28px', whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Data de <br/>
                        contestação</p>
                        <p style={{marginRight:'65px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Protocolo</p>
                        <p style={{marginRight:'42px', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', whiteSpace: 'nowrap'}}>Bandeira</p>
                        <p style={{marginRight:'31px', whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Valor da <br/>
                        contestação</p>
                        <p style={{marginRight:'72px',whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Valor <br/>
                        líquido</p>
                        <p style={{whiteSpace: 'nowrap', fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D'}}>Tomada <br/>
                        de decisão</p>
                      </div>
                    </div>
                  </div>
                  <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                    <div style={{marginBottom:'10px'}}>
                      <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                        <div style={{ marginLeft:'50px'}}>
                          <p style={{ fontSize:'12px', fontWeight:'300', color:'#3D3D3D', margin: '0' }}>16/12/2022</p>
                        </div>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'45px', whiteSpace: 'nowrap'}}>0000003565</p>

                        <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'50px'}}><img style={{width:'36px', height:'19px'}} src={bXuliz}/></div>

                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'50px', whiteSpace: 'nowrap'}}>R$ 150,00</p>

                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'50px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 148,00</p>

                        <div style={{ display:'flex', alignItems:'center', marginLeft:'40px',}}>
                          <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Acatar</p></div>
                          <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Defender</p></div>
                          <div style={{padding:'6px 35px', margin:'10px', border:'2px solid #3D3D3D', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', margin: '0', }}>Exportar</p></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                    <div style={{ marginBottom:'10px'}}>
                    <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                        <div style={{ marginLeft:'50px'}}>
                          <p style={{ fontSize:'12px', fontWeight:'300', color:'#3D3D3D', margin: '0' }}>16/12/2022</p>
                        </div>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'45px', whiteSpace: 'nowrap'}}>0000003565</p>

                        <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'50px'}}><img style={{width:'36px', height:'19px'}} src={bXuliz}/></div>

                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'50px', whiteSpace: 'nowrap'}}>R$ 150,00</p>

                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'50px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 148,00</p>

                        <div style={{ display:'flex', alignItems:'center', marginLeft:'40px',}}>
                          <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Acatar</p></div>
                          <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Defender</p></div>
                          <div style={{padding:'6px 35px', margin:'10px', border:'2px solid #3D3D3D', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', margin: '0', }}>Exportar</p></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                    <div style={{marginBottom:'10px'}}>
                    <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                        <div style={{ marginLeft:'50px'}}>
                          <p style={{ fontSize:'12px', fontWeight:'300', color:'#3D3D3D', margin: '0' }}>16/12/2022</p>
                        </div>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'45px', whiteSpace: 'nowrap'}}>0000003565</p>

                        <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'50px'}}><img style={{width:'36px', height:'19px'}} src={bXuliz}/></div>

                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'50px', whiteSpace: 'nowrap'}}>R$ 150,00</p>

                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'50px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 148,00</p>

                        <div style={{ display:'flex', alignItems:'center', marginLeft:'40px',}}>
                          <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Acatar</p></div>
                          <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Defender</p></div>
                          <div style={{padding:'6px 35px', margin:'10px', border:'2px solid #3D3D3D', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', margin: '0', }}>Exportar</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                    <div style={{marginBottom:'10px'}}>
                    <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                        <div style={{ marginLeft:'50px'}}>
                          <p style={{ fontSize:'12px', fontWeight:'300', color:'#3D3D3D', margin: '0' }}>16/12/2022</p>
                        </div>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'45px', whiteSpace: 'nowrap'}}>0000003565</p>

                        <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'50px'}}><img style={{width:'36px', height:'19px'}} src={bXuliz}/></div>

                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'50px', whiteSpace: 'nowrap'}}>R$ 150,00</p>

                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'50px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 148,00</p>

                        <div style={{ display:'flex', alignItems:'center', marginLeft:'40px',}}>
                          <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Acatar</p></div>
                          <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Defender</p></div>
                          <div style={{padding:'6px 35px', margin:'10px', border:'2px solid #3D3D3D', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', margin: '0', }}>Exportar</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{borderBottom:'solid 1px #999', margin: '10px'}}>
                    <div style={{marginBottom:'10px'}}>
                    <div style={{display:'flex', alignItems:'center', margin: '0', padding:'10px' }}>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0'}}>16/01/2023</p>
                        <div style={{ marginLeft:'50px'}}>
                          <p style={{ fontSize:'12px', fontWeight:'300', color:'#3D3D3D', margin: '0' }}>16/12/2022</p>
                        </div>
                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'45px', whiteSpace: 'nowrap'}}>0000003565</p>

                        <div style={{background:'#262626', padding:'5px', borderRadius:'4px', marginLeft:'50px'}}><img style={{width:'36px', height:'19px'}} src={bXuliz}/></div>

                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#3D3D3D', margin: '0', marginLeft:'50px', whiteSpace: 'nowrap'}}>R$ 150,00</p>

                        <p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'600', color:'#3D3D3D', margin: '0', marginLeft:'50px', marginRight:'6px', whiteSpace: 'nowrap'}}>R$ 148,00</p>

                        <div style={{ display:'flex', alignItems:'center', marginLeft:'40px',}}>
                          <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Acatar</p></div>
                          <div style={{padding:'8px 35px', background:'#262626', margin:'10px', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#F2E2C4', margin: '0', }}>Defender</p></div>
                          <div style={{padding:'6px 35px', margin:'10px', border:'2px solid #3D3D3D', borderRadius:'5px', cursor:'pointer'}}><p style={{fontFamily:'Montserrat', fontSize:'12px', fontWeight:'400', color:'#262626', margin: '0', }}>Exportar</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop:'50px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div style={{paddingLeft:'19px', display:'inline-flex', paddingTop:'13px'}}>
                      <p style={{fontFamily:'Montserrat', fontSize:'14px', fontWeight:'400', color:'#999', margin: '0',}}>0-6 de 50 recebíveis</p>
                    </div>

                    <div style={{ paddingRight:'19px', display:'inline-flex'}}>
                      <button style={{background:'#FFF', padding:'3px', border:'1px solid #0006241A', borderRadius:'5px', marginRight:'12px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 18 18" fill="none">
                        <path d="M11.1447 6.10886C11.2912 6.2553 11.2912 6.49274 11.1447 6.63919L8.41027 9.37501L11.1447 12.11C11.2912 12.2564 11.2912 12.4938 11.1447 12.6403C10.9983 12.7867 10.7608 12.7867 10.6144 12.6403L7.34961 9.37501L10.6144 6.10886C10.7608 5.96241 10.9983 5.96241 11.1447 6.10886Z" fill="#000624" fill-opacity="0.3"/>
                        </svg>
                      </button>

                      <div style={{background:'#3D3D3D', borderRadius:'5px', width:'33px', height:'30px', display:'flex', justifyContent:'center', alignItems:'center', marginRight:'8px'}}>
                        <p style={{paddingTop:'15px', color:'#FFF'}}>1</p>
                      </div>

                      <div style={{background:'#FFF', borderRadius:'5px', width:'33px', height:'30px', display:'flex', justifyContent:'center', alignItems:'center', border:'solid 1px #3D3D3D'}}>
                        <p style={{paddingTop:'15px', color:'#3D3D3D'}}>2</p>
                      </div>

                      <button style={{background:'#FFF', padding:'3px', border:'1px solid #3D3D3D', borderRadius:'5px', marginLeft:'12px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 18 18" fill="none">
                        <path d="M6.85528 11.8911C6.70883 11.7447 6.70883 11.5073 6.85528 11.3608L9.58973 8.62499L6.85528 5.89004C6.70883 5.7436 6.70883 5.50616 6.85528 5.35971C7.00173 5.21327 7.23916 5.21327 7.38561 5.35971L10.6504 8.62499L7.38561 11.8911C7.23916 12.0376 7.00173 12.0376 6.85528 11.8911Z" fill="#000624" fill-opacity="0.3"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </>
  
  );

};

export default Recebiveis;
