import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";

import { useEffect } from "react";
import xloja_12 from './assets/xloja_12.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg'; 
import banco_img from './assets/banco.png'; 


import xloja from './assets/xloja.png'; // Tell webpack this JS file uses this image


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faShoppingBag,faMoneyCheckAlt,faFileAlt,faGavel,faChartPie,faBox,faCircleDollarToSlot,faBullhorn,faQuestionCircle,faUsers,faGear,faSquareXmark,faMobile,faBars,faBookReader } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import axios from "axios";
import logo_parceiros from './assets/grupo677.png'; // Tell webpack this JS file uses this image
 
 import Calendario from "./componentes/Recebiveis/calendario";
 

var vendaPaga = false;
   
const Recebiveis = () => {
  const navigate = useNavigate();

  const Swal = require('sweetalert2')

   


  const [emailRef, setEmailRef] = useState('');
  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [cashbackCliente, setCaschbackCliente] = useState('R$ 0,00');
  const [dadosClinte, setDadosCliente] = useState([]);
  const [dadosX, setDadosX] = useState([]);
  const [primeiroNome, setPrimeiroNome] = useState('');
  const [nomeUser, setNome] = useState('');
  const [rows, setRow] = useState([]);


  const [dadosREF, setDADOSREF] = useState(null);

  const [total_receber, setTotalReceber] = useState('');
  const [geralTaxas, setTotalTaxas] = useState('R$ 0,00');

  

  const now = new Date();
  const [selectedValue, setSelectedValue] = useState(now.getMonth());
  const [ano, setAno] = useState(now.getFullYear());
  const [dataCalendario, setDataCalendario] = useState([]);
  const [geralDebito,setGeralDebito] = useState(0)
  const [geralCredito,setGeralCredito] = useState(0)
  const [geralXuliz,setGeralXuliz] = useState(0)
  // const [geralPix,setGeralPix] = useState(0)
  const [geralBruto,setGeralBruto] = useState(0)
  const [totalPedidos,setTotalPedidos] = useState(0)

  const [dataRef,setDataRef] = useState('')

  

  

  

  const [clickEnviou, setClickEnviou] = useState(false);
  const [tokenEsta, setTokenEstabelecimento] = useState('');
  const [idvenda, setIdvenda] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [urlLogo, setUrlLogo] = useState('');

  
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };

    
  }, []);



  useEffect(() => {

    getDadosSQL()

  },[]);


  const filtrarDias = (array_dias)=>{

    var dados_retorno = dadosREF.retorno;

    var novoArrayFiltrado = [];

    var dadosREF_SELECAO = dadosREF;

    for(var x=0;x<array_dias.length;x++){

      var diaSelecionado = array_dias[x]

      //verifica se esse dia esta no array
      for(var b=0;b<dados_retorno.length;b++){
        var datavencimento = dados_retorno[b].datavencimento;
        var refDia = datavencimento.split('-')
        refDia = refDia[2]; 
        if(refDia == diaSelecionado){
          novoArrayFiltrado.push(dados_retorno[b])
        }
      }

 
    }
    
    console.log('arrayFiltrado',novoArrayFiltrado)
    dadosREF_SELECAO.array_retorno = novoArrayFiltrado
    tradarDados(dadosREF_SELECAO)
    // // console.log(dadosREF_SELECAO)
    // console.log('arrayFiltrado',novoArrayFiltrado)
    // console.log('dadosREF_SELECAO',dadosREF_SELECAO)


  }



  const getDadosSQL = async(mesSelecionado)=>{

    setRow([])

   

    await  getDados()

    var dados = await localStorage.getItem('login')
    dados = JSON.parse(dados);
    var idestabele = dados.idestabelecimento;




    var getTokenEstabelecimneto = await getTokenEstabelciment(idestabele);
    


    if(getTokenEstabelecimneto == null){
      getTokenEstabelecimneto = dados.tokenestabelecimento;
    }


    var datainicial = await getDataInicial(mesSelecionado)
    var datafinal = await getDataFinal(mesSelecionado)

    // console.log('inicial'+datainicial)
    // console.log('final'+datafinal)
   


   

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: new URLSearchParams({
        idempresa: '1350',
        token: getTokenEstabelecimneto,
        idvendaxuliz: '',
        metodo: 'Lista',
        idestabelecimento: idestabele,
        idvenda: '',
        datainicial: datainicial,
        datafinal: datafinal
      })
    };
     
    fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/estabelecimento/listarecebiveisagrupado.php', options)
    // fetch('https://xuliz.com.br/painel/integracao/vendaxuliz.php', options)
      .then(response => response.json())
      .then(response => tradarDados(response, datainicial, datafinal))
      .catch(err => console.error(err));
  }

  
  const tradarDados = async(dadosG, datainicial, datafinal)=>{

    setDADOSREF(dadosG)
    

    var retorno = dadosG.retorno;
    console.log('dadosG',dadosG)
    var usuarioFiltrou = false;

    if(dadosG.array_retorno){
      usuarioFiltrou = true;
      retorno = dadosG.array_retorno

      if(dadosG.array_retorno.length == 0){
        retorno = dadosG.retorno;
      }
    }

    // var mesREF = getCurrentMonthAndYear(newMes);
 
 
    // console.log(mesREF,'mesREF')
     console.log('dados_api',retorno);


     
    var geralLiquido = 0;
    var totalDebitos = 0;
    var totalCredito = 0;
    var totalXuliz  = 0;
    // var totalPix= 0;
    var totalTaxas = 0;
    var totalBruto = 0;
    var total_pedido = 0;

     var dadosCalendario = []
    // setDataCalendario
 


     for(var x=0;x<retorno.length;x++){

        var data = retorno[x].datavencimento;
        var datavencimento = retorno[x].datavencimento;
       
        data = data.split('-');
        var diaRef = data[2];
        data = data[0]+'-'+data[1];
      

        var resumo = retorno[x].resumo
        for(var b=0;b<resumo.length;b++){
          
         

        var bandeira = resumo[b].tipo;
          //  console.log(bandeira)
          if(bandeira.includes("Débito")){
            var debito = resumo[b].valor
            debito = parseFloat(debito)
            totalDebitos += debito
          }

          if(bandeira.includes("Crédito")){
            var credito = resumo[b].valor
            credito = parseFloat(credito)
            totalCredito += credito
          }

          if(bandeira.includes("Xuliz APP")){
            var xuliz = resumo[b].valor
            xuliz = parseFloat(xuliz)
            totalXuliz += xuliz
          }


        }
          
         
        // console.log(mesREF+'  ||| '+data+' -->'+diaRef)
        
        var valor = retorno[x].valor;
        valor = parseFloat(valor);
        
        
        geralLiquido = geralLiquido+valor;
        
        var quantidade = retorno[x].qtde
        quantidade = parseFloat(quantidade)
        total_pedido = total_pedido+quantidade;
        
        var valorBruto = retorno[x].valorbruto
        valorBruto = parseFloat(valorBruto)
        totalBruto = totalBruto+valorBruto;

        totalTaxas = totalTaxas+(valorBruto-valor)
         
      
        dadosCalendario.push({
          liquido:valor,
          diaRef:diaRef,
          data:data,
          datavencimento:datavencimento,
          qtde: retorno[x].qtde
        })
        

      }
 

    var resultadoCalendario = [];

    for(var x=0;x<dadosCalendario.length;x++){
      resultadoCalendario.push({
        data:dadosCalendario[x].datavencimento,
        total:dadosCalendario[x].liquido,
        totalPedidos:dadosCalendario[x].qtde
      })
    }
    
    if(usuarioFiltrou == false){
      setDataCalendario(resultadoCalendario)
    }
     

    geralLiquido = geralLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalDebitos = totalDebitos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalCredito = totalCredito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalXuliz = totalXuliz.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    //  totalPix = totalPix.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      totalTaxas = totalTaxas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     totalBruto = totalBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

     setTotalReceber(geralLiquido)
     setGeralDebito(totalDebitos)
     setGeralCredito(totalCredito)
     setGeralXuliz(totalXuliz)
    //  setGeralPix(totalPix)
     setTotalTaxas(totalTaxas)
     setGeralBruto(totalBruto)
     setTotalPedidos(total_pedido)

    datainicial = datainicial.split('-')
    datainicial = datainicial[2]+"-"+datainicial[1]+"-"+datainicial[0]
   
    datafinal = datafinal.split('-')
    datafinal = datafinal[2]+"-"+datafinal[1]+"-"+datafinal[0]

     setDataRef('Data: '+datainicial+' à '+datafinal )
    }


  function getCurrentMonthAndYear(newMes) {
    // Cria um objeto de data para o mês atual
    var currentDate = new Date();

    
    
    var selectedValueGet = document.getElementById('selectInput').value;
    selectedValueGet = parseInt(selectedValue)

    if(newMes != null){
      selectedValueGet = ''+newMes
      selectedValueGet = parseInt(selectedValueGet)
    }
    // Obtém o mês e o ano atual
    var month = selectedValueGet + 1; // Adiciona 1 pois os meses em JavaScript são indexados de 0 a 11
    var year = currentDate.getFullYear();




    // Retorna o mês e o ano formatados
    return `${year}-${(month < 10 ? '0' : '') + month}`;
}


function obterUltimoDiaDoMes(mes, ano) {
  var mesInt = parseInt(mes);

  // Criar uma nova data para o primeiro dia do mês seguinte
  var data = new Date(ano, mesInt, 0);

  // O último dia do mês é o último dia do mês anterior ao que definimos
  var ultimoDia = data.getDate();

  return ultimoDia;
}

  const getDataFinal = async(mesSelecionado)=>{


   var data = new Date();
   var ano = data.getFullYear();
  var mes = String(data.getMonth() + 1).padStart(2, '0');

  if(mesSelecionado != null){
    mes = mesSelecionado
  }

   var dia = obterUltimoDiaDoMes(mes,ano)
  
  return ano + '-' + mes + '-' + dia;
  }

  const getDataInicial = async(mesSelecionado)=>{
    var data = new Date();
    // data.setDate(data.getDate() - 32);
    
    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');

    if(mesSelecionado != null){
      mes = mesSelecionado
    }

    var dia = "01";
    
    return ano + '-' + mes + '-' + dia;
  }


  const getTokenEstabelciment = async(token)=>{
    const form = new FormData();
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("metodo", "ListaEstabelecimentos");
      
    
    const options = {
      method: 'POST',
    };
    
    options.body = form;
    
   var rres = await  fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options).then(response => response.json()); 
    
   var arrrayEstabelecimnto = rres.estabelecimentos;

   var tokenEstabelecimento = '';
   for(var x=0;x<arrrayEstabelecimnto.length;x++){
    if(arrrayEstabelecimnto[x].idestabelecimento == token){
      tokenEstabelecimento = arrrayEstabelecimnto[x].token;
      break;
    }
   }

  //  console.log(rres)
  //  console.log(tokenEstabelecimento)
   setTokenEstabelecimento(tokenEstabelecimento)
   return tokenEstabelecimento;
 

  }


  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
  
    verificarNiveisLogin(dadosX)
  };

 
  const verDetalhes = async ()=>{

    
    var getMes = ''+selectedValue;
    getMes = parseInt(getMes)
    getMes = getMes+1;

    if(getMes < 10){
      getMes = '0'+getMes
    }
 
    // return false;///REMOVA TESTE
    location.href = './resumo_recebiveis?m='+getMes
  }

 

  const getDados = async()=>{
    var dados = await localStorage.getItem('login')

    if(dados == null){
      navigate("/");

      return false;
    }
    dados = JSON.parse(dados)
     setDadosX(dados)

     setUrlLogo(dados.urllogo)
 
    //  console.log('dadosX',dados)

    setEmailRef(dados.email)
    //  console.log(dados)

      
  
     
  }


  const verificarNiveisLogin = async ()=>{
    var idperfil = await localStorage.getItem('tipoPerfil');
    
      
    if(idperfil == "7"){
        //financeiro somente relatorios
        location.href = "./minhasvendas";
        
    }

}
  
 
  


  

   

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
 

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    // tradarDados(dadosREF,event.target.value)

    var mesRef = ""+event.target.value;
    mesRef = parseInt(mesRef)+1;

    if(mesRef < 10){
      mesRef = "0"+mesRef;
    }

  


    getDadosSQL(mesRef)

};

const Detalhes = ({minWidth})=>{

  return(
    <>
    <div style={{display:'flex',padding:10,flexDirection:'column'}}>
            
            <div id="blockUser" style={{flexDirection:'column',display:"flex",height:"100%",padding:5,flex:1,minWidth:minWidth,padding:20}}>
                      
                      <span style={{fontFamily:'Montserrat',fontSize:20,color:'#262626',fontWeight:'bold'}}>Detalhamento do calendário</span>
                      <span style={{fontFamily:'Montserrat',fontSize:12,backgroundColor:'#F2E2C4',width:200,padding:5,borderRadius:5,marginTop:10}}>{dataRef}</span>
  
  
                      <div style={{backgroundColor:'#F1F1F1',display:'flex',height:700,marginTop:20,borderRadius:10,flexDirection:'column',flex:1}}>
                          <div style={{display:'flex',flex:1}}>
                                
                                <div style={{flex:1,justifyContent:'center',display:'flex',flexDirection:'column',padding:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:17,color:'#999999',fontWeight:'bold'}}>Vendas no total</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#999999',fontWeight:'bold'}}>{totalPedidos} vendas</span>
  
                                </div>
  
                                <div style={{flex:1,justifyContent:'center',display:'flex',flexDirection:'column',padding:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:17,color:'#999999',fontWeight:'bold'}}>{geralBruto}</span>
                                </div>
                          </div>
  
                          <div style={{display:'flex',flex:6,borderTop:"4px solid #3D3D3D",padding:20,flexDirection:'column'}}>
                              
                              <span style={{fontFamily:'Montserrat',fontSize:17,color:'#262626',fontWeight:'bold'}}>Formas de pagamento</span>
  
  
                                <div style={{display: 'flex',justifyContent:'space-between', width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Crédito</span>
                                      <div style={{display:'flex', alignItems:'baseline',cursor:'pointer'}} onClick={()=>{verDetalhes()}}>
                                        <span style={{fontFamily:'Montserrat',fontSize:13, color:'#262626', paddingBottom:11,float:"right"}}>{geralCredito}</span>
                                        <span style={{marginLeft:10,fontSize:17,fontWeight:'bold'}} > ⋮ </span> 
                                      </div>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
  
                                {/* <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Débito</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralDebito}</span>
                                </div> */}
                                <div style={{display: 'flex',justifyContent:'space-between', width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Débito</span>
                                      <div style={{display:'flex', alignItems:'baseline',cursor:'pointer'}} onClick={()=>{verDetalhes()}}>
                                        <span style={{fontFamily:'Montserrat',fontSize:13, color:'#262626', paddingBottom:11,float:"right"}}>{geralDebito}</span>
                                        <span style={{marginLeft:10,fontSize:17,fontWeight:'bold'}} > ⋮ </span> 
                                      </div>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
                               
  
  
                                {/* <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Pix</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralPix}</span>
                                </div> */}
                                {/* <div style={{display: 'flex',justifyContent:'space-between', width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Pix</span>
                                      <div style={{display:'flex', alignItems:'baseline',cursor:'pointer'}} onClick={()=>{}}>
                                        <span style={{fontFamily:'Montserrat',fontSize:13, color:'#262626', paddingBottom:11,float:"right"}}>{geralPix}</span>
                                        <span style={{marginLeft:10,fontSize:17,fontWeight:'bold'}} > ⋮ </span> 
                                      </div>
                                </div> */}
                                {/* <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/> */}
  
                               
  
  
                                {/* <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Saldo Xuliz</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}>{geralXuliz}</span>
                                </div> */}
                                <div style={{display: 'flex',justifyContent:'space-between', width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Xuliz</span>
                                      <div style={{display:'flex', alignItems:'baseline',cursor:'pointer'}} onClick={()=>{verDetalhes()}}>
                                        <span style={{fontFamily:'Montserrat',fontSize:13, color:'#262626', paddingBottom:11,float:"right"}}>{geralXuliz}</span>
                                        <span style={{marginLeft:10,fontSize:17,fontWeight:'bold'}} > ⋮ </span> 
                                      </div>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/>
  
                               
  
                                {/* <div style={{width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Saldo Xuliz + Crédito</span>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11,float:"right"}}> - {}</span>
                                </div> */}
                                {/* <div style={{display: 'flex',justifyContent:'space-between', width:'100%',paddingTop:20}}>
                                    <span style={{fontFamily:'Montserrat',fontSize:13,color:'#262626',paddingBottom:11}}>Saldo Xuliz + Crédito</span>
                                      <div style={{display:'flex', alignItems:'baseline',cursor:'pointer'}} onClick={()=>{}}>
                                        <span style={{fontFamily:'Montserrat',fontSize:13, color:'#262626', paddingBottom:11,float:"right"}}> - {}</span>
                                        <span style={{marginLeft:10,fontSize:17,fontWeight:'bold'}} > ⋮ </span> 
                                      </div>
                                </div>
                                <div style={{borderBottom:"1px solid #3D3D3D",width:'100%'}}/> */}
  
                               
  
                               <div style={{width:'100%',height:150,textAlign:'-webkit-right',marginTop:20,placeContent:'center',flexDirection:'column',display:'flex'}}>
                                    
                                    <span style={{fontFamily:'Montserrat',fontSize:15,color:'#999999'}}>Taxa/tarifa paga:<span style={{color:'red'}}>  - {geralTaxas}</span></span>
                                    {/*<span style={{fontFamily:'Montserrat',fontSize:15,color:'#999999',marginTop:10}}>Cashback: <span style={{color:'#279C33'}}>  - {} </span></span>*/}
  
                                    <span style={{fontFamily:'Montserrat',fontSize:20,color:'#999999',marginTop:50}}>Total líquido: <span style={{color:'black'}}>  {total_receber}</span></span>
  
  
                               </div>
  
  
  
                          </div>
                      </div>
  
  
  
                
  
                          {/* CODE */}
                      
            </div>  
            </div>
    
    </>
  )
}



  if(isMobile == true){
      return(
      <>
        <MenuMobile page="recebiveis"/>


        <div style={{height:150,width:'100%',flexDirection:'row',display:'flex',padding:10}}>
                    <div style={{width:'100%',display:'flex',flex:1,margin:5,borderRadius:10,justifyContent:'center',alignItems:'center',flexDirection:'column',backgroundColor:'#f2e2c4'}} id="realizar_vendaID" onClick={()=>{location.href = './recebiveis'}}>
                       <FontAwesomeIcon icon={faMoneyCheckAlt} style={{fontSize:30,color:"#262626"}} />
                      <span style={{textAlign:'center',fontSize:14,marginTop:10}}>Antecipar</span>

                    </div>

                    <div style={{width:'100%',backgroundColor:'#F1F1F1',display:'flex',flex:1,margin:5,borderRadius:10,justifyContent:'center',alignItems:'center',flexDirection:'column',backgroundColor:'#f1f1f1'}} onClick={()=>{}}>
                      <FontAwesomeIcon icon={faFileAlt} style={{fontSize:30,color:"#262626"}} />
                      <span style={{textAlign:'center',fontSize:14,marginTop:10}}>Resumo</span>

                    </div>

                    <div style={{width:'100%',backgroundColor:'#f1f1f1',display:'flex',flex:1,margin:5,borderRadius:10,justifyContent:'center',alignItems:'center',flexDirection:'column'}} onClick={()=>{}}>
                      <FontAwesomeIcon icon={faGavel} style={{fontSize:30,color:"#262626"}} />

                      <span style={{textAlign:'center',fontSize:14,marginTop:10}}>Contestação</span>

                    </div>
          </div>

         

          <div style={{display:"flex",width:"100%",overflow:'auto',paddingRight:10,paddingLeft:10}}>


              <div style={{display:'flex',height:60,alignItems:'center',justifyContent:'center'}}>
                  <span style={{fontFamily:'Montserrat',fontSize:12,color:'#999999'}}>Selecione no calendário uma data que gostaria de iniciar uma consulta nos seus recebíveis. Para consultar período, marque mais de uma data.</span>

                    <select id="selectInput" value={selectedValue} onChange={handleSelectChange} style={{padding:10,marginLeft:10}}>
                        <option value="0">Janeiro</option>
                        <option value="1">Fevereiro</option>
                        <option value="2">Março</option>
                        <option value="3">Abril</option>
                        <option value="4">Maio</option>
                        <option value="5">Junho</option>
                        <option value="6">Julho</option>
                        <option value="7">Agosto</option>
                        <option value="8">Setembro</option>
                        <option value="9">Outubro</option>
                        <option value="10">Novembro</option>
                        <option value="11">Dezembro</option>
                    </select>
                </div>

             
          </div>

          <div style={{display:'flex',height:700,alignItems:'center',marginTop:20,zoom:'65%'}}>
              <Calendario mes={selectedValue} ano={ano} dataCalendario={dataCalendario} filtrarDias={filtrarDias}/>
          </div>

          <div style={{display:'flex',height:700,alignItems:'center',justifyContent:'center'}}>
              <Detalhes  minWidth={300}/>
          </div>

          

        </>
      )
  }



 return(
  <>

    <div style={{height:'100vh',display:"flex"}}>
      <div style={{backgroundColor:'#262626'}}>
          <Menu page="recebiveis"/>
      </div>

      <div style={{height:'100vh',display:"flex",width:"100%",overflow:'auto',flex:1,padding:20}}>
          <div style={{display:'flex',flex:1.4,flexDirection:'column',margin:10}}>
            <div style={{display:'flex',height:80,borderBottom: '1px solid black' }}>
                <div style={{flex:1.5,display:'flex',alignItems:'center'}}>
                  <span style={{fontFamily:'Montserrat',fontWeight:'bold'}}>Recebíveis</span>
                </div>

                <div style={{flex:1,display:'flex',alignItems:'center'}}>
                    <span style={{fontFamily:'Montserrat'}}>Antecipar</span>
                </div>

                <div style={{flex:1,display:'flex',alignItems:'center'}}>
                  <span style={{fontFamily:'Montserrat'}}>Resumo</span>
                </div>

                <div style={{flex:1,display:'flex',alignItems:'center'}}>
                    <span style={{fontFamily:'Montserrat'}}>Contestação</span>
                </div>

                <div style={{flex:2,display:'flex'}}>
                    <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                           {/* <img src={banco_img} style={{width:50,height:50}}/> */}
                    </div>

                    <div style={{display:'flex',flex:1,flexDirection:'column',justifyContent:'center'}}>
                        {/* <span style={{fontFamily:'Montserrat',fontWeight:'bold',fontSize:13}}>Bancoob S.A.</span>
                        <span style={{fontFamily:'Montserrat',fontSize:13}}>Ag.: 0001</span>
                        <span style={{fontFamily:'Montserrat',fontSize:13}}>Cc.:  22700847-2</span> */}
                    </div>
                </div>
            </div>

            <div style={{display:'flex',height:80,alignItems:'center',marginTop:20}}>
              <span style={{fontFamily:'Montserrat',fontSize:12,color:'#999999'}}>Selecione no calendário uma data que gostaria de iniciar uma consulta nos seus recebíveis. Para consultar período, marque mais de uma data.</span>

                <select id="selectInput" value={selectedValue} onChange={handleSelectChange} style={{padding:10,marginLeft:10}}>
                    <option value="0">Janeiro</option>
                    <option value="1">Fevereiro</option>
                    <option value="2">Março</option>
                    <option value="3">Abril</option>
                    <option value="4">Maio</option>
                    <option value="5">Junho</option>
                    <option value="6">Julho</option>
                    <option value="7">Agosto</option>
                    <option value="8">Setembro</option>
                    <option value="9">Outubro</option>
                    <option value="10">Novembro</option>
                    <option value="11">Dezembro</option>
                 </select>
            </div>


            <div style={{display:'flex',height:700,alignItems:'center',marginTop:20}}>

                  
                    <Calendario mes={selectedValue} ano={ano} dataCalendario={dataCalendario} filtrarDias={filtrarDias}/>

                    

            </div>

            
          </div>

          <Detalhes minWidth={500}/>
      </div>
    </div>
  
  </>
  
  
  )
 
  
};

export default Recebiveis;
