import styles from "./Input.module.css";
const Input = () => {
  return (
    <div className={styles.input}>
      <div className={styles.pesquisar}>Pesquisar</div>
      <img className={styles.vectorIcon} alt="" src="/vector.svg" />
    </div>
  );
};

export default Input;
