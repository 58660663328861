import styles from "./Calender.module.css";
const Calender = () => {
  return (
    <div className={styles.calender}>
      <div className={styles.calenderChild} />
      <div className={styles.timeday1w4i} />
      <div className={styles.timeday1w4i1} />
      <div className={styles.timeday1w4i2} />
      <div className={styles.timeday1w4i3} />
      <div className={styles.timeday1w4i4} />
      <div className={styles.timeday1w4i5} />
      <div className={styles.timeday1w4i6} />
      <div className={styles.timeday1w4i7} />
      <div className={styles.timeday1w4i8} />
      <div className={styles.timeday1w4i9}>
        <div className={styles.dayCalender}>
          <div className={styles.div}>01</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender1}>
          <div className={styles.div}>08</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender2}>
          <div className={styles.div}>07</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender3}>
          <div className={styles.div}>06</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender4}>
          <div className={styles.div}>05</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender5}>
          <div className={styles.div}>04</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender6}>
          <div className={styles.div}>03</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender7}>
          <div className={styles.div}>02</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender8}>
          <div className={styles.div}>15</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender9}>
          <div className={styles.div}>22</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender10}>
          <div className={styles.div}>29</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender11}>
          <div className={styles.div}>14</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender12}>
          <div className={styles.div}>21</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender13}>
          <div className={styles.div}>28</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender14}>
          <div className={styles.div}>13</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender15}>
          <div className={styles.div}>20</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender16}>
          <div className={styles.div}>27</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender17}>
          <div className={styles.div}>12</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender18}>
          <div className={styles.div}>19</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender19}>
          <div className={styles.div}>26</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender20}>
          <div className={styles.div}>11</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender21}>
          <div className={styles.div}>18</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender22}>
          <div className={styles.div}>25</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender23}>
          <div className={styles.div}>10</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender24}>
          <div className={styles.div}>17</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender25}>
          <div className={styles.div}>24</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender26}>
          <div className={styles.div}>31</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender27}>
          <div className={styles.div}>09</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender28}>
          <div className={styles.div}>16</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender29}>
          <div className={styles.div}>23</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
        <div className={styles.dayCalender30}>
          <div className={styles.div}>30</div>
          <div className={styles.r1004442}>
            <span>R$</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span1}>10.044,42</span>
          </div>
          <div className={styles.vendas}>72 vendas</div>
        </div>
      </div>
      <div className={styles.dom}>Dom</div>
      <div className={styles.seg}>Seg</div>
      <div className={styles.ter}>Ter</div>
      <div className={styles.qua}>Qua</div>
      <div className={styles.qui}>Qui</div>
      <div className={styles.sex}>Sex</div>
      <div className={styles.sb}>Sáb</div>
    </div>
  );
};

export default Calender;
